import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UnsecuredApp from './UnsecureApp';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './hooks/scrollToTop';
import './index.css';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
if (window.self === window.top) {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <ScrollToTop />
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
} else {
  root.render(
    <UnsecuredApp />
  );
}
