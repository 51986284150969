import styled from "styled-components";
import STAR from "../assets/star.svg";

const StarRating = ({
  activeCount,
}) => {
  return (
    <Wrapper>
      {Array.from({ length: activeCount }, (_, index) => (
        <ImageWrapper key={`star-active-${index}`} src={STAR} alt="" />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ImageWrapper = styled.img`
  width: 15px;
`;

export default StarRating;
