import React, { useEffect } from 'react';
import styled from "styled-components";
import rank from "../../assets/Rank.svg";
import rank1 from "../../assets/Rank1.svg";
import rank2 from "../../assets/Rank2.svg";
import rank3 from "../../assets/Rank3.svg";

const RankView = ({ rankNumber }) => {
    let rankImg = null;
    if (rankNumber === 1) rankImg = rank1;
    else if (rankNumber === 2) rankImg = rank2;
    else if (rankNumber === 3) rankImg = rank3;
    else rankImg = rank;

    return (
        <Rank>
            <RankBadge src={rankImg} />
            {rankNumber > 3 && <RankNumber>{rankNumber}</RankNumber>}
        </Rank>
    );
}

// RankView
const Rank = styled.div`
    position: relative;
    background: #393939;
    width: 80px;
    height: 50px;
    border-radius: 4px;
`;
const RankBadge = styled.img`
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
`;
const RankNumber = styled.span`
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, 30%);
    color: #4B4B4B;
`;

export default RankView;