import styled from "styled-components";

const Link = styled.a`
    color: var(--blue-text);
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    transition: .4s;
    &:hover {
        color: var(--main-bg);
        text-decoration: none;
        transition: .4s;
    }
`;

export default Link;