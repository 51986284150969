import { useState, lazy, Suspense, useMemo } from "react";
import styled from "styled-components";
import { getContent } from "../../hooks/useGetContent";
import { size } from "../../utils/helpers";
import HomepageCloudSection from "../../layout/sales/HomepageCloudSection";
import Logo64 from "../../assets/logo-64.png";

const Staging = lazy(() => import('../../layout/sales/HomepageStaging'));
const SecondSection = lazy(() => import('../../layout/sales/HomepageSecond'));
const ThirdSection = lazy(() => import("../../layout/sales/HomepageThird"));
const ComparisionSection = lazy(() => import("../../layout/sales/HomepageComparisonSection"));
const FeatureSection = lazy(() => import("../../layout/sales/HomepageFeatureSection"));
const FeedbackSection = lazy(() => import("../../layout/sales/HomepageFeedbackSection"));
const HomepageSixthSection = lazy(() => import("../../layout/sales/HomepageSisth"));
const RoboticSection = lazy(() => import("../../layout/sales/HomepageRoboticSection"));
const EffectSection = lazy(() => import("../../layout/sales/HomepageEffectSection"));
const HomepageFlow = lazy(() => import("../../layout/sales/HomepageFlow"));
const HomepageMoneyBackSection = lazy(() => import("../../layout/sales/HomepageMoneyBack"));
const HomepageFAQSection = lazy(() => import("../../layout/sales/HomepageFAQSection"));
const HomepageChallengeSection = lazy(() => import("../../layout/sales/HomepageChallange"));

const Homepage = () => {
    const [content, setContent] = useState({});
    const [faq, setFAQ] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    useMemo(() => {
        getContent().then(response => {
            if (response['homepage']) {
                setContent(response['homepage'])
            }
            if (response['homeFAQ']) {
                setFAQ(response['homeFAQ'])
            }
            setIsLoading(false);
        })
    }, []);

    if (isLoading)
        return (
            <div style={{
                position: "absolute",
                minHeight: "100vh",
                width: "100%",
                background: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
                zIndex: 9999
            }}>
                <div class="container">
                    <div class="logo-container">
                        <div class="logo">
                            <LogoImg src={Logo64} alt="logo" />
                        </div>
                    </div>

                    <div class="loading-letters">
                        <span>L</span>
                        <span>o</span>
                        <span>a</span>
                        <span>d</span>
                        <span>i</span>
                        <span>n</span>
                        <span>g</span>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                    </div>
                </div>
            </div>
        )
    return (
        <Container>
            <Suspense fallback={<div style={{
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <div class="container">
                    <div class="logo-container">
                        <div class="logo">
                            <LogoImg src={Logo64} alt="logo" />
                        </div>
                    </div>

                    <div class="loading-letters">
                        <span>L</span>
                        <span>o</span>
                        <span>a</span>
                        <span>d</span>
                        <span>i</span>
                        <span>n</span>
                        <span>g</span>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                    </div>
                </div>
            </div>}>
                <Staging
                    subtitle={content.subtitle ?? ""}
                    title={content.title ?? ""}
                    options={content.stagingOptions}
                    stagingButton={content.stagingButton ?? ""}
                    links={content.stagingLinks ?? []}
                    image={content?.stagingImage}
                    testimonials={content.fifthSectionContent?.content}
                >
                    {content.description ?? ""}
                </Staging>
            </Suspense>
            <Suspense>
                <SecondSection
                    title={content.secondSectionTitle}
                    subtitle={content.secondSectionSubtitle}
                    images={content.secondSectionIcons}
                    content={content.secondSectionContent?.content}
                />
            </Suspense>
            <Suspense>
                <HomepageFlow
                    title={content.flowTitle}
                    content={content.flowContent?.content}
                />
            </Suspense>
            <Suspense>
                <HomepageChallengeSection
                    title={content.secondSectionTitle}
                    content={content.secondSectionContent?.content}
                />
            </Suspense>
            <Suspense>
                <ThirdSection
                    title={content.thirdSectionTitle ?? ""}
                    subtitle={content.thirdSectionSubtitle ?? ""}
                    description={content.thirdSectionDescription ?? ""}
                    content={content?.thirdSectionColumns?.content}
                    influencer={content?.influencerMessage ?? ""}
                    influencerMobile={content?.influencerMessageMobile ?? ""}
                />
            </Suspense>
            <Suspense>
                <ComparisionSection
                    title={content.comparisonSectionTitle}
                    comparison={content.comparison}
                />
            </Suspense>
            <Suspense>
                <FeatureSection
                    title={content.featureSectionTitle}
                    subtitle={content.featureSectionSubTitle}
                    images={content.featureSectionImages}
                />
            </Suspense>
            <HomepageCloudSection
                title={content.fourthSection}
                button={content.fourthSectionButton}
                links={content.fourthSectionList}
                staticImages={content.staticImages}
                influencer={content.influencer}
            >
                <CloudSectionWrapper>
                    {
                        (() => {
                            const list = [];
                            const allItems = content?.fourthSectionItems?.content;
                            if (!allItems || !allItems.length) {
                                return <div></div>;
                            }
                            let first = 0;
                            let second = 1;
                            let third = 2;
                            for (let i = 0; i < allItems.length; i++) {
                                if (i % 3 === 0) {
                                    list.push(
                                        <CloudItem key={i}>
                                            <CloudItemImage src={"https:" + allItems[first].data.target.fields.file.url} />
                                            <CloudInternalDiv>
                                                <CloudItemTitle>{allItems[second].content[0]?.value}</CloudItemTitle>
                                                <CloudItemParagraph>{allItems[third].content[0]?.value}</CloudItemParagraph>
                                            </CloudInternalDiv>
                                        </CloudItem>
                                    )
                                }
                                first++;
                                second++;
                                third++;
                            }

                            return list;
                        })()
                    }
                </CloudSectionWrapper>
            </HomepageCloudSection>
            <Suspense>
                <HomepageSixthSection
                    title={content.seventhSectionTitle}
                    subtitle={content.seventhSectionSubtitle}
                    elements={content.seventhSectionContent?.content}
                />
            </Suspense>
            <Suspense>
                <RoboticSection
                    title={content.roboticSectionTitle}
                    subtitle={content.roboticSectionSubTitle}
                    description={content.roboticSectionDescription}
                    options={content.roboticSectionOptions}
                />
            </Suspense>
            <Suspense>
                <EffectSection
                    title={content.effectSectionTitle}
                    content={content.effectSectionContent?.content}
                    bottomContent={content.effectSectionBottomContent}
                    button={content.ninthSectionButton}
                    links={content.ninthSectionList}
                />
            </Suspense>

            <Suspense>
                <HomepageMoneyBackSection
                    title={content.tenthSectionTitle}
                    content={content.tenthSectionContent}
                    staticImages={content?.staticImages}
                />
            </Suspense>
            <Suspense>
                <FeedbackSection
                    title={content.fifthSectionTitle}
                    content={content.fifthSectionContent?.content}
                    influencer={content.influencer}
                />
            </Suspense>
            <Suspense>
                <HomepageFAQSection
                    content={content.faqSection}
                    faq={faq}
                    staticImages={content?.staticImages}
                />
            </Suspense>
        </Container>
    )
}

const Container = styled.main`
`;

const CloudSectionWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    grid-gap: 45px;
    max-width: 700px;
    margin-left: auto;
    width: 100%;
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        max-width: 600px;
    }

    @media only screen and (max-width: ${size.maxWidthTablet}) {
        width: calc(100% - 1rem);
        grid-gap: 25px;
    }
`;

const CloudItem = styled.div`
    padding: 32px 56px;
    background-color: var(--main-bg);
    display: flex;
    border-radius: 25px;
    color: var(--white);
    transition:.4s;
    &:last-child {
        img {
            margin-top: 1.5rem;
        }
    }
    @media only screen and (min-width: ${size.maxWidthTablet}){
        &:hover {
            transform: translateX(40px);
        }
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        padding: 15px 26px 15px 36px;
        img {
            margin-top: 2rem!important;
        }
        &:last-child {
            img {
                margin-top: 2rem!important;
            }
        }
        @media only screen and (max-width: 400px) {
        img {
            margin-top: 15%!important
        }
        &:last-child {
            img {
                margin-top: 15%!important;
            }
        }
        }

    }
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        padding: 0;
    }

`

const CloudItemTitle = styled.h4`
    margin-block-start: 0;
    margin-block-end: 1rem;
    font-size:30px;
    line-height: 32px;
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        font-size: 20px;
        line-height: 21px;
    }
`;

const CloudItemParagraph = styled.p`
    font-size:18px;
    line-height: 26px;
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        font-size: 14px;
        line-height: 21px;
    }
`;

const CloudItemImage = styled.img`
    background-color: var(--white);
    border-radius: 50%;
    margin-left: -6rem;
    margin-top: 3rem;
    border: 12px solid var(--white);
    height: fit-content;
    outline: 12px solid rgba(255, 255, 255, 0.2);
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        margin-left: -4.75rem;
        margin-top:1rem;
    }
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        width:35px;
        height: 35px;
        margin-top: 50%!important;
        margin-left: -1.5rem;
        outline: 1px solid transparent;
    }
`;

const CloudInternalDiv = styled.div`
    padding: 1rem 1rem 1rem 3rem;
    @media only screen and (max-width: ${size.minWidthTablet}) {
        padding: 1rem .25rem .5rem .5rem;
    }
`;

const LogoImg = styled.img`
    width: 4rem;
`

export default Homepage;