import React, { useState } from 'react';
import { config } from "../utils/config";
import { fetchAPI } from '../utils/fetch';
import { Loader } from './loader';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';

export const Marketcard = ({
  title,
  industryDescription,
  description,
  cardText,
  imageSrc,
  couponId,
  userData
}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [commentsMessage, setCommentsMessage] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoadingPrm, setLoadingPrm] = useState(false);

  const handleSubscribePrm = () => {
    setLoadingPrm(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: userData.email,
          subscriptionId: userData.stripeId,
          plan: "proMonth",
        }
      )
      .then((response) => {
        setLoadingPrm(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingPrm(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };

  const toggleModal = async (couponId) => {

    if (!userData) {
      return;
    }
    if (userData.totalUsedComment >= 100) {
      setCommentsMessage('');
      try {
        const response = await axios.get(`${config.urlPlatform}/api/v2/content/marketplace-coupon-details?id=${couponId}`);
        const { data } = response;
        if (data.redirectionLink) {
          window.location.href = data.redirectionLink;
        } else {
          setCouponCode(data.couponCode);
          setIsModalOpen(true);
        }
      } catch (error) {
        setErrorMessage('Failed to copy coupon code. Please try again.');
        console.error('Error fetching coupon details', error);
      }
      // setIsModalOpen(!isModalOpen);
    } else {
      setCommentsMessage('You need to generate 100 comments to use this offer');
      setIsModalOpen(true);
    }
  };

  const copyCouponCode = async () => {
    if (couponCode) {
      try {
        await navigator.clipboard.writeText(couponCode);
        setSuccessMessage('Coupon code copied to clipboard!');
      } catch (err) {
        setErrorMessage("Failed to copy coupon code. Please try again.");
        console.error('Failed to copy: ', err);
      }
    }
  };

  return (
    <>
      {errorMessage ? <Error>{errorMessage}</Error> : null}
      {successMessage ? <Success>{successMessage}</Success> : null}
      <CardWrapper>
        <CardHeader>
          <FigureImageText src={imageSrc} />

        </CardHeader>
        <Titlecard>
          <IndustryTitle>{title}</IndustryTitle>
          <Indusrtydescription>{industryDescription}</Indusrtydescription>
          <Descrption>{description}</Descrption>
        </Titlecard>

        <CardFooter>
          <CardText>{cardText}</CardText>
          <Button onClick={() => toggleModal(couponId)}>Get Offer</Button>
        </CardFooter>
      </CardWrapper>
      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <CloseButton onClick={() => setIsModalOpen(false)}>X</CloseButton>
            <CardWrapper1>
              <CardHeader>
                <FigureImageText src={imageSrc} />
                {/* <Titlecard>
                 <IndustryTitle>{title}</IndustryTitle> 
                  <Indusrtydescription>{industryDescriptionmodal}</Indusrtydescription>
                </Titlecard>*/}
              </CardHeader>
              {commentsMessage ? (
                <>
                  <Descrption1>{commentsMessage}</Descrption1>
                  {(userData.plan !== 'proMonth' && (userData.paidStatus === true || userData.paidStatus === false) ||
                    (userData.plan == 'proMonth' && userData.paidStatus === false) || (userData.plan === undefined)) ? (
                    <Buttonprem
                      className={isLoadingPrm ? "loading" : ""}
                      onClick={handleSubscribePrm}
                    >
                      {isLoadingPrm ? <Loader /> : "Get your premium plan"}
                    </Buttonprem>
                  ) : null}
                </>
              ) : (
                <>
                  <Descrption1>Coupon code: {couponCode}</Descrption1>
                  <Buttoncopy onClick={() => copyCouponCode(couponCode)}>COPY CODE</Buttoncopy>
                </>
              )}
              <CardFooter1>
                <CardText1>{cardText}</CardText1>
              </CardFooter1>
            </CardWrapper1>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

const Error = styled.div`
  color: red;
`;

const Success = styled.div`
  color: green;
`;

const CardWrapper = styled.section`
     position: relative;
    box-shadow: rgba(0, 80, 178, 0.1) 1px 5px 6px 0px;
    border-radius: 25px;
    width: 31%;
    min-height: 300px;
    border: 1px solid rgb(243, 243, 243);
    box-sizing: border-box;
    @media only screen and (max-width:1400px){
      ${'' /* padding: 30px 15px 72px; */}
    }
    @media only screen and (max-width:1024px){
      width: 48%;
    }
    @media only screen and (max-width:767px){
      width: 100%;
    }
  
`;
const CardWrapper1 = styled.section`
     position: relative;
    box-shadow: rgba(0, 80, 178, 0.1) 1px 5px 6px 0px;
    border-radius: 25px;

    width: 100%;
    min-height: 310px;
    box-sizing: border-box;
    @media only screen and (max-width:1400px){
      ${'' /* padding: 30px 15px 72px; */}
    }
  
`;
const Titlecard = styled.section`
    text-align: center;
    padding: 0 30px;
    margin-top: 10px;
    margin-bottom: 73px;
`;
const CardHeader = styled.section`
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(0, 80, 178);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    height: 58px;
`;
const FigureImageText = styled.img`
    width: 160px;
    height: 100%;
    object-fit: contain;
`;
const IndustryTitle = styled.div`
  font-family: Space Grotesk;
font-size: 18px;
font-weight: 700;
line-height: 20px;
letter-spacing: 0.02em;
text-align: center;
color:#000;
margin-bottom:20px;
  @media only screen and (max-width:767px){
    font-size: 20px;
    line-height: normal;
  }
`;
const Indusrtydescription = styled.div`
  font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.02em;
text-align: center;
color: #000000;
${'' /* margin-top:15px; */}
@media only screen and (max-width:767px){
    font-size: 16px;
    line-height: normal;
    margin-top:0px;
  }
`;
const Descrption = styled.div`
  font-family: Space Grotesk;
font-size: 16px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0.02em;
text-align: left;
color: #000000;
opacity:0.65;

`;

const Descrption1 = styled.div`
font-family: Space Grotesk;
font-size: 30px;
font-weight: 700;
line-height: 28px;
letter-spacing: 0.02em;
text-align: center;
margin-top:25px;
padding:0 30px;
@media only screen and (max-width:480px){
  font-size: 22px;
  line-height: normal;
}
`;

const CardFooter = styled.div`
  display: flex;
    padding: 13px 30px;
    background: rgb(0, 80, 178);
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    @media only screen and (max-width:1400px){
      padding: 13px 15px;
    }

`;
const CardFooter1 = styled.div`
  display: flex;
    padding: 25px 30px;
    background: rgb(0, 80, 178);
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;

`;

const CardText = styled.div`
font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.02em;
text-align: left;
color:#fff;
@media only screen and (max-width:1400px){
  font-size: 16px;
}
`;
const CardText1 = styled.div`
font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.02em;
text-align: center;
color:#fff;
width:100%;
`;

const Button = styled.div`
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
    background: #FF2400;
    max-width: 152px;
    height: 42px;
    gap: 0px;
    border-radius: 21px;
    border: 2px 0px 0px 0px;
    opacity: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #FFFFFF66;
    cursor:pointer;
    padding:0 10px;
    width:100%;
    box-sizing: border-box;
    @media only screen and (max-width:1400px){
      font-size: 16px;
      max-width: max-content;
    }
`;

const Buttoncopy = styled.div`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #fff;
  background: #FF2400;
  width: 165px;
  height: 31px;
  border-radius: 21px;
  border: 2px solid #FFFFFF66;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: auto;
  margin-top: 25px;
  // Default state
  opacity: 1;
  transition: opacity 0.5s ease; /* Add a transition for smoother animation */

  // Trigger text change and fade-out animation when the button is clicked
  &:active {
    opacity: 0;
    transition: opacity 0.5s ease; /* Include the transition for a smooth fade-out */
  }
`;
const Buttonprem = styled.div`
  font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
    background: #FF2400;
    max-width: 248px;
    height: 31px;
    border-radius: 21px;
    border: 2px 0px 0px 0px;
    opacity: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #FFFFFF66;
    cursor:pointer;
    color:#fff;
    margin:auto;
    margin-top:25px;
    margin-bottom:83px;

`;

const ModalOverlay = styled.div`
    position: fixed;
    background: rgba(0, 0, 0, 0.32);
    inset: 0px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding:0 15px;
`;
const ModalContent = styled.div`
   background: #fff;
    max-width: 400px;
    min-height: 300px;
    ${'' /* border: 1px solid #F3F3F3; */}
    box-shadow: 1px 5px 6px 0px #0050B21A;
    border-radius: 25px;
    position: relative;
    width: 100%;
   
    box-sizing: border-box;
`;
const CloseButton = styled.div`
    position: absolute;
    background: #ffffff00;
    width: 30px;
    text-align: center;
    height: 30px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid rgb(255 255 255);
    top: -26px;
    z-index: 1;
    right: -28px;
    color: #ffff;
    cursor: pointer;
    @media only screen and (max-width:480px){
      top: -40px;
    z-index: 1;
    right: 0px;
    }
`;

