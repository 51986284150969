
export const useGetStoredData = () => {
    try {
        const userData = localStorage.getItem("userData");
        const expiration = localStorage.getItem("expiration");
        
        return {
            userData: JSON.parse(userData),
            expiration: JSON.parse(expiration)
        }
    } catch(error) {
        return null;
    }
}