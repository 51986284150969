import { useState, useEffect } from "react";
import styled from "styled-components";
import salesClouds from "../../assets/sales-clouds.webp";
import salesMascott from "../../assets/sales-mascott.webp";
import { urlPaths } from "../../utils/config";
import { size } from "../../utils/helpers";
import plane1 from "../../assets/planne.png";
import clounImg from "../../assets/Newcloud.webp";
import NoAi from "../../assets/NOai.png";
import RedLinkButton from "../../components/RedLinkButton";

const HomepageCloudSectionNew = ({
    title,
    children,
    button,
    links = [],
    staticImages = [],
    influencer,
}) => {
    const [creditCards, setCreditCards] = useState([]);
    useEffect(() => {
        if (staticImages && staticImages.length > 0) {
            const cardAssets = staticImages.filter(item => ['VISA', 'mastercard', 'American express'].includes(item.fields.title));
            setCreditCards(cardAssets.map(item => `https:${item.fields.file.url}`));
        }
    }, [staticImages]);

    const checkMark = () => {
        return (
            <svg width="25" height="25" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="19" cy="19" r="17.5" fill="#0050B2" stroke="#ECECEC" strokeWidth="3" />
                <path d="M11 20.467L16.2403 25.667L28.3333 13.667" stroke="white" strokeWidth="2" strokeLinecap="round" />
            </svg>
        )
    }
    return (
        <>
            <SectionWrapper>
                <Plane src={plane1} />

                <ContentDiv>

                    {children}
                </ContentDiv>
                
                <BottomBG src={clounImg} alt="Sales clouds" />
                <CommenterAi_sec>
                <CommenterAi>
                    <BottomBG1 src={NoAi} alt="Sales clouds" />
                    <Commenterwrap>
                        <CommenterAiMain>No AI is perfect, and neither is Commenter AI</CommenterAiMain>
                        <CommenterAipara>
                            <p>Commenter AI is great in generating valuable human-like comments. But sometimes, they might not sound exactly like you. That's where you come in!</p>

                            <p>Think of its comments as the first step. You can make them even better by adding your own experience, ideas, and words. You have the magic touch to turn a good comment into something brilliant, or even make it viral!</p>
                            <p>
                                Remember, Commenter AI is like your personal assistant for commenting on LinkedIn<sup>TM</sup>. Not a replacement. It’s here to make your LinkedIn<sup>TM</sup> commenting easier and faster. So go ahead and make the most of it!.
                            </p>
                        </CommenterAipara>
                    </Commenterwrap>
                </CommenterAi>
                </CommenterAi_sec>
            </SectionWrapper>

        </>
    )
}

    const BottomBG1 = styled.img`
   position: absolute;
   width: 187px;
   left: 60px;
   top: 0;
   bottom:0;
   margin:auto;
 @media only screen and (max-width:991px){
        position: static !important;
        margin:auto;
        display: block;
    }
`


const CommenterAi_sec = styled.section`
background: #0357b7;
backdrop-filter: blur(5px);
max-width: 100%;
margin: auto;
position: relative;
// padding:15px;
// padding-left: 210px;
box-sizing: border-box;
margin-top: -160px;
z-index:-1;

@media only screen and (max-width:1200px){
    margin:0 auto;
    ${'' /* width:97%;
    margin-top:40px; */}
    margin-top: -120px;
}
@media only screen and (max-width:991px){
    padding:15px;
    padding-left: 15px;
    max-width: 100%;
    margin-top:40px;
}
@media only screen and (max-width:767px){
   
    margin-top:40px;
}

`;

const CommenterAi = styled.section`
display: flex;
justify-content: center;
    align-items: center;
border-radius: 25px;
backdrop-filter: blur(5px);
${'' /* max-width: 913px; */}
margin: auto;
position: relative;
padding:60px;
Padding-bottom:40px;
box-sizing: border-box;
margin-top: 40px;
gap:70px;

@media only screen and (max-width:1200px){
    margin:0 auto;
    width:100%;
    margin-top:40px;
}
@media only screen and (max-width:991px){
    padding:30px 0px;
    padding-left: 0px;
    max-width: 100%;
    margin-top:0px;
    
}
@media only screen and (max-width:767px){
    flex-direction: column;
}

`;
const Commenterwrap = styled.section`
 padding-left:200px;
    @media only screen and (max-width:991px){
        padding-left:0px;
        max-width: 100%;
    }
`;
const CommenterAiMain = styled.section`
color: #fff;
text-align: left;
font-family: Space Grotesk;
font-size: 38px;
font-style: normal;
font-weight: 700;
line-height: normal;
 @media only screen and (max-width:767px){
    font-size: 27px;
 }
`;
const CommenterAipara = styled.section`
    >p{
        color: #fff;
font-family: Roboto;
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: 30px;
max-width: 700px;

    }
    >p sup {
        font-size: 10px;
        font-family: 'Space Grotesk', sans-serif !important;
    }
`;
const SectionWrapper = styled.section`
    position: relative;
    min-height: 100vh;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
`

const ContentDiv = styled.div`
    position: relative;
    max-width: 1353px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
    box-sizing: border-box;
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        ${'' /* width: calc(100% - 2rem); */}
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        ${'' /* max-width: 900px; */}
    }
`

const SectionTitle = styled.h2`
    text-align: center;
    margin-top: 7.5rem;
    font-size: 53px;
    font-weight: 700;
    line-height: 87px;
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        font-size: 40px;
    }
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        margin-top: 2.5rem;
        margin-bottom: 3rem;
        font-size: 27px;
        line-height: 33px;
    }
`;

const MascottBG = styled.img`
    position: absolute;
    top: 25%;
    left: 0%;
    z-index: -1;
    max-width: 350px;
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        left: -10%;
        max-width: 300px;
    }

    @media only screen and (max-width: ${size.maxWidthTablet}) {
        position: relative;
        max-width: 120px;
        width: 100%;
        left: calc(50% - 53.5px);
        margin-bottom: 20px;
    }
`;

const BottomBG = styled.img`
    width: 100%;
    // position: absolute;
    // bottom: 0;
    left:0;
    z-index: -1;
    ${'' /* position: absolute; */}
    bottom: -24%;
}
    @media only screen and (max-width: ${size.maxWidthTablet}) {
       display:none;
    }
`

const Plane = styled.img`
    position: absolute;
    top: -4.5rem;
    left: calc(50% - 467px);
    z-index: 3;
    @media only screen and (max-width: 1199px) {
        display:none;
    }
`

const Links = styled.div`
    display: flex;
    grid-gap:15px;
    margin-top: 2rem;
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        margin-top: 1rem;
    }
`;

const TheLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    > svg {
        width: 20px;
        height: auto;
        margin-right: .25rem;
    }
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        font-size: 12px;
        line-height: 19px;
    }
`;

const CreditCardList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 30px;
`;

const CreditCardImage = styled.img`
    max-height: 30px;
`;

const InfluencerContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
    padding: 10px;
`;

const InfluencerImage = styled.img`
  margin-top: 2rem;
  max-width: 870px;
  width:100%;
  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 800px;
  }
  @media only screen and (max-width: ${size.minWidthTablet}) {
    margin-top: 0;
  }
`;

export default HomepageCloudSectionNew;