import { useState } from "react";
import styled from "styled-components";
import robotAssistant from "../assets/robot-assistant.svg";
import { content } from "../utils/helpers";
import triangleIcon from "../assets/triangle-box.svg";


const RobotAssistant = ({children, hasHeadline = false, onClick = () => {}}) => {
    const [isFocused, setFocused] = useState(false);
    const [isClicked, setClicked] = useState(false);

    const toggled = () => {
        setFocused(!isFocused)
        setClicked(true);
    }

    return(
        <Robot onClick={() => {toggled()}}>
            <Image src={robotAssistant} style={
                isClicked ? {marginTop:"5px"}: {}
            } onClick={onClick} />
            {
                (() => {
                    if (hasHeadline === true) {
            
                        return isClicked ? <Headline style={{visibility: "hidden"}}>{ content.PublicPages.Welcome.Robot.Headline }</Headline> : <Headline>{ content.PublicPages.Welcome.Robot.Headline }</Headline>;

                    }
                })()
            }
            { isFocused ? 
                <TitleField>
                    <Triangle src={triangleIcon} />
                    <Content>
                        {children}
                    </Content>
                </TitleField> : 
                <div></div>
            }
            
        </Robot>
    );
}

const Robot = styled.figure`
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: column;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-top: auto;
    margin-bottom: auto;
    z-index:1;
    &:hover {
        cursor: pointer;
    }
`;

const Image = styled.img`
    height: 100%;
    max-height: 100%;
    margin-top: -7.5px;
    transition: .4s;
`;

const Headline = styled.p`
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: center;
    margin-left:auto;
    margin-right: auto;
    color: var(--blue-text);
    margin-top:5px;
    transition: .4s;
`;

const TitleField = styled.div`
    position: absolute;
    width: 378px;
    bottom: -230px;
    right: -20px;
    background-color: var(--white);
    box-shadow: 0px 4px 87px rgba(0,0,0,0.25);
    border-radius: 10px;
    padding: 1rem;
    animation: fade-up-quick .5s;
`;
const Content = styled.p``;

const Triangle = styled.img`
    position:absolute;
    top: -15px;
    right: 66px;
`;

export default RobotAssistant;