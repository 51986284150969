import { useState, useRef } from "react";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleAuth from "../components/GoogleAuth";
import { content } from "../utils/helpers";
import Input from "../components/input";
import { NavLink } from "react-router-dom";
import { size } from "../utils/helpers";
import publicLogo from "../assets/public-logo.png";
import { useNavigate } from "react-router-dom";
import { urlPaths } from "../utils/config";
import mobileMascot from "../assets/mobile-message-login.jpg";
import { Loader } from "../components/loader";
import { fetchAPI } from "../utils/fetch";
import { Buffer } from "buffer";
import { config } from "../utils/config";

const ExtensionLoginPage = () => {
    // const sitekey = "6Lf_8-wnAAAAAKg2EjPlV_lC4b4Vn6cJ6H0nXT8I";
    // const recaptcha = useRef();
    const [show, setShow] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setErrorStatus] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isLogged, setLoggedStatus] = useState(false);
    const navigate = useNavigate();

    const handleClick = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await fetchAPI.post(`${config.urlPlatform}/api/v2/auth/login`, {
                withCredentials: true
            }, {
                email: email,
                password: Buffer.from(password , 'ascii').toString('base64')
            });

            if (!isLogged && response?.data?.success === false) {
                setErrorStatus(true);
                setErrorMessage(response.data.message);
                setLoading(false);
            }
            else {
                setLoading(false);
                localStorage.setItem("userData", JSON.stringify(response?.data?.userData));
                localStorage.setItem("expiration", JSON.stringify(response?.data?.expiration));
                // initiatePaymentHandler(response?.data?.userData)
                navigate('/platform', {state: {isFirst: true}});
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            setLoggedStatus(false);
            setErrorStatus(true);
            setErrorMessage("We have server issues");
        }
    }

    const loginWithOauth = async (credentialResponse) => {
        setLoading(true);
        try {
            const response = await fetchAPI.post(`${config.urlPlatform}/api/v2/auth/check-oauth`, {
                withCredentials: true
            }, {
                idToken: credentialResponse.credential
            });

            if (isLogged === false && response?.data?.success === false) {
                setLoggedStatus(false);
                setErrorStatus(true);
                setErrorMessage(response.data.message);
                setLoading(false)
            }
            else {
                setLoggedStatus(true);
                setLoading(false);
                localStorage.setItem("userData", JSON.stringify(response?.data?.userData));
                localStorage.setItem("expiration", JSON.stringify(response?.data?.expiration));
                // initiatePaymentHandler(response?.data?.userData)
                navigate('/platform', {state: {isFirst: true}});
                // navigate('/platform');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            setLoggedStatus(false);
            setErrorStatus(true);
            setErrorMessage("We have server issues");
        }
    }

    const initiatePaymentHandler = (userData) => {
        if(!userData.isFreeTrial && userData?.plan !== 'free' && userData.paidStatus === false){
            fetchAPI.post(`${config.urlPlatform}/api/v2/payment/subscription`, {
                withCredentials: true
            }, {
                email: userData?.email,
                subscriptionId: userData?.stripeId,
            }).then(paymentResponse => {
                // setLoadingOne(false);
                if (paymentResponse.status !== 200) {
                    throw new Error("error");
                }
                if (paymentResponse.data.success === false) {
                    // setErrorMessage(paymentResponse.data.message);
                } else {
                    window.location.replace(paymentResponse.data.url);
                    // setSuccessMessage("Successfully upgraded subscription!")
                    localStorage.setItem("subscription", "pro");

                    // navigate('/platform');
                }
            }).catch(error => {
                console.log("Error: payment/subscription", error.message);
                // setLoadingOne(false);
                // setErrorMessage("There was an issue subscribing. Please contact us!");
            })
        }else{
            // window.location.replace(config.platform);
            navigate('/platform', {state: {isFirst: true}});
        }
    };

    const onErrorWithGoogle = () => {
        setErrorStatus(true);
        setErrorMessage("We have server issues");
    }
    return (
        <LoginContainer>
            <Wrapper>
                <img src={publicLogo} alt="Wellcome to Commenter.ai" onClick={() => { navigate(urlPaths.homenew) }} />
                <Title>Final Step</Title>
                <Content>Fill your login details below and click "Connect" to finish the connection process:</Content>
                <FormContainer id="login" onSubmit={handleClick}>
                    {
                        (() => {
                            if (isError === true) {
                                return (
                                    <ErrorMessage>{errorMessage}</ErrorMessage>
                                )
                            }
                        })()
                    }
                    <Input placeholder='Marco@gmail.com' label='Email address' value={email}
                        changeEvent={(event) => setEmail(event.target.value)} hasIcon={{ active: true, icon: "@" }} />
                    <Input placeholder='********' label='Password' value={password} inputType="password"
                        changeEvent={(event) => setPassword(event.target.value)} hasIcon={{ active: true, icon: "lock" }} />
                    <SubmitButton className={isLoading ? "loading" : ""}>{isLoading ? <Loader /> : "CONNECT"}</SubmitButton>
                    <GoogleAuth
                        text={'continue_with'}
                        onSuccess={loginWithOauth}
                        onError={onErrorWithGoogle}
                        theme="outline"
                    />
                </FormContainer>
            </Wrapper>
            <MobileSuggestion onClick={() => { setShow(false) }} className={show ? "" : "hide"}>
                <MobileImage src={mobileMascot} alt="Desktop suggestion" />
            </MobileSuggestion>
        </LoginContainer>
    );
}

const MobileSuggestion = styled.div`
    opacity:0;
    pointer-events: none;
    z-index: -200;

    @media only screen and (max-width: ${size.maxWidthTablet}) {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index:200;
        opacity: 1;
        pointer-events: all;
        background-color: rgba(0, 0, 0, .75);
        transition: .4s;
        animation: fade-opacity 1s;

        &.hide {
            opacity:0;
            pointer-events: none;
            z-index: -200;
            transition: .4s;
        }
    }
`;

const MobileImage = styled.img`
    position: absolute;
    bottom:5%;
    right:2%;
    width:95%;
    height: auto;
    transition: .4s;
    animation: image-scale 1.5s;
`;

const LoginContainer = styled.main`
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content:space-around;
    align-items: center;
    animation: popUp 1s;
    width:100%;
    min-height: 100vh;
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        animation: popUp .5s;
    }
`;

const Wrapper = styled.section`
    margin-top:auto;
    margin-bottom: auto;
    text-align: center;

    > img {
        &:hover {
            cursor: pointer;
        }
    }

    @media only screen and (min-width: ${size.minWidthTablet}) {
        margin-top: 10rem;
    }

    @media only screen and (max-width: ${size.minWidthTablet}) {
        > img {
            max-width:200px;
        }
    }
`;

const Title = styled.h1`
    text-align: center;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 41px;
    line-height: 155.52%;
    margin-bottom: 0;
    @media only screen and (max-width: ${size.minWidthTablet}) {
        line-height: 120%;
        font-size: 30px;
    }
`;

const Content = styled.p`
    color: #000;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 164.523%; /* 32.905px */
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
`;

const SignUpContainer = styled.div`
    position:relative;
    animation: image-fade-up-main 1.5s;
    margin-top: 1rem;
`;

const FooterColor = styled.p`
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: var(--font-grey-dark);
    > a {
        color: var(--blue-text);
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        transition: .4s;
        &:hover {
            color: var(--main-bg);
            text-decoration: none;
            transition: .4s;
        }
    }
`;

const CaptchaContainer = styled.div`
  margin: 20px auto;
  text-align: center;
  & div{
    margin: auto;
  }
`;

const FormContainer = styled.form`
    width: 100%;
    margin-top: 1rem;
    padding: 1rem 1rem 0;
    box-sizing: content-box;

    @media only screen and (max-width: ${size.minWidthTablet}) {
        width: revert;
        margin-top: 2rem;
    }
`

const SubmitButton = styled.button`
    display: "block";
    width:100%;
    text-align: center;
    color: var(--white);
    border-radius: 8px;
    padding: 1rem 0;
    margin-bottom: 1rem;
    background-color: #FF2400;
    border: 1px solid #FF2400;
    font-family: 'Montserrat',sans-serif;
    font-size: 1rem;
    transition: 0.4s;
    &:hover {
        color: #FF2400;
        background-color: var(--white);
        transition: 0.4s;
        cursor: pointer;
    }
    &.loading {
        background-color: #fedd00;
        border: 1px solid #fedd00;
        transition: .4s;
    }
`;
const ForgetPasswordHolder = styled.div`
    color: var(--main-bg);
    cursor:pointer;
    margin:1rem 0 1rem;
    text-align: right;
    text-decoration:underline;
`;

const ErrorMessage = styled.div`
    color:red;
    font-weight: 600;
    margin-bottom: 2rem;
    animation: fade-up-quick .5s;
`;

export default ExtensionLoginPage;