import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Confetti from "react-confetti";

import KingBadge from "../../assets/kingbadge.png";
import FirstStepBadge from "../../assets/firstStepBadge.png";
import ReadyToCommentBadge from "../../assets/readyToCommentBadge.png";
import RookieBadge from "../../assets/rookieBadge.png";
import SkilledBadge from "../../assets/skilledBadge.png";
import ExpertBadge from "../../assets/expertBadge.png";
import MasterBadge from "../../assets/masterBadge.png";
import UnlishedBadge from "../../assets/unleashed-badge.png";
import rookie from "../../assets/rookie-badge.svg";
import skilled from "../../assets/skilled-badge.svg";
import expert from "../../assets/expert-badge.svg";
import master from "../../assets/master-badge.svg";
import king from "../../assets/king-badge.svg";
import rookieLine from "../../assets/rookie-line.svg";
import goldLine from "../../assets/gold-line.svg";
import firstStep from "../../assets/first-step.svg";
import readyComment from "../../assets/ready-to-comment.svg";
import { fetchAPI } from '../../utils/fetch';
import { config } from '../../utils/config';

const comment = {
    "unlished": 1,
    "rockie": 10,
    "skilled": 50,
    "expert": 200,
    "master": 500,
    "king": 1000,
}

const Badges = () => {
    const [email, setEmail] = useState();
    const [userData, setUserData] = useState();
    const [runConfetti, setRunConfetti] = useState(true);
    const [showConfetti, setShowConfetti] = useState(false);
    const [showCongrate, setShowCongrate] = useState(false);
    const [congrateModal, setCongrateModal] = useState(FirstStepBadge);
    useEffect(() => {
        setEmail(JSON.parse(localStorage.getItem("userData"))?.email);
    }, []);

    useEffect(() => {
        if (email) {
            console.log(email);
            fetchAPI.get(`${config.urlPlatform}/api/v2/badges?email=${email}`, {
                withCredentials: true
            }).then(result => {
                if (result.status == 200) {
                    setUserData(result.data);
                }
            }).catch(error => {
                console.log(error);
            })
        }
    }, [email])

    const isUnLocked = (reward) => {
        if (reward == "firstvideo") {
            if (userData?.watchedFVideo) {
                if (userData?.badges) {
                    if (userData?.badges.indexOf("firstvideo") == -1) return true;
                } else return true;
            }
        } else if (reward == "secondvideo") {
            if (userData?.watchedSVideo) {
                if (userData?.badges) {
                    if (userData?.badges.indexOf("secondvideo") == -1) return true;
                } else return true;
            }
        } else {
            if (userData?.totalUsedComment >= comment[reward]) {
                if (userData?.badges) {
                    if (userData.badges.indexOf(reward) == -1) return true;
                } else return true;
            }
        }
        return false;
    }

    const getRewardImage = (reward) => {
        switch (reward) {
            case "firstvideo":
                return FirstStepBadge;
            case "secondvideo":
                return ReadyToCommentBadge;
            case "unlished":
                return UnlishedBadge;
            case "rookie":
                return RookieBadge;
            case "skilled":
                return SkilledBadge;
            case "expert":
                return ExpertBadge;
            case "master":
                return MasterBadge;
            case "king":
                return KingBadge;
            default:
                return null;
        }
    }

    const unLockBadge = (reward) => {
        fetchAPI.post(`${config.urlPlatform}/api/v2/badges/unlock`, {}, {
            email: email,
            badge: reward
        }).then(result => {
            if (result.status == 200) {
                let temp = userData;
                if (temp.badges) temp.badges.push(reward);
                else temp.badges = [reward];
                setCongrateModal(getRewardImage(reward));
                setUserData({...temp});
                setShowConfetti(true);
                setTimeout(() => {
                    setShowCongrate(true);
                }, (1500));
            }
        })
    }
    console.log(userData);
    return (
        <BadgeContainer>
            { showConfetti &&
            <>
                <Modal onClick={() => {
                    setShowCongrate(false);
                    setShowConfetti(false);
                }}>
                    { showCongrate && <CongrateImg src={congrateModal} className='congrate-img' />}
                </Modal>
                <Confetti 
                    style={{position: "fixed"}}
                    width={window.innerWidth}
                    height={window.innerHeight}
                    gravity={0.3}
                    friction={0.99}
                    wind={0}
                    run={runConfetti}
                    recycle={true}
                />
            </>
            }
            <BadgeHeader>
                Mystery Achievements
            </BadgeHeader>
            <BadgeContent>
                <BadgeStepSection className={userData?.watchedFVideo ? "" : "blur"}>
                    <BadgeName>First Steps</BadgeName>
                    <BadgeView>
                        <Badge src={firstStep} />
                        <BadgeLine src={rookieLine} />
                    </BadgeView>
                    <LockDiv>
                    {/* { isUnLocked("firstvideo") ?
                        <UnLock onClick={() => unLockBadge("firstvideo")}>UnLock</UnLock>
                        : <BlankLock></BlankLock>
                    } */}
                    </LockDiv>
                </BadgeStepSection>
                <BadgeStepSection className={userData?.watchedSVideo ? "" : "blur"}>
                    <BadgeName>Ready to Comment</BadgeName>
                    <BadgeView>
                        <Badge src={readyComment} />
                        <BadgeLine src={rookieLine} />
                    </BadgeView>
                    <LockDiv>
                    {/* { isUnLocked("secondvideo") ?
                        <UnLock onClick={() => unLockBadge("secondvideo")}>UnLock</UnLock>
                        : <BlankLock></BlankLock>
                    } */}
                    </LockDiv>
                </BadgeStepSection>
                <BadgeStepSection className={userData?.totalUsedComment >= 1 ? "" : "blur"}>
                    <BadgeName>Unleashed Commenter</BadgeName>
                    <BadgeView>
                        <Badge src={UnlishedBadge} />
                        <BadgeLine src={rookieLine} />
                    </BadgeView>
                    <LockDiv>
                    {/* { isUnLocked("rockie") ?
                        <UnLock onClick={() => unLockBadge("rockie")}>UnLock</UnLock>
                        : <BlankLock></BlankLock>
                    } */}
                    </LockDiv>
                </BadgeStepSection>
                <BadgeStepSection className={userData?.totalUsedComment >= 10 ? "" : "blur"}>
                    <BadgeName>Rockie Commenter</BadgeName>
                    <BadgeView>
                        <Badge src={rookie} />
                        <BadgeLine src={rookieLine} />
                    </BadgeView>
                    <LockDiv>
                    {/* { isUnLocked("rockie") ?
                        <UnLock onClick={() => unLockBadge("rockie")}>UnLock</UnLock>
                        : <BlankLock></BlankLock>
                    } */}
                    </LockDiv>
                </BadgeStepSection>
                <BadgeStepSection className={userData?.totalUsedComment >= 50 ? "" : "blur"}>
                    <BadgeName className='golden'>Skilled Commenter</BadgeName>
                    <BadgeView>
                        <Badge src={skilled} />
                        <BadgeLine src={goldLine} />
                    </BadgeView>
                    <LockDiv>
                    {/* { isUnLocked("skilled") ?
                        <UnLock onClick={() => unLockBadge("skilled")}>UnLock</UnLock>
                        : <BlankLock></BlankLock>
                    } */}
                    </LockDiv>
                </BadgeStepSection>
                <BadgeStepSection className={userData?.totalUsedComment >= 200 ? "" : "blur"}>
                    <BadgeName className='golden'>Expert Commenter</BadgeName>
                    <BadgeView>
                        <Badge src={expert} />
                        <BadgeLine src={goldLine} />
                    </BadgeView>
                    <LockDiv>
                    {/* { isUnLocked("expert") ?
                        <UnLock onClick={() => unLockBadge("expert")}>UnLock</UnLock>
                        : <BlankLock></BlankLock>
                    } */}
                    </LockDiv>
                </BadgeStepSection>
                <BadgeStepSection className={userData?.totalUsedComment >= 500 ? "" : "blur"}>
                    <BadgeName className='golden'>Master Commenter</BadgeName>
                    <BadgeView>
                        <Badge src={master} />
                        <BadgeLine src={goldLine} />
                    </BadgeView>
                    <LockDiv>
                    { isUnLocked("master") ?
                        <UnLock onClick={() => unLockBadge("master")}>UnLock</UnLock>
                        : <BlankLock></BlankLock>
                    }
                    </LockDiv>
                </BadgeStepSection>
                <BadgeStepSection className={userData?.totalUsedComment >= 1000 ? "" : "blur"}>
                    <BadgeName className='golden'>Comment King/Queen</BadgeName>
                    <BadgeView>
                        <Badge src={king} />
                        <BadgeLine src={goldLine} />
                    </BadgeView>
                    <LockDiv>
                    {/* { isUnLocked("king") ?
                        <UnLock onClick={() => unLockBadge("king")}>UnLock</UnLock>
                        : <BlankLock></BlankLock>
                    } */}
                    </LockDiv>
                </BadgeStepSection>
            </BadgeContent>
        </BadgeContainer>
    )
}

const BadgeContainer = styled.div`
    color: #FFFFFF;
`;

const BadgeHeader = styled.h1`
    color: #FFAE00;
    margin: 0;
    text-align: center;
`;

const BadgeContent = styled.div`
    margin: 5%;
`;

const BadgeStepSection = styled.div`
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 50px;
    &.blur > *{
        filter: blur(4px);
    }
`;

const BadgeName = styled.div`
    font-size: 1.2em;
    width: 210px;
    text-align: right;
    &.golden{
        color: #FFAE00;
    }
`;
const BadgeView = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;
const Badge = styled.img`
    width: 50px;
    height: auto;
`;
const BadgeLine = styled.img`
    width: 5px;
`;

const Reward = styled.div`
    font-size: 1.2em;
    &.golden{
        color: #FFAE00;
    }
`;
const UnLock = styled.div`
    background: linear-gradient(180deg, #EF9B3F 0%, #C8450F 100%);
    text-align: center;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    height: 24px;
`;
const BlankLock = styled.div`
    height: 24px;
    width: 74px;
`
const LockDiv = styled.div`
    width: 100px;
`
const Modal = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const CongrateImg = styled.img`
    width: 550px;
`
export default Badges;