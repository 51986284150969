import styled from "styled-components"
import { useState, lazy, Suspense, useMemo } from "react";
import tickimg from "../../assets/tick1.png";
import { urlPaths } from "../../utils/config";
import LeftTop from "../../assets/LeftTop.png";
import RightTop from "../../assets/RightTop.png";
import RightBottom from "../../assets/RightBottom.png";
import LeftBottom from "../../assets/LeftBottom.png";
import arrowdown from "../../assets/arrowdown.png";
import ButtonIcon from "../../assets/ButtonIcon.png";
import getbutton from "../../assets/getbutton.png";
import Starimg from "../../assets/Starimg.png";
import Icon1 from "../../assets/Icon1.png";
import Icon2 from "../../assets/Icon2.png";
import Icon3 from "../../assets/Icon3.png";
import Thankulogo from "../../assets/Thankulogo.png";
import { Link } from "react-router-dom";


const thankYouPage = () => {


    return (
        <>
            <Container className="main_con_thanks">
                <Imgwrapper className="sectionpad">
                    <div class="screenimgs">
                        <Img1 src={LeftTop} alt="LeftTop" />
                        <Img2 src={RightTop} alt="RightTop" />
                        <Img3 src={RightBottom} alt="RightBottom" />
                        <Img4 src={LeftBottom} alt="LeftBottom" />
                    </div>
                    <Section>
                        <Centericon>
                            <Img7 className="Thankulogo" src={Thankulogo} alt="Thankulogo" />

                        </Centericon>

                        <h1 className="thanksmaintitle">Congratulations, Commenter! <br />You are successfully registered.</h1>
                        <p>To get the most out of the platform add the Chrome extension below:</p>

                        <Centericon>
                            <Img5 className="arrowdown" src={arrowdown} alt="arrowdown" />

                            <Link className="thankubtn" to="https://chromewebstore.google.com/detail/social-post-mate-commente/ffebfjkgjgbpmnoogjjdgfkmiobngdnf" title="">
                                <Span1><Img6 src={ButtonIcon} alt="ButtonIcon" /></Span1>
                                <Span2>Social Post Mate (Commenter AI partner)</Span2>
                                <div class="geturbtn2">get yours now</div>
                            </Link>
                            <p className="staradded"><span><img src={Starimg} alt="Starimg" /></span>
                                <span><img src={Starimg} alt="Starimg" /></span>
                                <span><img src={Starimg} alt="Starimg" /></span>
                                <span><img src={Starimg} alt="Starimg" /></span>
                                <span><img src={Starimg} alt="Starimg" /></span> 317+ people have added it</p>
                            <p className="chorme_today ">BY ADDING IT TO YOUR CHORME TODAY, YOU WILL:</p>
                        </Centericon>

                        <Section2 className="sectionbox2">
                            <Div1 className="boxicon">
                                <img src={Icon1} alt="Icon1" />
                                <p>Easily test the tool <br />during your free trial</p>
                            </Div1>
                            <Div1 className="boxicon">
                                <img src={Icon2} alt="Icon2" />
                                <p>Generate comments in<br /> less than 3 seconds</p>
                            </Div1>
                            <Div1 className="boxicon">
                                <img src={Icon3} alt="Icon3" />
                                <p>Start saving time<br /> immediately</p>
                            </Div1>
                        </Section2>
                        <Section2 className="sectionbox2">

                            <Comments>
                                <p style={{ fontWeight: '400', color: '#0050B2', fontSize: '28px', marginTop: '80px', marginBottom: '0px' }}>
                                    <span style={{ fontWeight: '700', color: '#0050B2', }}>Important:</span>
                                    You get 20 comments daily, refilled every 24 hours (140 total)
                                </p>
                            </Comments>

                        </Section2>



                    </Section>
                </Imgwrapper>

            </Container>


        </>
    )
}



const Comments = styled.div`


@media only screen and (max-width:1480px){
       
    >p {
    font-size: 23px !Important;
}
>span {
        font-size: 23px !Important;
       }
           }
    @media only screen and (max-width:1199px){
       >p {
        font-size: 19px !Important;
       }
       >span {
        font-size: 19px !Important;
       }
>p {
    ${'' /* max-width: 650px;
    margin: auto */}
}
    }
    @media only screen and (max-width:991px){
       
       >p {
        padding-bottom: 50px;
        margin-top: 0 !Important;
        margin-top: 0px !important;
        text-align: center;

       }
           }
     @media only screen and (max-width:767px){
       
       >p {
        padding-bottom: 0px;

        }
    }
`

const Container = styled.div`
    // max-width: 1320px;
    margin: auto;
    width: 100%;
    @media only screen and (max-width:1335px){
        // padding: 0px 15px;
        box-sizing: border-box;
    }

`

const Span1 = styled.div`
position: relative;
    }

`
const Span2 = styled.div`
position: relative;
    }

`
const Span3 = styled.div`
position: relative;
    }

`
const Imgwrapper = styled.div`
position: relative;
    }

`
const Img1 = styled.img`
position: fixed;
    left: 0;
}
`

const Img2 = styled.img`
position: fixed;
    right: 0;
}
`

const Img3 = styled.img`
position: fixed;
    bottom: 0;
    right:0;
}
`

const Img4 = styled.img`
position: fixed;
    bottom: 0;
    left:0;
}
`
const Img5 = styled.img`

}
`
const Img6 = styled.img`

}
`
const Img7 = styled.img`

}
`
// const Link = styled.div`
//     cursor: pointer
//     display: inline-flex;
//     justify-content: center;
//     align-items
//     text-align: center;
//     border: 2px solid red;
//     border-radius: 50px;

// `;


const Section2 = styled.div`

>Div1 {
    display: flex;
    justify-content: center;
}

`;
const Div1 = styled.div`

`;
const Section = styled.div`
    padding:50px 0px 33px;
    @media only screen and (max-width:767px){
    padding:40px 0px 100px;
    }
    >h1 {
    font-family: Space Grotesk;
    font-size: 58px;
    color: #0050B2;
    font-weight: 700;
    line-height: 68.24px;
    text-align:center;
    margin-bottom:18px;
    margin-top: 0px;
    @media only screen and (max-width:767px){
        font-size: 32px;
        line-height: 42px;
        margin-bottom:20px;
    }
}
    >p {
        font-family: Space Grotesk;
        font-size: 28px;
        color: #0050B2;
        font-weight: 400;
        text-align:center;
        margin-bottom:30px;
        margin-top: 0px;
        @media only screen and (max-width:767px){
            font-size: 20px;
            line-height: 35px;
            margin-bottom:20px;
        }
        >a {
            
        }
   
}`


const Centericon = styled.div`
text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 30px
   

    
`
const GridImageLast = styled.img`
     box-shadow: 0px 4px 20px 0px #0000001F;
     border-radius: 15px;
     max-width: 620px;
     @media only screen and (max-width:1023px){
        max-width: 100%;
    }
`
const BottamCloudImage = styled.img`
     width:100%;
     margin-top: -40%;
`
const ButtonTrial = styled.div`
    width: 100%;
    margin: 60px auto 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    @media only screen and (max-width:767px){
        margin: 40px auto 40px;
    }
`
const SliderWrap = styled.div`
    display: flex ! IMPORTANT;
    gap: 35px;
    justify-content: center;
    @media only screen and (max-width:767px){
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
`
const SliderPannelLeft = styled.div`
> h2{
    max-width: 574px;
    min-height: 55px;
    border-radius: 4px 4px 0px 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #FF3131;
    padding:0 15px;
    font-family: Space Grotesk;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.43px;
    text-align: center;
    @media only screen and (max-width:767px){
        font-size: 18px;
        line-height: 28px;
    }
}`

const SliderPannelRight = styled.div`
> h2{
    max-width: 574px;
    min-height: 55px;
    border-radius: 4px 4px 0px 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #0050B2;
    padding:0 15px;
    font-family: Space Grotesk;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.43px;
    text-align: center;
    @media only screen and (max-width:767px){
        font-size: 18px;
        line-height: 28px;
    }
}`










export default thankYouPage;