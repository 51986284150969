import { useState, useEffect } from "react";
import styled from "styled-components";
import Playerboard from "./Playerboard";
import dailyActive from "../../assets/daily-active.svg";
import daily from "../../assets/daily.svg";
import weekly from "../../assets/weekly.svg";
import monthly from "../../assets/monthly.svg";
import alltime from "../../assets/all.svg";
import { fetchAPI } from "../../utils/fetch";
import { config } from "../../utils/config";
import CountdownTimer from "../../components/countdownTimer";

const Leaderboard = () => {
    const [users, setUsers] = useState([]);
    const [email, setEmail] = useState("");
    const [leaderboardType, setLeaderboardType] = useState("daily");
    const [rankTitle, setRankTitle] = useState("Daily Dynamo");
    useEffect(() => {
        setEmail(JSON.parse(localStorage.getItem("userData"))?.email);
    }, []);
    useEffect(() => {
        switch (leaderboardType) {
            case "daily":
                setRankTitle("Daily Dynamo");
                break;
            case "weekly":
                setRankTitle("Weekly Wizard");
                break;
            case "monthly":
                setRankTitle("Monthy Master");
                break;
            default:
                setRankTitle("All Time Ace");
                break;
        }
    }, [leaderboardType]);
    const getEndDate = () => {
        let today = new Date();
        switch (leaderboardType) {
            case "daily":
                return today.toISOString().substr(0, 10);
            case "weekly":
                const startOfWeek = new Date();
                startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() + 6) % 7);
                const day = new Date(startOfWeek);
                day.setDate(day.getDate() + 6);
                return day.toISOString().substr(0, 10);
            case "monthly":
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth() + 1;

                const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
                return `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${daysInMonth.toString().padStart(2, '0')}`;

            default:
                return new Date()

        }
    }
    useEffect(() => {
        if (email) {
            console.log(email);
            fetchAPI.get(`${config.urlPlatform}/api/v2/leaderboard?type=${leaderboardType}&email=${email}`, {
                withCredentials: true
            }).then(result => {
                if (result.status === 200) {
                    setUsers(result.data);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }, [email, leaderboardType]);
    console.log(window.innerWidth);    
    return (
        <>
            <ContentHeader>
                <LeaderboardHeaderItem onClick={() => setLeaderboardType("daily")} className={leaderboardType === "daily" ? "active" : ""}>
                    <img src={daily} />
                    <span>Daily Dynamo</span>
                </LeaderboardHeaderItem>
                <LeaderboardHeaderItem onClick={() => setLeaderboardType("weekly")} className={leaderboardType === "weekly" ? "active" : ""}>
                    <img src={weekly} />
                    <span>Weekly Wizard</span>
                </LeaderboardHeaderItem>
                <LeaderboardHeaderItem onClick={() => setLeaderboardType("monthly")} className={leaderboardType === "monthly" ? "active" : ""}>
                    <img src={monthly} />
                    <span>Monthly Master</span>
                </LeaderboardHeaderItem>
                <LeaderboardHeaderItem onClick={() => setLeaderboardType("")} className={leaderboardType === "" ? "active" : ""}>
                    <img src={alltime} />
                    <span>All Time Ace</span>
                </LeaderboardHeaderItem>
            </ContentHeader>
            <ContentBox>
                <LeaderboardActiveSection>
                    <LeaderboardTitle>
                        <img src={dailyActive} width={50} height={50} />
                        <div>
                            <h1>{rankTitle}</h1>
                            <LeaderboardEndIn>Ends in: {leaderboardType !== "" && <CountdownTimer endDate={getEndDate()} />}</LeaderboardEndIn>
                        </div>
                    </LeaderboardTitle>
                    {/* <Credit>
                        <div>Winner perks:</div>
                        <div>100 Credits</div>
                    </Credit>
                    <Action>
                        <HelpButton>
                            <span>
                                ?
                            </span>
                        </HelpButton>
                        <LastDayResult>
                            <div>Last Day</div>
                            <div>Results</div>
                        </LastDayResult>
                    </Action> */}
                </LeaderboardActiveSection>
                <Playerboard
                    users={users}
                    type={leaderboardType}
                    email={email}
                />
            </ContentBox>
        </>
    )
}
const ContentHeader = styled.div`
    display: flex;
    gap: 30px;    
`;
const ContentBox = styled.div``;
// Leaderboard Active Section
const LeaderboardActiveSection = styled.div`
    margin-top: 20px;
    padding: 20px;
    border-radius: 6px;
    justify-content: space-between;
    background: radial-gradient(91.5% 10533.58% at 7.72% 63.54%, #CF7B01 0%, #4F250D 46.88%, #33180F 78%, #31170E 100%);
    linear-gradient(0deg, #D9D9D9, #D9D9D9);
    display: flex;
    align-items: center;
    & * {
      color: #FFFFFF;  
    }
`;

const LeaderboardHeaderItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.2em;
    justify-content: center;
    padding: 16px;
    cursor: pointer;
    background: linear-gradient(0deg, #0050B2, #0050B2),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border-radius: 8px;
    text-align: center;
    width: 100%;
    color: #FFFFFF;
    &.active{
        background: linear-gradient(0deg, #4B4B4B, #4B4B4B),
        linear-gradient(0deg, #D39816, #D39816);
        border: 1px solid #D39816;
    }
    &.active::after{
        content: url();
    }
`;

const LeaderboardEndIn = styled.p`
    display: flex;
`;

const LeaderboardTitle = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    & > div{
        display: flex;
        flex-direction: column;
        gap: 10px;
        & > *{
            margin: 0;
            padding: 0;
        }
        & > h1 {
            font-size: 2em;
        }
        & > p {
            font-size: 1.2em;
        }
    }
`;
const Credit = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    & > div{
        font-size: 1.2em;
    }
`;
const Action = styled.div`
    display: flex;
    gap: 20px;
`;
const HelpButton = styled.div`
    background: linear-gradient(180deg, #4B4B4B 0%, #242323 100%);
    font-size: 3em;
    width: 50px;
    cursor: pointer;
    border-radius: 8px;
    text-align: center;    
`;
const LastDayResult = styled.div`
    background: linear-gradient(180deg, #EF9B3F 0%, #C8450F 100%);
    width: 150px;
    text-align: center;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
`;

export default Leaderboard;