import styled from "styled-components";
import Select from "react-select";
import { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Link from "./link";
import TimeInput from "react-time-picker-input";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useEffect } from "react";

export const Engagement = ({ engage, idx, isLast, onChange, onDelete }) => {
    const [value, setValue] = useState(engage);
    const [linkEditStatus, setLinkEditStatus] = useState(false);
    const [noteEditStatus, setNoteEditStatus] = useState(false);

    useEffect(() => {
        setValue(engage);
    }, [engage]);

    useEffect(() => {
        onChange(value, idx);
    }, [value]);
    const personTypes = [
        { value: "client", label: "Client" },
        { value: "idealClient", label: "Ideal Client" },
        { value: "supporter", label: "Supporter" },
        { value: "competitor", label: "Competitor" },
        { value: "influencer", label: "Influencer" },
        { value: "other", label: "Other" },
    ];
    useEffect(() => {
        if (!value.note) {
            setNoteEditStatus(true);
        }
    }, [value.note]);
    const showLinkEdit = () => {
        setLinkEditStatus(!linkEditStatus);
    }
    return (
        <Tbrow style={{
            borderBottom: !isLast ? "0.5px solid #ffffff40" : "none"
        }} >
            <Tbody>
                <Select
                    options={personTypes}
                    value={value?.personType ? personTypes.find((type) => type.value === value?.personType) : ""}
                    onChange={(selected) => {
                        setValue({ ...value, personType: selected.value })
                    }}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            maxWidth: "200px",
                            borderRadius: "5px",
                            border: "none",
                            background: "var(--363636, #363636)",
                            color: "white"
                        }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            background: "var(--363636, #363636)",
                            color: "white",
                            border: state.isSelected ? "1px solid" : "none",
                            borderColor: state.isSelected && "red"
                        }),
                        singleValue: (styles) => ({
                            ...styles,
                            color: "white"
                        })
                    }}
                />
            </Tbody>
            <Tbody>
                <Link href={value?.link} style={{ display: (!linkEditStatus ? "inline" : "none"), color: "white !important" }}>{value?.link}</Link>
                <LinkInput style={{ display: linkEditStatus ? "inline" : "none" }}
                    value={engage?.link}
                    onChange={(e) => setValue({ ...value, link: e.target.value })}
                    onKeyUp={(e) => {
                        if (e.code === "Enter") {
                            setLinkEditStatus(false);
                        }
                    }}
                    onBlur={() => {setLinkEditStatus(false)}}
                />
                <IconButton onClick={showLinkEdit}><FaEdit /></IconButton>
            </Tbody>
            <Tbody>
                {/* <TimePicker 
                    value={value?.time} 
                    onChange={(time) => setValue({ ...value, time })} 
                    disableClock={true}  
                    format="H:m"
                /> */}
                <TimeInput 
                    value={value?.time ? value?.time : ""}
                    onChange={(time) => setValue({...value, time})}
                    hour12Format
                    eachInputDropdown
                    manuallyDisplayDropdown
                />
            </Tbody>
            <Tbody>
                <Note style={{ display: !noteEditStatus ? "-webkit-box" : "none" }} onClick={() => setNoteEditStatus(!noteEditStatus)}>
                    {value?.note ? value?.note?.length < 40 ? value.note : (value.note?.slice(0, 40) + "...") : ""}
                </Note>
                <NoteInput
                    style={{ display: noteEditStatus ? "inline" : "none" }}
                    value={engage?.note ? engage?.note : ""}
                    onMouseDown={(e) => console.log(e)}
                    onChange={(e) => setValue({ ...value, note: e.target.value })}
                    onBlur={() => {
                        if (value?.note)
                                setNoteEditStatus(!noteEditStatus);
                    }}
                />
            </Tbody>
            <Tbody>
                {/* <IconButton 
                    disabled={!isChanged}
                    onClick={createOrupdate}
                
                ><FaSave /></IconButton> */}
                <IconButton disabled={!engage}
                    onClick={() => {
                        onDelete(idx);
                    }}
                ><MdDelete />
                </IconButton>
            </Tbody>
        </Tbrow>
    )
}
const Tbrow = styled.tr`
    bborder-radius: 0px 0px 5px 5px;
    background: #353535;
`;
const Tbody = styled.td`
    font-family: Space Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
`;

const LinkInput = styled.input`

`;

const Note = styled.div`
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 2;
    word-wrap: beark-word;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: auto;
    margin-right: auto;
`;
const NoteInput = styled.textarea`
    background: transparent;
    border: none;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
`;

const IconButton = styled.button`
    background: transparent;
    border-radius: 100%;
    color: #FFF;
    padding: 5px;
    cursor: pointer;
    border: none;
    &:hover {
        background: hsla(0,0%,13%,1);
    }
    &:disabled {
        color: rgb(255 255 255 / 49%);
        background: transparent !important;
    }
`;