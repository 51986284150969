import styled from "styled-components";
import RankView from "./RankView";
import avatar from "../../assets/avatar.png";
import { useState } from "react";

const Avatar = ({ name }) => {
    return (
        <>
            <img src={avatar} />
            <span>{name}</span>
        </>
    );
}

const Playerboard = ({ users, type, email }) => {
    const myRank = users.findIndex((user) => user.email === email);
    console.log(myRank, email, users);
    const genCommentHead = () => {
        switch (type) {
            case "daily":
                return "DAILY COMMENTS";
            case "weekly":
                return "WEEKLY COMMENTS";
            case "monthly":
                return "MONTHLY COMMENTS";
            default:
                return "COMMENTS";
        }
    }
    const getCommentCnt = (user) => {
        switch (type) {
            case "daily":
                return user.dailyComments;
            case "weekly":
                return user.weeklyComments;
            case "monthly":
                return user.monthlyComments;
            default:
                return user.totalComments;
        }
    }
    return (
        <PlayerboardContainer>
            <PlayerboardHead>
                <HeadItem>
                    <span>RANK</span>
                </HeadItem>
                <HeadItem>
                    <span>PLAYER NAME</span>
                </HeadItem>
                <HeadItem>
                    <span>SCORE</span>
                </HeadItem>
                <HeadItem>
                    <span>{genCommentHead()}</span>
                </HeadItem>
            </PlayerboardHead>
            <PlayerboardContent>
                {
                    users.map((user, index) => <PlayerboardRow key={index} className={index === myRank ? 'myrank' : ''}>
                        <RankItem>
                            <RankView
                                rankNumber={user.rank}
                            />
                        </RankItem>
                        <RankItem>
                            <Avatar
                                name={index === myRank ? user.name : "blabla"}
                            />
                        </RankItem>
                        <RankItem>
                            <span>{index === myRank ? user.score : "1534"}</span>
                        </RankItem>
                        <RankItem>
                            <span>{index === myRank ? getCommentCnt(user) : "1523"}</span>
                        </RankItem>
                    </PlayerboardRow>)
                }

            </PlayerboardContent>
        </PlayerboardContainer>
    )
}

// Player board
const PlayerboardContainer = styled.div`
    width: 100%;
    font-weight: normal;
    color: #FFFFFF;
`;
const PlayerboardHead = styled.div`
    display: flex;
    color: #999999;
`;
const HeadItem = styled.div`
    flex-basis: 25%;
    padding: 20px;
    text-align: center;
    &:first-child{
        flex-basis: 15%;
    }
    &:nth-child(2){
        text-align: left;
        flex-basis: 35%;
    }
`;

const PlayerboardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const PlayerboardRow = styled.div`
    width: 100%;
    display: flex;
    background: #4B4B4B;
    filter: blur(4px);
    border-radius: 5px;
    ${'' /* filter: blur(3px); */}
    &:first-child,&:nth-child(2),&:nth-child(3){
        background: linear-gradient(360.27deg, rgba(0, 0, 0, 0.22) 25.74%, rgba(255, 182, 0, 0.42) 98.71%),
        linear-gradient(0deg, #4B4B4B, #4B4B4B),
        linear-gradient(180deg, #DE9F17 0%, rgba(222, 159, 23, 0.40625) 37.5%, rgba(222, 159, 23, 0) 100%);
        box-shadow: 2px 4px 17px 0px #FFB60066;
        & > div > div {
            background: #58410D;
            
        }
    }
    &.myrank{
        background: linear-gradient(180deg, #02397D 0%, #0050B2 49.48%, #02397D 100%),linear-gradient(0deg, #1C7BF0, #1C7BF0);
        border: 3px solid #1C7BF0;
        filter: blur(0px);
        & > div > div {
            background: #052F62;
            
        }
    }
`;
const RankItem = styled.div`
    flex-basis: 25%;
    display: flex;
    gap: 10px;
    box-sizing: border-box;
    align-items: center;
    padding: 10px;
    justify-content: center;
    &:first-child{
        padding-left:20px;
        flex-basis: 15%;
    }
    &:nth-child(2){
        justify-content: flex-start;
        padding-left:40px;
        flex-basis: 35%;
    }
`;

export default Playerboard;