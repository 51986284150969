import styled from "styled-components";

export const MainContainer = ({ children }) => {
    return(
        <Container>
            { children }
        </Container>
    )
}

const Container = styled.div`
    margin: 0 1rem;
    max-width: 1300px;
    width: 100%;
    margin-left:auto;
    margin-right: auto;
`;