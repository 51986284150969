import { useState, lazy, Suspense, useMemo } from "react";
import styled from "styled-components";
import { getContent } from "../../hooks/useGetContent";
import backLine from "../../assets/backLine.png";
import SaveTimeComingsoon from "../../features/SaveTimeComingsoon";
import coming from "../../assets/hero-image-last.gif";
import checkIcon from "../../assets/modal-check-icon.png";

const ThirdSectionComingsoon = lazy(() => import("../../layout/sales/ComingsoonThird"));
const SectionFourCommingsoon = lazy(() => import('../../layout/sales/MainPageSectionFourCommingsoon'));
const ComingsoonBrand = lazy(() => import('../../layout/sales/ComingsoonBrand'));

const ComingSoon = () => {
  const [content, setContent] = useState({});
  const [faq, setFAQ] = useState({});
  useMemo(() => {
    getContent().then((response) => {
      if (response["homepage"]) {
        setContent(response["homepage"]);
      }
      if (response["homeFAQ"]) {
        setFAQ(response["homeFAQ"]);
      }
    });
  }, []);

  if (Object.keys(content).length === 0 || Object.keys(faq).length === 0) {
    return <></>;
  }

  return (
    <Container>
      <SectionFourCommingsoon />
      <Suspense>
        <ImageLine src={backLine} />
      </Suspense>
      <Suspense>
        <SaveTimecontent as='h2'>
          Save your time. Get valuable comments. Grow your LinkedIn sales.
        </SaveTimecontent>
        <SaveTimeComingsoon buttonText="Get your 5-day free trial " />
        <CancleAnytime>
          <TextCancel>
            <CheckIcon style={{ width: "13px", height: "13px" }} src={checkIcon} />
            <Text> LinkedIn safe</Text>
          </TextCancel>
          <TextCancel>
            <CheckIcon style={{ width: "13px", height: "13px" }} src={checkIcon} />
            <Text>No credit card required</Text>
          </TextCancel>
        </CancleAnytime>
      </Suspense>
      <Suspense>
        <VideoContainer2>
          <Image src={coming} alt="img" />
        </VideoContainer2>
      </Suspense>
      <Suspense>
        <ComingsoonBrand />
      </Suspense>
      <Suspense>
        <ThirdSectionComingsoon
          title=""
          influencer={content?.influencerMessage ?? ""}
          influencerMobile={content?.influencerMessageMobile ?? ""}
        />
      </Suspense>
    </Container>
  );
};

const Container = styled.main``;
const ImageLine = styled.img`
    display:block;
    margin:auto;
    width:100%;
    margin-top:-1px;
`;
const SaveTimecontent = styled.h2`
   color: #4B4B4B;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    max-width: 1000px;
    margin: auto;
    margin-top: 40px;
    @media only screen and (max-width:767px){
            font-size: 28px;
            margin-bottom:0px !important;
            line-height: normal;
       
     }
`;
const VideoContainer2 = styled.div`
    position: relative;
    text-align:center;
    width: 100%;
    padding:0 15px;
    box-sizing: border-box;
    margin:40px 0 0px;
    @media only screen and (max-width:767px){
        margin:40px 0 20px;
    }
    video {
        position:absolute;
        border-radius: 10px;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index: 2;
    }
    iframe.show {
        z-index: 10;
    }
  
`;

const Image = styled.img`
    width: 100%;
    border-radius: 10px;
    max-width: 674px;
    margin-left:auto;
    margin-right: auto;
    &.hide {
        z-index: -1;
    }
   
`;
const CancleAnytime = styled.div`
  display:flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top:18px;
  @media only screen and (max-width:480px){
    gap: 8px;
    }
`;
const TextCancel = styled.div`
display:flex;
align-items: center;
 gap: 3px;
`;
const CheckIcon = styled.img`
    width: 9px;
    height: 9px;
`;
const Text = styled.div`
    font-family: Space Grotesk;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color:#12100B;
    @media only screen and (max-width:480px){
      font-size: 15px;
    }
`;
export default ComingSoon;
