import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Input from "../components/input";
import { fetchAPI } from "../utils/fetch";
import { config, urlPaths } from "../utils/config";
import { Loader } from "../components/loader";
import { NavLink } from "react-router-dom";

const NewPasswordForm = ({id}) => {
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isRequested, setRequestedPasswordStatus] = useState(false);
    const [isError, setErrorStatus] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [queryParam, setQueryParams] = useSearchParams();

    const handleClick = async (event) => {
        event.preventDefault();
        const queryId = queryParam.get('id');
        setLoading(true);
        try {
            const response = await fetchAPI.post(`${config.urlPlatform}/api/v2/auth/forgot-password?id=${queryId}`, {}, {
                email: email,
                oldPassword: oldPassword,
                newPassword: newPassword
            });
            if(isRequested === false && response.data.success === false) {
                setLoading(false);
                setRequestedPasswordStatus(false);
                setErrorStatus(true);
                setErrorMessage(response.data.message);
            }
            else {
                setRequestedPasswordStatus(true);
                setLoading(false);
                setQueryParams("")
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            setRequestedPasswordStatus(false);
            setErrorStatus(true);
            setErrorMessage("We have server issues");
        }
    }

    return (
        <div>
            {
                (() => {
                    if (isRequested === false) {
                        return (
                        <FormContainer id={id} onSubmit={handleClick}>
                            {
                                (() => {
                                    if (isError === true) {
                                        return(
                                            <ErrorMessage>{ errorMessage }</ErrorMessage>
                                        )
                                    }
                                })()
                            }
                            <Input placeholder='Marco@gmail.com' label='Email Address' value={email} 
                                changeEvent={(event) => setEmail(event.target.value)} hasIcon={{ active: true, icon: "@" }}/>
                            <Input placeholder='********' label='New Password' value={oldPassword} inputType="password"
                                changeEvent={(event) => setOldPassword(event.target.value)} hasIcon={{ active: true, icon: "lock" }} />
                            <Input placeholder='********' label='Confirm Password' value={newPassword} inputType="password"
                                changeEvent={(event) => setNewPassword(event.target.value)} hasIcon={{ active: true, icon: "lock" }} />
                            <SubmitButton className={isLoading ? "loading" : ""}>{isLoading ? <Loader /> : "Submit"}</SubmitButton>
                        </FormContainer>
                        );
                    } else {
                        return (
                            <div>Successfully updated your password. Go to the login page and sign in with your new password.Go and <NavLink to={urlPaths.login}>login</NavLink></div>
                        )
                    }
                })()
            }
        </div>
    )
}

const FormContainer = styled.form`
    width: 100%;
    margin-top: 5rem;
    padding: 1rem 1rem 0;
    box-sizing: content-box;
`

const SubmitButton = styled.button`
    display: "block";
    width:100%;
    text-align: center;
    color: var(--white);
    border-radius: 8px;
    padding: 1rem 0;
    background-color: #FF2400;
    border: 1px solid #FF2400;
    font-family: 'Montserrat',sans-serif;
    font-size: 1rem;
    transition: 0.4s;
    &:hover {
        color: #FF2400;
        background-color: var(--white);
        transition: 0.4s;
        cursor: pointer;
    }
    &.loading {
        background-color: #fedd00;
        border: 1px solid #fedd00;
        transition: .4s;
    }
`;

const ErrorMessage = styled.div`
    color:red;
    font-weight: 600;
    margin-bottom: 2rem;
    animation: fade-up-quick .5s;
`;

export default NewPasswordForm;