import styled from "styled-components"
import { useState, lazy, Suspense, useMemo } from "react";
import { getContent } from "../../hooks/useGetContent";
import Logo64 from "../../assets/logo-64.png";
import { Helmet } from 'react-helmet-async';

const Staging = lazy(() => import('../../layout/sales/AboutUsStage'));
const Vision = lazy(() => import('../../layout/sales/AboutUsVision'));
const CoreValues = lazy(() => import('../../layout/sales/AboutUsCore'));
const MeetOurTeam = lazy(() => import('../../layout/sales/AboutUsMeet'));

const AboutUs = () => {
    const [content, setContent] = useState({});
    const [isLoading, setLoading] = useState(true);

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "About Us",
        "url": "https://commenter.ai/about-us",
        "description": "Learn about the team behind Commenter AI who wants to help with your LinkedIn comments.",
        "about": {
          "@type": "Organization",
          "name": "Commenter.ai",
          "url": "https://commenter.ai"
        }
      };
    
    useMemo(() => {
        getContent().then(response => {
            setContent(response["about"])
        })
        setLoading(false);
    }, []);
    if (isLoading)
        return (
            <div style={{
                position: "absolute",
                minHeight: "100vh",
                width: "100%",
                background: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
                zIndex: 9999
            }}>
                <div class="container">
                    <div class="logo-container">
                        <div class="logo">
                            <LogoImg src={Logo64} alt="logo" />
                        </div>
                    </div>

                    <div class="loading-letters">
                        <span>L</span>
                        <span>o</span>
                        <span>a</span>
                        <span>d</span>
                        <span>i</span>
                        <span>n</span>
                        <span>g</span>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                    </div>
                </div>
            </div>
        )
    return (
    <>
        <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
        <Page>
            <Suspense>
                <Staging
                    title={content.title}
                    subtitle={content.subtitle}
                    stagingContent={content.ceoSectionContent}
                    stagingImages={content.ceoSectionImages}
                    missionContent={content.ourMission}
                    staticImages={content?.staticImages}
                 />
            </Suspense>
            <Suspense>
                <Vision visionContent={content.ourVision} />
            </Suspense>
            <Suspense>
                <CoreValues content={content.ourCoreValues} />
            </Suspense>
            <Suspense>
                <MeetOurTeam meetOurTeam={content.meetOurTeam} bottomRobot={content.bottomRobot}/>
            </Suspense>
        </Page>
        </>
    )
}

const Page = styled.main``

const LogoImg = styled.img`
    width: 4rem;
`


export default AboutUs;