import { useState, Suspense, lazy, useMemo, useEffect } from "react";
import styled from "styled-components";
import { getContent } from "../../hooks/useGetContent";
import Logo64 from "../../assets/logo-64.png";
import { Helmet } from 'react-helmet-async';

const Staging = lazy(() => import("../../layout/sales/PricingStage"));
const Second = lazy(() => import("../../layout/sales/PricingSecond"));
const Feedback = lazy(() => import("../../layout/sales/PricingFeedback"));
const FAQsection = lazy(() => import("../../layout/sales/PricingFAQ"));

const Pricing = () => {
  const [content, setContent] = useState({});
  const [faq, setFAQ] = useState({});
  const [isLoading, setLoading] = useState(true);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Price",
    "url": "https://commenter.ai/pricing",
    "description": "Find the best plans for AI-generated LinkedIn comments. Save time and grow your LinkedIn engagement with the right pricing for you.",
    "about": {
      "@type": "Organization",
      "name": "Commenter.ai",
      "url": "https://commenter.ai"
    }
  };

  useMemo(() => {
    getContent().then((response) => {
      setContent(response["pricing"]);
    });

    getContent().then((response) => {
      setFAQ(response["pricingFAQ"]);
    });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <Page>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            minHeight: "100vh",
            width: "100%",
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            zIndex: 9999,
          }}
        >
          <div class="container">
            <div class="logo-container">
              <div class="logo">
                <LogoImg src={Logo64} alt="logo" />
              </div>
            </div>

            <div class="loading-letters">
              <span>L</span>
              <span>o</span>
              <span>a</span>
              <span>d</span>
              <span>i</span>
              <span>n</span>
              <span>g</span>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Helmet>
          <script type="application/ld+json">
          {JSON.stringify(structuredData)}
          </script>
      </Helmet>
      <Suspense>
        <Staging
          title={content.title}
          pricingPositives={content.pricingPositives}
          staticImages={content?.staticImages}
        />
      </Suspense>
      <Suspense>
        <Second reasons={content.reasonsToChooseUs} />
      </Suspense>
      <Suspense>
        <Feedback feedback={content.feedback} />
      </Suspense>
      <Suspense>
        <FAQsection
          content={content.faq}
          faq={faq}
          staticImages={content?.staticImages}
        />
      </Suspense>
    </Page>
  );
};

const Page = styled.main``;

const LogoImg = styled.img`
  width: 4rem;
`;
export default Pricing;
