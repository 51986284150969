import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ endDate }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(`${endDate} 23:59:59`) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div>
            {timeLeft.days > 0 && (
                <div>
                    {timeLeft.days} day{timeLeft.days > 1 ? 's' : ''}
                </div>
            )}
            <div>
                {timeLeft?.hours?.toString().padStart(2, '0')}:
                {timeLeft?.minutes?.toString().padStart(2, '0')}:
                {timeLeft?.seconds?.toString().padStart(2, '0')}
            </div>
        </div>
    );
};

export default CountdownTimer;