import { MainContainer } from "../../components/mainContainer";
import { Title } from "../../components/Title";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateSession } from "../../hooks/useValidateSession";
import { useGetStoredData } from "../../hooks/useGetStoreData";
import { fetchAPI } from "../../utils/fetch";
import { config } from "../../utils/config";

const SuccessSubscriptionPage = () => {
  const navigate = useNavigate();
  const storedData = useGetStoredData();
  const [email, setEmail] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const urlEmail = queryParams.get('email');
  console.log('QUERYPARAMS = ', queryParams);
  useEffect(() => {
    if (urlEmail) {
      setEmail(urlEmail);
    } else if (storedData.userData?.email) {
      setEmail(storedData.userData.email);
    }
  }, [storedData]);
  // useEffect(() => {
  //   setEmail(storedData.userData?.email);
  // }, [storedData]);

  console.log('USERSTOREDDATA ===>>>', email)
  useEffect(() => {
    try {
      if (email) {
        fetchAPI.post(
          `${config.urlPlatform}/api/v2/auth/regBuyer`,
          {
            withCredentials: true,
          },
          { email: email }
        );

        fetchAPI
          .get(
            `${config.urlPlatform}/api/v2/auth/validatePayment?email=${email}`,
            {
              withCredentials: true,
            }
          )
          .then((result) => {
            console.log("result >>>", result);
            if ("success" in result) {
              // setOverlay(false);
            } else {
              // if (result?.data?.status === USER_SUBSCRIPTION.INACTIVE) {
              //     // setStatus(false);
              //     // setOverlay(true);
              // }
              // if (!result?.data?.notFirst) {
              //     // setOver(true);
              // }
              delete result.data.subscriptionData;
              localStorage.setItem("userData", JSON.stringify(result?.data));
              window.location = "/platform";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) { }
  }, [email]);

  useEffect(() => {
    validateSession()
      .then((result) => {
        if (result.success === false || result === false) {
          //navigate("/unauthorized");
        }
      })
      .catch((error) => {
        //navigate("/unauthorized");
      });
  }, [navigate]);

  return (
    <MainContainer>
      <Title>Successful subscription!</Title>
      <p>You just upgraded your plan, you can continue generating comments</p>
    </MainContainer>
  );
};

export default SuccessSubscriptionPage;
