import styled from "styled-components";
import { size } from "../utils/helpers";

export const Title = ({ children }) => {
    return(
        <Headline>
            { children }
        </Headline>
    )
};

const Headline = styled.h1`
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: var(--main-bg);
    @media only screen and (max-width: ${size.minWidthTablet}) {
        font-size: 27px;
    }
`;