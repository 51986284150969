import styled from "styled-components";
import { size } from "../utils/helpers";
import React, { useEffect, useState } from "react";
import topLeft from "../assets/clip-top-left.png";
import bottomRight from "../assets/clip-bottom.png";
import TimezoneSelect from "react-timezone-select";
import { validateSession } from "../hooks/useValidateSession";
import { useNavigate } from "react-router-dom";
import { fetchAPI } from "../utils/fetch";
import { config } from "../utils/config";
import { Engagement } from "../components/engagement";
import saveIcon from "../assets/engage-save.png";

import InputField from "../components/inputField";
import UnlockAllFeaturesModal from "../components/UnlockAllFeatures";

const EngagementList = () => {
  const navigate = useNavigate();
  const storage = localStorage.getItem("userData");
  const storageJson = JSON.parse(storage);
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    storageJson.timeZone
  );
  const [sizep, setSize] = useState(storageJson.engagementSize);
  const [showCnt, setShowCnt] = useState(storageJson.engagementSize);
  const [list, setList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [erMsg, setEr] = useState("");
  const [sucMsg, setSuc] = useState("");

  console.log(showCnt);
  console.log(list);
  const createEngagement = (engage) => {
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/engage/create`,
        {
          withCredentials: true,
        },
        {
          email: storageJson.email,
          data: engage,
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
        } else {
          console.log(list);
          setList(generateTable(response.data.list));
        }
      })
      .catch((error) => {});
  };
  const generateTable = (list) => {
    let temp = list ? [...list] : [];
    for (let i = 0; i < showCnt - list.length; i++) temp.push({});
    return temp;
  };
  const updateEngagement = (engage, key) => {
    const temp = list.map((row, idx) => {
      if (idx === key) return engage;
      else return row;
    });
    setList(temp);
  };

  const deleteEngagement = (key) => {
    const temp = list.filter((row, idx) => idx !== key);
    setList(temp);
    // fetchAPI.post(`${config.urlPlatform}/api/v2/engage/delete`, {
    //     withCredentials: true
    // }, {
    //     email: storageJson.email,
    //     key
    // }).then(response => {
    //     if (response.status !== 200) {
    //         throw new Error("error");
    //     }
    //     if (response.data.success === false) {

    //     } else {
    //         console.log(generateTable(response.data.list));
    //         const newlist = generateTable(response.data.list);
    //         setList([...newlist]);
    //     }
    // }).catch(error => {
    // })
  };
  const saveList = () => {
    let temp = list.filter((row) => JSON.stringify(row) !== "{}");
    if (!temp || !temp.length) return;
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/engage/saveList`,
        {
          withCredentials: true,
        },
        {
          email: storageJson.email,
          list: temp,
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.msg);
        }
        setSuc("Commenter, your list is SAVED!");
        setTimeout(() => {
          setSuc("");
        }, 3000);
      })
      .catch((error) => {
        setEr(error);
        setTimeout(() => {
          setEr("");
        }, 3000);
      });
  };
  const requestChangeSize = (sizeOf) => {
    if (!storageJson.paidStatus) {
      setOpenModal(true);
      setSize(storageJson.engagementSize);
      return;
    }
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/engage/updateSize`,
        {
          withCredentials: true,
        },
        {
          email: storageJson.email,
          size: sizeOf,
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setSize(storageJson.engagementSize);
        } else {
          setSize(sizeOf);
          let temp = [...list];
          for (let i = 0; i < sizeOf - list.length; i++) {
            temp.push({});
          }
          if (list.length > parseInt(sizeOf)) temp = temp.slice(0, sizeOf);
          setList(temp);
        }
      })
      .catch((error) => {});
  };

  const updateTimeZone = (timezone) => {
    setSelectedTimeZone(timezone);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/engage/updateTimeZone`,
        {
          withCredentials: true,
        },
        {
          email: storageJson.email,
          timezone,
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
        } else {
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    validateSession()
      .then((result) => {
        if (result.success === false || result === false) {
          //navigate("/unauthorized");
        }
      })
      .catch((error) => {
        //navigate("/unauthorized");
      });

    fetchAPI
      .get(
        `${config.urlPlatform}/api/v2/auth/user?email=${storageJson.email}`,
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if (result.status === 200) {
          delete result.data.subscriptionData;
          localStorage.setItem("userData", JSON.stringify(result.data));
          if (result.data.engagementSize) setSize(result.data.engagementSize);
          if (result.data.timeZone) setSelectedTimeZone(result.data.timeZone);
          let tempList = result.data.engagementList
            ? [...result.data.engagementList]
            : [];
          for (
            let i = 0;
            i <
            (result.data.engagementSize
              ? result.data.engagementSize -
                (result.data.engagementList ? result.data.engagementList : 0)
              : 5);
            i++
          ) {
            tempList.push({});
          }
          if (tempList.length > result.data.engagementSize)
            tempList = tempList.slice(0, result.data.engagementSize);
          setList(generateTable(tempList));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    const updatedList = generateTable(list);
    setList(updatedList);
  }, [showCnt]);
  const loadMore = () => {
    if (!storageJson.paidStatus) {
      setOpenModal(true);
      return;
    }
    setShowCnt(parseInt(showCnt) + parseInt(storageJson.engagementSize));
  };

  return (
    <SectionContainer>
      <FigureLeft>
        <FigureImage src={topLeft} />
      </FigureLeft>
      <FigureRight>
        <FigureImage src={bottomRight} />
      </FigureRight>
      <EngagementSection>
        <EngagementTitle>My Engagement List</EngagementTitle>
        <TimeZoneSection>
          {/* <link src="https://youtu.be/_i3aeuhLfQU">How to use this Feature?</link> */}
          <a
            style={{ color: "white", fontSize: 20 }}
            href="https://youtu.be/JUmTkybYYZ8"
            target="_blank"
          >
            How to use this Feature?
          </a>
          <SaveBtn type="button" onClick={saveList}>
            <FloatDiv>
              <MarkIcon src={saveIcon} />
              SAVE LIST
            </FloatDiv>
          </SaveBtn>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TimezoneLabel>Timezone: </TimezoneLabel>
            <TimezoneSelect
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "200px",
                  borderRadius: "5px",
                  border: "none",
                  background: "var(--363636, #363636)",
                  color: "white",
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  background: "var(--363636, #363636)",
                  color: "white",
                  border: state.isSelected ? "1px solid" : "none",
                  borderColor: state.isSelected && "red",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  color: "white",
                }),
              }}
              value={selectedTimeZone}
              onChange={updateTimeZone}
            />
          </div>
        </TimeZoneSection>

        <MainSection>
          <ErrorMsg style={{ display: erMsg ? "block" : "none" }}>
            {erMsg}
          </ErrorMsg>
          <SuccessMsg style={{ display: sucMsg ? "block" : "none" }}>
            {sucMsg}
          </SuccessMsg>
          <EngagementTable>
            <Throw>
              <Thead>Type of person</Thead>
              <Thead>Link</Thead>
              <Thead>Time</Thead>
              <Thead>Note</Thead>
              <Thead>Action</Thead>
            </Throw>
            {list &&
              list.map((engage, key) => {
                return (
                  <Engagement
                    engage={engage}
                    idx={key}
                    isLast={key === list.length - 1}
                    // onCreate={createEngagement}
                    // onUpdate={updateEngagement}
                    onChange={updateEngagement}
                    onDelete={deleteEngagement}
                  />
                );
              })}
          </EngagementTable>
          <LoadMore>
            <LoadMoreBtn onClick={loadMore}>See more links</LoadMoreBtn>
          </LoadMore>
        </MainSection>
        <PaginationSection>
          Show entries per page
          {/* <Input placeholder={''}
                        label={''} 
                        value={size} 
                        changeEvent={(e) => requestChangeSize(e.target.value)} /> */}
          <InputField
            value={sizep}
            onChange={(e) => setSize(e.target.value)}
            onKeyUp={(e) => {
              if (e.code === "Enter") requestChangeSize(e.target.value);
            }}
            onBlur={(e) => requestChangeSize(e.target.value)}
          />
        </PaginationSection>
      </EngagementSection>
      {openModal && (
        <UnlockAllFeaturesModal
          setOpenModal={setOpenModal}
          title="Unlock all the features"
          functions={[
            "3000 comments per month",
            "6 commenting styles",
            "Access to our exclusive community",
            "Monthly AMAs with our co-founder",
            "Special discounts for events, cources",
            "Personalized commenting strategy",
            "30% referral commission",
            "Saving comments - Unlimited",
            "Engagement list - Unlimited",
          ]}
        />
      )}
    </SectionContainer>
  );
};

const SectionContainer = styled.section`
  position: relative;
  padding: 2%;
  background-color: #0050b2;
  font-family: Space Grotesk;
  > * {
    box-sizing: border-box;
  }
`;

const FigureLeft = styled.figure`
  position: absolute;
  top: 0;
  left: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  z-index: 1;
  > img {
    max-width: 400px;
  }
  @media only screen and (max-width: ${size.maxWidthTablet}) {
    > img {
      max-width: 300px;
    }
  }
  @media only screen and (max-width: ${size.minWidthTablet}) {
    > img {
      max-width: 150px;
    }
  }
`;

const FigureRight = styled.figure`
  position: absolute;
  bottom: -4px;
  right: 0;
  z-index: 1;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  > img {
    max-width: 400px;
  }
  @media only screen and (max-width: ${size.maxWidthTablet}) {
    > img {
      max-width: 300px;
    }
  }

  @media only screen and (max-width: ${size.minWidthTablet}) {
    > img {
      max-width: 150px;
    }
  }
`;
const FigureImage = styled.img``;

const EngagementSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  z-index: 2;
`;

const EngagementTitle = styled.h3`
  font-family: Space Grotesk;
  font-size: 48px;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: 0em;
  width: 100%;
  text-align: center;
`;

const TimeZoneSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  @media only screen and (max-width: ${size.maxWidthTablet}) {
    max-width: 600px;
  }
  @media only screen and (max-width: ${size.minWidthTablet}) {
    max-width: 350px;
  }
`;

const TimezoneLabel = styled.span`
  color: #fff;
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 10px;
`;

const MainSection = styled.div`
  width: 100%;
  max-width: 1000px;
  min-height: 500px;
  margin-top: 20px;

  @media only screen and (max-width: ${size.maxWidthTablet}) {
    max-width: 600px;
  }
  @media only screen and (max-width: ${size.minWidthTablet}) {
    max-width: 350px;
  }
`;

const ErrorMsg = styled.div`
  color: red;
  width: 100%;
  text-align: center;
  display: none;
`;
const SuccessMsg = styled.div`
  color: #fff;
  width: 100%;
  text-align: center;
  display: none;
`;
const EngagementTable = styled.table`
  width: 100%;
  border: none;
  border-radius: 5px;
  border-collapse: collapse;
`;

const Throw = styled.tr`
  border-radius: 5px 5px 0px 0px;
  background: #05438e;
`;

const Thead = styled.th`
  border: none;
  color: #fff;
  font-family: Space Grotesk;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const PaginationSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
`;
const LoadMore = styled.div`
  border-top: 0.5px solid rgba(255, 255, 255, 0.25);
  text-align: center;
  padding: 10px 0;
  margin-bottom: 20px;
  background: rgb(53, 53, 53);
  border-radius: 0 0 5px 5px;
`;

const LoadMoreBtn = styled.span`
  text-decoration: underline;
  color: #fff;
  text-align: center;
  font-family: Space Grotesk;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;

const SaveBtn = styled.button`
  background: #fff;
  padding: 8px 28px;
  border: none;
  border-radius: 156px;
  color: #000;
  font-family: Space Grotesk;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 118.1%; /* 22.439px */
  text-transform: uppercase;
  cursor: pointer;
  &:active {
    box-shadow: -1px -1px 19px -4px rgba(0, 0, 0, 0.75) inset;
  }
`;
const FloatDiv = styled.div`
  display: flex;
  align-items: center;
`;
const MarkIcon = styled.img`
  margin-right: 10px;
`;

export default EngagementList;
