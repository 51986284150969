import styled from "styled-components";
import { NavLink } from "react-router-dom";

const SessionExpired = () => {
    return(
        <AccountContainer>
            <Title>Your session has expired!</Title>
            <Content>Please <NavLink to={"/login"}>log in</NavLink> again!</Content>
        </AccountContainer>
    );
}

const AccountContainer = styled.div`
    display: flex;
    align-items: center;
    flex-flow: column;
    > div  {
        width: 100%;
    }
`;

const Title = styled.h1`
    text-align: center;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 41px;
    line-height: 155.52%;
    margin-bottom: 0;
`;

const Content = styled.p`
    font-size: 1rem;
    line-height: 26px;
    text-align: center;
    color: var(--font-grey);
`;

export default SessionExpired;