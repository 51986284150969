import React, { useState } from "react";
import styled from "styled-components";
import useClickOutside from "../hooks/useClickOutside";
import IconLock from "../assets/vector-lock.svg";

const Input = ({placeholder, label, hasIcon = { active: false, icon: "@" }, inputType = "text", changeEvent, value}) => {

    const [isFocused, setFocused] = useState("true");
    const [isInputFilled, setInput] = useState('');

    const toggled = () => {
        setFocused()
    }

    const inputDivBehavior = () => {
        if (isInputFilled.length > 0) {
            return "active"
        }
        return null
    }

    const domNode = useClickOutside(() => {
        setFocused("true")
    })
    return(
        <InputContainer ref={domNode} className={isFocused ? inputDivBehavior() : "focused" }>
            <Label className={isInputFilled.length > 0 ? "active" : null}>{label}</Label>
            <InputField onClick={() => {toggled()}} onChange={changeEvent} defaultValue={value}
            placeholder={placeholder} required aria-required='true' type={inputType}/>
            {
                (() => {
                    if (hasIcon.active === false) {
                        return;
                    }
                    if(hasIcon.icon === "@") {
                        return (
                            <InputIcon>{hasIcon.icon}</InputIcon>
                        )
                    }
                    if(hasIcon.icon === "lock") {
                        return(
                            <InputIcon><img src={IconLock} alt="lock" /></InputIcon>
                        )
                    }
                })()
            }
        </InputContainer>
    );
}

const InputContainer = styled.div`
    position: relative;
    border: 1px solid rgba(33, 33, 33, 0.14);
    border-radius: 8px;
    padding: 0.3125rem 0.5625rem 0.25rem 0.5625rem;
    cursor: text;
    margin-bottom: 1rem;
    transition:0.2s;
    &.active {
        border-bottom: 2px solid #6f9a37;
    }
    &.focused {
        border: 1px solid var(--blue-text);
        transition:0.2s;
        label {
            color: var(--blue-text);
            transition:0.2s;
        }
    }
`

const Label = styled.label`
    display:block;
    margin-top: -.9rem;
    width: fit-content;
    background-color: #fff;
    padding: 0 5px;
    transition:0.2s ease;
    &.active {
        color: var(--blue-text);
        transition:0.2s;
    }
    :not(.error) {
        color: #4b4b4b;
    }
`

const InputField = styled.input`
    border:0px;
    width:100%;
    height: 2.5rem;
    &:focus-visible {
        border:0px;
        outline:none;
    }
`

const InputIcon = styled.div`
    display: block;
    position: absolute;
    right:1rem;
    top: 1.15rem;
    color: var(--font-grey-dark);
    font-size:1.25rem;
`;

export default Input;