import styled from "styled-components";
import { getContent } from "../hooks/useGetContent";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from 'react-router-dom';
import { size, convertArrayToArrayOfObject } from "../utils/helpers";


const StartGrowingList = () => {
    const location = useLocation();

    const [listingContent, setContent] = useState({});
    const [totalTitle, setTitle] = useState();
    const [totalText, setText] = useState();
    const [assetLink, setLink] = useState();
    const [underContent, setcont] = useState();

    useMemo(() => {
        getContent().then(response => {
            setContent(response['pricing'])
            setContent(convertArrayToArrayOfObject(['heading-4', "embedded-asset-block"], response['pricing'].pricingPositives.content))
            const workingArray = response['pricing'].totalValue?.content;
            let totalValueTitle = "";
            let link = "";
            let totalPayText = [];
            let underLineContent = "";
            workingArray.map((item) => {
                if (item.nodeType === "heading-5") {
                    item.content.map((con) => { totalValueTitle += con.value });
                    setTitle(totalValueTitle);
                }
                if (item.nodeType === "heading-6") {
                    item.content.map((con) => { totalPayText += con.value });
                    setText(totalPayText);
                }
                if (item.nodeType === "embedded-asset-block") {
                    link = `https:${item.data.target.fields.file.url}`;
                    setLink(link);
                }
                if (item.nodeType === "paragraph") {
                    item.content.map((con) => { underLineContent += con.value });
                    setcont(underLineContent);
                }
            });
        })

    }, []);

    return (
        <Container>
            <AsideUl className="aside-ul">
                {listingContent && listingContent.length && listingContent.map((content, key) => (
                    content.title &&
                    <AsideList className="aside-list" key={key}>
                        <AsideListIcons src={content.image} />
                        {content.title}
                    </AsideList>
                ))}
            </AsideUl>
            {totalTitle && (
                <TotalValueSector>
                    {
                        location.pathname === '/pricing' ? <TotalValueTitle>{totalTitle}</TotalValueTitle> : ''
                    }
                    <TotalValueText>{totalText}</TotalValueText>
                    {
                        location.pathname === '/pricing' ? <UnderLineText>
                            <CoffeeImg src={assetLink} />
                            {underContent}
                        </UnderLineText> : ''
                    }
                </TotalValueSector>
            )}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
`

const AsideUl = styled.ul`
    list-style-type: "";
    padding-inline-start: 0;
    margin-bottom: 2rem;
    margin-top: 19px;
    @media only screen and (min-width:${size.maxWidthTablet}) {
        margin-top: 48px;
        margin-bottom: 64px;
    }
`;
const AsideList = styled.li`
    margin: 0 18px;
    font-size: 15px;
    display: flex;
    font-weight: 500;
    font-family: Space Grotesk;
    line-height: 130.687%;
    margin-bottom: 10px;
    @media only screen and (min-width: ${size.minWidthTablet}) {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }
    @media only screen and (min-width:${size.maxWidthTablet}) {
        font-size: 20px;
        line-height: 164.52%;
        margin-bottom: 12px;
        padding: 0 46px;
    }
    
`;


const AsideListIcons = styled.img`
    height: 15px !important;
    margin-bottom: -5px;
    margin-right: 4.75px;
    margin-top: 3px;
    @media only screen and (min-width:${size.maxWidthTablet}) {
        height: 16px !important;
        margin-top: 8px;
        margin-right: 8px;
    }
`;

const TotalValueSector = styled.div`
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    width: 100%;
    margin-bottom: 0;
    @media only screen and (min-width:${size.maxWidthTablet}) {
        padding-top: 21px;
        padding-bottom: 21px;
        // padding-bottom: 40px;
    }
`;
const TotalValueTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    @media only screen and (min-width:${size.maxWidthTablet}) {
        font-size: 24px;
        line-height: normal;
        text-transform: capitalize;
        font-weight: 700;
    }
    
`;
const TotalValueText = styled.div`
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    @media only screen and (min-width:${size.maxWidthTablet}) {
        font-size: 20px;
        font-wieight: 500; 
    }
`;
const UnderLineText = styled.div`
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration-line: underline;
    @media only screen and (min-width:${size.maxWidthTablet}) {
        font-size: 20px;
        line-height: 20px;
    }
`;
const CoffeeImg = styled.img`
    width: 11px;
    margin-right: 4px;
    @media only screen and (min-width:${size.maxWidthTablet}) {
        width: 15px;
    }
`;
export default StartGrowingList;