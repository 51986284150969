import axios from "axios";

class FetchAPI {
    fetch;
    constructor(library) {
        this.fetch = library;
    }

    async get(url, configuration = {}) {
        try {
            
            return axios.get(url, configuration);
        } catch(error) {
            console.log(error);
        }
    }

    async post(url, configuration = {}, data ={}) {
        try {

            return axios.post(url, data, configuration);
        } catch(error) {
            console.error(error);
        }
    }

    async put(url, configuration = {}, data ={}) {
        try {

            return axios.put(url, data, configuration);
        } catch(error) {
            console.error(error);
        }
    }

    async delete(url, configuration = {}) {
        try {

            return axios.delete(url, configuration);
        } catch(error) {
            console.error(error);
        }
    }
}

export const fetchAPI = new FetchAPI(axios);