import { useEffect } from 'react';

function FirstPromoter({ email }) {
    useEffect(() => {
      window.fpr('referral', {
          email: email
        });
    }, [email]);
  
    return null;
  }

export default FirstPromoter;