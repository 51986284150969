import styled from "styled-components"
import { useState, lazy, Suspense, useMemo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderImg1 from "../../assets/1-1new.jpeg";
import SliderImg1_2 from "../../assets/1-2new.jpeg";

import SliderImg2 from "../../assets/2-1new.jpeg";
import SliderImg2_2 from "../../assets/2-2new.jpeg";

import SliderImg3 from "../../assets/3-1new.jpeg";
import SliderImg3_2 from "../../assets/3-2new.jpeg";

import BackArrow from "../../assets/backArrow.png";
import NextArrows from "../../assets/nextArrow.png";
import lefttopimg from "../../assets/pricing1.jpg";
import righttopimg from "../../assets/pricing4.jpg";
import righttopimg2 from "../../assets/righttopimg2.png";
import right2img from "../../assets/pricing2.jpg";
import right3img from "../../assets/pricing3.jpg";
import cloudResult from "../../assets/backgroundclouds.png";
import RedLinkButton from "../../components/RedLinkButton";
import { urlPaths } from "../../utils/config";
import { Helmet } from 'react-helmet-async';

// Custom previous and next arrow components with images
const PrevArrow = (props) => (
    <img
      {...props}
      src={BackArrow} 
      alt="Previous"
    />
  );
  const NextArrow = (props) => (
    <img
      {...props}
      src={NextArrows} 
      alt="Next"
    />
  );

const Results = () => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1, // Change this from 0 to 1
        arrows: true,
        prevArrow: <PrevArrow />, // Custom previous arrow component with image
        nextArrow: <NextArrow />, // Custom next arrow component with image
        responsive: [
            {
                breakpoint: 768, // Adjust settings for screens smaller than 768 pixels
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Results",
        "url": "https://commenter.ai/results",
        "description": "See the impact of AI LinkedIn comments. Learn how our tool can help you grow your LinkedIn presence and save time.",
        "about": {
          "@type": "Organization",
          "name": "Commenter.ai",
          "url": "https://commenter.ai"
        }
      };
   
    return (
        <>
        <Helmet>
          <script type="application/ld+json">
          {JSON.stringify(structuredData)}
          </script>
        </Helmet>

            <Container>
              <Section>
                <h1>Before & after using Commenter AI</h1>
              
                <Slider {...sliderSettings} className="SliderMain">
                       <SliderWrap >
                           <SliderPannelLeft>
                           <h2>Before (79 profile views)</h2>
                            <SliderImgWrapper src={SliderImg1} alt="sliderone" />
                           </SliderPannelLeft>
                           <SliderPannelRight>
                           <h2>After (476 profile views)</h2>
                            <SliderImgWrapper src={SliderImg1_2} alt="sliderone" />
                           </SliderPannelRight>
                       </SliderWrap>
                       <SliderWrap >
                           <SliderPannelLeft>
                           <h2>Before (234 Impressions)</h2>
                            <SliderImgWrapper src={SliderImg2} alt="sliderone" />
                           </SliderPannelLeft>
                           <SliderPannelRight>
                           <h2>After (19,538 Impressions)</h2>
                            <SliderImgWrapper src={SliderImg2_2} alt="sliderone" />
                           </SliderPannelRight>
                       </SliderWrap>
                       <SliderWrap >
                           <SliderPannelLeft>
                           <h2>Before (12 Engagements)</h2>
                            <SliderImgWrapper src={SliderImg3} alt="sliderone" />
                           </SliderPannelLeft>
                           <SliderPannelRight>
                           <h2>After (413 Engagements)</h2>
                            <SliderImgWrapper src={SliderImg3_2} alt="sliderone" />
                           </SliderPannelRight>
                       </SliderWrap>
                </Slider>
              </Section>
            </Container>
              <SectionTwo>
              <ContainerTwo>
              <h2>DMs from our customers</h2>
              <GridOne>
                <GridLeft>
                    <GridImageOne src={righttopimg}/>
                    <GridImageOne src={right3img}/>
                </GridLeft>
                <GridRight>
                    <GridImageTwo src={lefttopimg}/>
                </GridRight>
              </GridOne>
              <GridTwo>
                <GridImageLast src={righttopimg2}/>
               </GridTwo>
              </ContainerTwo>
              <ButtonTrial>
                <RedLinkButton href={urlPaths.register} text="Start Your Free Trial Today" />
              </ButtonTrial>
             <BottamCloudImage src={cloudResult}></BottamCloudImage>
            </SectionTwo>
           
            
        </>
    )
}

const Container = styled.div`
    max-width: 1320px;
    margin: auto;
    width: 100%;
    @media only screen and (max-width:1335px){
        padding: 0px 15px;
        box-sizing: border-box;
    }
`
const ContainerTwo = styled.div`
    max-width: 1320px;
    margin: auto;
    width: 100%;
    position: relative;
    z-index: 1;
    @media only screen and (max-width:1335px){
        padding: 0px 15px;
        box-sizing: border-box;
    }
    > h2{
        color:#fff;
        font-family: Space Grotesk;
        font-size: 40px;
        font-weight: 700;
        line-height: 47.24px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 40px;
        @media only screen and (max-width:767px){
            font-size: 32px;
            line-height: 43px;
            margin-bottom: 20px;
    }
    }
`
const Section = styled.div`
    padding:80px 0px 150px;
    @media only screen and (max-width:767px){
    padding:40px 0px 100px;
    }
    >h1 {
    font-family: Space Grotesk;
    font-size: 40px;
    font-weight: 700;
    line-height: 47.24px;
    text-align:center;
    margin-bottom:40px;
    margin-top: 0px;
    @media only screen and (max-width:767px){
        font-size: 32px;
        line-height: 42px;
        margin-bottom:20px;
    }
   
}`
const SectionTwo = styled.div`
padding:80px 0px 0px;
line-height: 0;
background: #0050B2;
@media only screen and (max-width:767px){
    padding:40px 0px 0px;
    }

`
const SliderImgWrapper = styled.img`
    width: 100%;
    object-fit:contain;
`
const GridOne = styled.div`
     width: 100%;
     min-height: 685px;
     display: flex;
     gap: 35px;

     
     @media only screen and (max-width:1300px){
        min-height: 500px;
    }
   
     @media only screen and (max-width:1023px){
       flex-direction:column;
       gap: 20px;
       min-height: auto;
    }

`
const GridTwo = styled.div`
    margin-top: 30px;
    text-align: center;
    @media only screen and (max-width:1023px){
        margin-top: 20px;
    }

`
const GridLeft = styled.div`
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     gap:30px;
     @media only screen and (max-width:1023px){
       flex-direction:column;
       gap: 20px;
    }
    
    @media only screen and (max-width:1200px){
        justify-content: center;
    }
     
`
const GridImageOne = styled.img`
     width: 100%;
     box-shadow: 0px 4px 20px 0px #0000001F;
     border-radius: 15px;
     height: 100%;
     @media only screen and (max-width:1200px){
        object-fit: contain;
        height: max-content;
    }
    
`
const GridImageTwo = styled.img`
     width: 100%;
     height: 100%;
     box-shadow: 0px 4px 20px 0px #0000001F;
     border-radius: 15px;
`
const GridRight = styled.div`
    
`
const GridImageLast = styled.img`
     box-shadow: 0px 4px 20px 0px #0000001F;
     border-radius: 15px;
     max-width: 620px;
     @media only screen and (max-width:1023px){
        max-width: 100%;
    }
`
const BottamCloudImage = styled.img`
     width:100%;
     margin-top: -40%;
`
const ButtonTrial = styled.div`
    width: 100%;
    margin: 60px auto 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    @media only screen and (max-width:767px){
        margin: 40px auto 40px;
    }
`
const SliderWrap = styled.div`
    display: flex ! IMPORTANT;
    gap: 35px;
    justify-content: center;
    @media only screen and (max-width:767px){
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
`
const SliderPannelLeft = styled.div`
> h2{
    max-width: 574px;
    min-height: 55px;
    border-radius: 4px 4px 0px 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #FF3131;
    padding:0 15px;
    font-family: Space Grotesk;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.43px;
    text-align: center;
    @media only screen and (max-width:767px){
        font-size: 18px;
        line-height: 28px;
    }
}`

const SliderPannelRight = styled.div`
> h2{
    max-width: 574px;
    min-height: 55px;
    border-radius: 4px 4px 0px 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #0050B2;
    padding:0 15px;
    font-family: Space Grotesk;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.43px;
    text-align: center;
    @media only screen and (max-width:767px){
        font-size: 18px;
        line-height: 28px;
    }
}`









export default Results;