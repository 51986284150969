import React from 'react';
import styled from 'styled-components';


const UnsecuredApp = ()=> (
  <div>
    <UnsecuredPageWarning>If you see this page, Web App link you have clicked on is under Clickjacking Security Attack.</UnsecuredPageWarning>
    <h2>Please inform team with the reference of the application from where you clicked this link.</h2>
    <h2>Click <Link href={window.self.location.href} title='Web Application' target='blank'>here</Link> to access WebApp safely.</h2>
  </div>
);

const UnsecuredPageWarning = styled.h1`
  color: red;
`;
 
const Link = styled.a`
  text-decoration: none;
  color: red;
`;

export default UnsecuredApp;