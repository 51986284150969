import { Title } from "../../components/Title";
import styled from "styled-components";
import { MainContainer } from "../../components/mainContainer";
import { size } from "../../utils/helpers";
import { lazy, Suspense } from "react";
import { Helmet } from 'react-helmet-async';

const ContactForm = lazy(() => import('../../features/ContactForm'));


const ContactPage = () => {

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Contact us",
        "url": "https://commenter.ai/contact-us",
        "description": "Questions about LinkedIn comments with AI or Commenter AI? Contact us and let us help you.",
        "about": {
          "@type": "Organization",
          "name": "Commenter.ai",
          "url": "https://commenter.ai"
        }
      };
    
    return(
         <>
            <Helmet>
                <script type="application/ld+json">
                {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <MainContainer>
                <Wrapper>
                    <Content>
                        <Title>Contact us!</Title>
                        <Suspense fallback={<div style={{
                            minHeight: "100vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>Loading...</div>}>
                            <ContactForm />
                        </Suspense>
                    </Content>
                </Wrapper>
            </MainContainer>
         </>
        
    )
}

const Wrapper = styled.div`
    margin: 0 1rem;
    min-height: 90vh;
    display: flex;
    justify-content: space-around;
    @media only screen and (min-width: ${size.minWidthTablet}) {
        max-width: 1200px;
    }
`;

const Content = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    h1 {
        text-align: center;
        animation: popUp 1s;
    }
    > div {
        animation: popUp 1s;
    }
    @media only screen and (min-width: ${size.minWidthTablet}) {
        margin-top: 100px;
    }
`;



export default ContactPage;