import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { config } from "../utils/config";
import { fetchAPI } from "../utils/fetch";


import { BloackLoader } from "./loader";
import ribbon from "../assets/modal-ribbon.png";
import modalBack from "../assets/modal_new.png";
import modalIcon2 from "../assets/lock.png";
import checkIcon from "../assets/modal-check-icon.png";
import contentBack from "../assets/modal-condition.png";

const UnlockAllFeaturesModal = ({ setOpenModal, title, functions }) => {
    const navigate = useNavigate();
    const storage = JSON.parse(localStorage.getItem("userData"));
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoadingOne, setLoadingOne] = useState(false);

    const handleClick = () => {
        setLoadingOne(true);
        fetchAPI.post(`${config.urlPlatform}/api/v2/payment/subscription`, {
            withCredentials: true
        }, {
            email: storage.email,
            subscriptionId: storage.stripeId,
        }).then(response => {
            setLoadingOne(false);
            if (response.status !== 200) {
                throw new Error("error");
            }
            if (response.data.success === false) {
                setErrorMessage(response.data.message);
            } else {
                window.location.replace(response.data.url);
                setSuccessMessage("Successfully upgraded subscription!")
                localStorage.setItem("subscription", "pro");
            }
        }).catch(error => {
            setLoadingOne(false);
            setErrorMessage("There was an issue subscribing. Please contact us!");
        })
    }

    return (
        <>
            <Modal className="test" onClick={() => setOpenModal(false)} />
            <ModalContainer>
                <ModalBody >
                    <ModalCaption>
                        <Emotion src={modalIcon2} />
                        <ModalText >
                            <h2> {title}</h2>
                            <p>Achieve more with your comments</p>
                        </ModalText>
                    </ModalCaption>
                    <ModalSubHeader>
                        <p>
                            Subscribe today to boost your results:
                        </p>
                    </ModalSubHeader>
                    <ModalContent>
                        <ContentList>
                            {functions?.map((content, idx) =>
                                <ContentItem key={idx}>
                                    <CheckIcon src={checkIcon} />
                                    <Text>{content}</Text>
                                </ContentItem>
                            )}
                        </ContentList>
                    </ModalContent>
                    <ModalFooter>
                        <FooterText>
                            For only $27/month (less than a cup of coffee per day)
                        </FooterText>
                        <SubscribeButton>
                            <button onClick={handleClick}>
                                {isLoadingOne ? <BloackLoader /> : "GET MY FULL ACCESS"}
                            </button>
                        </SubscribeButton>
                        <FooterText>
                            30-day double money back gurantee
                        </FooterText>
                    </ModalFooter>
                </ModalBody>
            </ModalContainer>


        </>
    )
}

const Modal = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: 100vh;
    position: fixed;
    background-color: rgba(0,0,0,.9);
    
`;


const ModalContainer = styled.div`
    position: fixed;
    top: 0px;
    left: 0%;
    z-index: 1000;
    max-width:600px;
    right:0px;
    height:100vh;
    display:flex;
    justify:center;
    align-items: center;
    justify-content: center;
    margin:auto;
    width:100%
    
`;

const ModalHeader = styled.div`
    width: 440px;
    margin: auto;
    padding: 20px;
    position: abolute;
    margin-top: -50px;
    background-image: url(${ribbon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

const ModalTitle = styled.h1`
    text-align: center;
    margin-top: 0;
    font-size: 32px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    font-family: 'Spicy Rice', sans-serif;
    color: #fff;
`;

const ModalBody = styled.div`
background-image: url(${modalBack});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    max-width:600px;
    width:100%;
    & * {
        font-family: Space Grotesk;
        color: #fff;
    }`;

const ModalCaption = styled.div`
    margin-top: 10px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    justify-content: space-start;
    max-width: 480px;
    margin: auto;
    padding: 0 40px;
    padding-top: 40px;
`;

const Emotion = styled.img`
    width: 70px;
    heigh: 70px;
`;

const ModalText = styled.div`
    text-align:start;
    & > h2{
        font-size: 32px;
        font-weight: 700;
        line-height: 38.5px;
        margin-bottom: 0px;
        font-family: Space Grotesk;
    }
    & > p{
        font-size: 20px;
        font-weight: 700;
        line-height: 38.5px;
        margin-bottom: 10px;
        margin-top:0px;
        font-family: Space Grotesk;
    }
`;


const ModalSubHeader = styled.div`
    text-align: center;
    max-width: 480px;
    margin: auto;
    & > p{
        display: inline-block;
        margin-left: 10px;
        background: #0050B2;
        padding: 10px 40px;
        border-radius: 40px;
        font-family: Space Grotesk;
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        
    }
`;

const ModalContent = styled.div`
    width: 100%;
   
    background-image: url(${contentBack});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 25px 0px;
    padding-top: 10px;
    max-width: 480px;
    margin: auto;
    & > p{
        padding-left: 50px;
        font-family: Space Grotesk;
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
    }
`;

const CheckIcon = styled.img`
    width: 20px;
    height: 20px;
`;

const ContentList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
`;

const ContentItem = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
   
`;

const Text = styled.div`
    font-family: Space Grotesk;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;

const ModalFooter = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0px 40px 70px;
`;

const FooterText = styled.div`
    font-family: Space Grotesk;
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
`;

const SubscribeButton = styled.div`
    text-align: center;
    & > button {
        width: 340px;
        padding: 10px 0;
        border-radius: 40px;
        font-family: Space Grotesk;
        font-size: 23px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        border: none;
        box-shadow: 0px 4px 18px 0px #00000040;
        background: #FFFFFF;
        color: #000000;
        cursor: pointer;
    }
    & > button.loading{
        background-color: #000;
    }
`;

export default UnlockAllFeaturesModal;