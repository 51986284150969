import { config } from "../utils/config";
import { fetchAPI } from "../utils/fetch";

export const getContent = async (string = "homepage") => {
    try {
        const sessionData = sessionStorage.getItem('content');
        if (sessionData) {
            const dataInJson = JSON.parse(sessionData);
            return dataInJson;
        }

        const response = await fetchAPI.get(`${config.urlPlatform}/api/v2/content/`);

        if (response.status > 300) {
            return [{}];
        }
        sessionStorage.setItem('content', JSON.stringify(response.data));

        return response.data;
    } catch(error) {
        console.log(error);

        return {
            success: false
        }
    }
}
