import styled from "styled-components";
import { NavLink } from "react-router-dom";
import SurveyBrand from "../assets/survey-brand.png";

const SurveySuccess = () => {
    return(
        <AccountContainer>
            <BrandImg src={SurveyBrand} />
            <Title>You have successfully cancelled your subscription</Title>
        </AccountContainer>
    );
}

const AccountContainer = styled.div`
    display: flex;
    align-items: center;
    flex-flow: column;
    > div  {
        width: 100%;
    }
`;

const Title = styled.h1`
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
    color: #0c192b;
    margin-bottom: 0;
    position: absolute;
    top: 30%;
`;

const BrandImg = styled.img`
    height: 60px;
`;


export default SurveySuccess;