import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { config } from "../utils/config";
import { fetchAPI } from "../utils/fetch";
import { size } from "../utils/helpers";

import { BloackLoader } from "./loader";
import ribbon from "../assets/modal-ribbon.png";
import modalBack from "../assets/modal_new.png"
import modalIcon from "../assets/modal-emtion-sad.png";
import checkIcon from "../assets/modal-check-icon.png";
import contentBack from "../assets/modal-condition.png";

const TrialExpiredModal = ({ setShow }) => {
    const navigate = useNavigate();
    const storage = JSON.parse(localStorage.getItem("userData"));
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoadingOne, setLoadingOne] = useState(false);
    const closePopup = () => {
        const modal = document.getElementById('modal');
        const modal_content = document.getElementById('modal_content');
       
        modal.style.display = 'none';
        modal_content.style.display = 'none';
    };
    useEffect(() => {
        document.body.addEventListener('click', closePopup);
    }, []);

    const handleClick = () => {
        setLoadingOne(true);
        fetchAPI.post(`${config.urlPlatform}/api/v2/payment/subscription`, {
            withCredentials: true
        }, {
            email: storage.email,
            subscriptionId: storage.stripeId,
        }).then(response => {
            setLoadingOne(false);
            if (response.status !== 200) {
                throw new Error("error");
            }
            if (response.data.success === false) {
                setErrorMessage(response.data.message);
            } else {
                window.location.replace(response.data.url);
                setSuccessMessage("Successfully upgraded subscription!")
                localStorage.setItem("subscription", "pro");
            }
            
        }).catch(error => {
            setLoadingOne(false);
            setErrorMessage("There was an issue subscribing. Please contact us!");
        })
    }

    return (
        <>
            <Modal id="modal" />
            <ModalContainer id="modal_content">
                <ModalHeader>
                    <ModalTitle>
                        Your commenting<br />
                        is over!
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                <ModalWrap>
                    <ModalCaption>
                        <Emotion src={modalIcon} />
                        <ModalText>
                            You can't proceed, your<br />
                            trial has ended!
                        </ModalText>
                    </ModalCaption>
                    <ModalSubHeader>
                        <p>
                            Subscribe today to proceed and get:
                        </p>
                    </ModalSubHeader>
                    <ModalContent>
                        <p>All Commenter AI features together with:</p>
                        <ContentList>
                            <ContentItem>
                                <CheckIcon src={checkIcon} />
                                <Text>3000 comments per month</Text>
                            </ContentItem>
                            <ContentItem>
                                <CheckIcon src={checkIcon} />
                                <Text>Access to our exclusive community</Text>
                            </ContentItem>
                            <ContentItem>
                                <CheckIcon src={checkIcon} />
                                <Text>10% additional referral commission</Text>
                            </ContentItem>
                            <ContentItem>
                                <CheckIcon src={checkIcon} />
                                <Text>Special discounts for events & courses</Text>
                            </ContentItem>
                            <ContentItem>
                                <CheckIcon src={checkIcon} />
                                <Text>eBook - “All-in-one LinkedIn personal branding”</Text>
                            </ContentItem>
                            <ContentItem>
                                <CheckIcon src={checkIcon} />
                                <Text>Priority support</Text>
                            </ContentItem>
                            <ContentItem>
                                <CheckIcon src={checkIcon} />
                                <Text>1x Monthly group AMA Zoom call</Text>
                            </ContentItem>
                            <ContentItem>
                                <CheckIcon src={checkIcon} />
                                <Text>Personalized commenting strategy</Text>
                            </ContentItem>
                        </ContentList>
                    </ModalContent>
                    <ModalFooter>
                        <FooterText>
                            For only $27/month (less than a cup of coffee per day)
                        </FooterText>
                        <SubscribeButton>
                            <button onClick={handleClick}>
                                {isLoadingOne ? <BloackLoader /> : "GET MY FULL ACCESS"}
                            </button>
                        </SubscribeButton>
                        <FooterText>
                            30-day double money back gurantee
                        </FooterText>
                    </ModalFooter>
                </ModalWrap>
                </ModalBody>
            </ModalContainer>
        </>
    )
}
const ModalWrap = styled.div`
    padding:70px;
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        height: 580px;
        overflow: auto;
        padding: 0 52px;
        padding-top:60px;
    }
    @media only screen and (max-width: ${size.mobileWidth}) {
        height: 440px;
        padding: 0 40px;
    }
    @media only screen and (max-width: 480px) {
        padding-top:30px;
    }
`;

const Modal = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: 100vh;
    position: fixed;
    background-color: rgba(0,0,0,.9);
`;

const ModalContainer = styled.div`
    position: fixed;
    top: 0px;
    left: 0%;
    right:0px;
    width: 615px;
    height: max-content;
    z-index: 1000;
    bottom:0px;
    margin:auto;
    @media only screen and (max-width: ${size.minWidthTablet}) {
        width: 100%;
        
    }
    & * {
        font-family: Space Grotesk;
        color: #fff;
    }
    
    @media only screen and (max-width: ${size.mobileWidth}) {
        width: 95%;
        margin:auto;
    }
`;

const ModalHeader = styled.div`
    width: 440px;
    margin: auto;
    padding: 20px;
    position: abolute;
    margin-top: -50px;
    background-image: url(${ribbon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        width: 91%;
        margin:auto;
    }
    @media only screen and (max-width: 480px) {
        width: 83%;
        margin:auto;
    }
   
`;

const ModalTitle = styled.h1`
    text-align: center;
    margin-top: 0;
    font-size: 32px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    font-family: 'Spicy Rice', sans-serif;
    color: #fff;

    @media only screen and (max-width: ${size.mobileWidth}) {
        font-size: 18px;
    }
    @media only screen and (max-width: 480px) {
        line-height: normal;
    }
`;

const ModalBody = styled.div`
    background-image: url(${modalBack});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    max-width:600px;
    width:100%;
    margin:auto;
    margin-top: -65px;
    z-index: -1;
   position: relative;
    @media only screen and (max-width: ${size.maxWidthTablet}) {
       height:700px;
       width: 93%;
    }
    @media only screen and (max-width: ${size.mobileWidth}) {
       height:500px;
    }

    `;

const ModalCaption = styled.div`
    margin-top: 10px;
    display: flex;
    gap: 20px;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: ${size.mobileWidth}) {
        margin-top: 50px;
    }
`;

const Emotion = styled.img`
    width: 70px;
    heigh: 70px;
`;

const ModalText = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    @media only screen and (max-width: ${size.mobileWidth}) {
        font-size: 18px;
    }
`;

const ModalSubHeader = styled.div`
    text-align: center;
    & > p{
        display: inline-block;
        margin-left: 10px;
        background: #0050B2;
        padding: 10px 40px;
        border-radius: 40px;
        font-family: Space Grotesk;
        font-size: 19px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
       
        @media only screen and (max-width: ${size.maxWidthTablet}) {
            font-size: 18px;
    }
    @media only screen and (max-width: ${size.maxWidthTablet}) {
            font-size: 16px;
            margin-left:0px;
    }
    }

    
`;

const ModalContent = styled.div`
    width: 100%;
    background-image: url(${contentBack});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 10px;
    & > p{
        padding-left: 50px;
        font-family: Space Grotesk;
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
    }
`;

const CheckIcon = styled.img`
    width: 20px;
    height: 20px;
`;

const ContentList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 20px 30px;
`;

const ContentItem = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
`;

const Text = styled.div`
    font-family: Space Grotesk;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;

const ModalFooter = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const FooterText = styled.div`
    font-family: Space Grotesk;
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
`;

const SubscribeButton = styled.div`
    text-align: center;
    & > button {
        width: 340px;
        padding: 10px 0;
        border-radius: 40px;
        font-family: Space Grotesk;
        font-size: 23px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        border: none;
        box-shadow: 0px 4px 18px 0px #00000040;
        background: #FFFFFF;
        color: #000000;
        cursor: pointer;
        @media only screen and (max-width: ${size.mobileWidth}) {
            font-size: 16px;
            width: 200px;
        }
    }
    & > button.loading{
        background-color: #000;
    }
`;

export default TrialExpiredModal;