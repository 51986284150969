import { fetchAPI } from "../utils/fetch";
import { config } from "../utils/config";

export const validateSession = async () => {
    try {
        const storageData = checkSessionFromLocalStorage();
        const response = await fetchAPI.get(`${config.urlPlatform}/api/v2/status?email=${storageData.userData.email}`, {
            withCredentials: true
        });
        
        return response.data;
    } catch(error) {
        return false;
    }
}

const checkSessionFromLocalStorage = () => {
    const userData = localStorage.getItem("userData");
    const userDataJSON = JSON.parse(userData);
    const tokenExpiration = localStorage.getItem("expiration");

    const date = new Date();
    if (date.getTime() > tokenExpiration) {
        return {
            isSessionValid: false
        };
    }

    return {
        userData: userDataJSON,
        isSessionValid: true
    };
}