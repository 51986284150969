import styled from "styled-components"
import { PiCopySimpleLight } from "react-icons/pi";
import { FiTrash2 } from "react-icons/fi";


export const SavedComment = ({ comment, removeComment, isLast }) => {

    const copyToClipboard = () => {
        navigator.clipboard.writeText(comment.comment);
    }

    return (
        <CommentContainer style={{ borderBottom: isLast ? "none" : "0.5px solid rgba(255, 255, 255, 0.25)" }}>
            <CommentSub>
                {comment ? comment.comment : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Auctor eu augue ut lectus. Eleifend quam adipiscing vitae proin sagittis nisl rhoncus. Praesent tristique magna sit amet purus gravida quis blandit. Consequat ac felis donec et odio. Massa massa ultricies mi quis hendrerit dolor magna. Scelerisque fermentum dui faucibus in ornare quam. Arcu dui vivamus arcu felis. Et malesuada fames ac turpis. Quis eleifend quam adipiscing vitae proin sagittis nisl rhoncus. Non nisi est sit amet facilisis magna etiam tempor.`}
            </CommentSub>
            {comment?.comment &&
                <Action>
                    <IconButton onClick={copyToClipboard}>
                        <PiCopySimpleLight fontSize={30} />
                    </IconButton>
                    <IconButton onClick={() => { removeComment(comment.id) }}>
                        <FiTrash2 fontSize={25} />
                    </IconButton>
                </Action>
            }
        </CommentContainer>
    )
}

const CommentContainer = styled.div`
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 45px;
`;

const CommentSub = styled.div`
    flex: 7 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    word-wrap: beark-word;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 20px;
    padding-right: 20px;
`;

const Action = styled.div`
    display: flex;
    flex: 2 1;
    justify-content: center;
    text-align: center;
`;

const IconButton = styled.button`
    background: transparent;
    border-radius: 100%;
    color: #FFF;
    padding: 5px;
    cursor: pointer;
    border: none;
    &:hover {
        background: hsla(0,0%,13%,1);
    }
    &:disabled {
        color: rgb(255 255 255 / 49%);
        background: transparent !important;
    }
`;