import styled from "styled-components";
import { useState, useEffect } from "react";
import { content } from "../utils/helpers";
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import { size } from "../utils/helpers";
import publicLogo from "../assets/survey-brand.png";
import { Loader } from "../components/loader";
import { fetchAPI } from "../utils/fetch";
import { config } from "../utils/config";
import FirstPromoter from "../hooks/useFirstPromoter";
import SocialPostMate from "../assets/social-post-mate.png";

const SuccessRegisterPage = () => {
  // const [queryParams, setQueryParams] = useSearchParams();
  // const [error, setError] = useState("");
  // const navigate = useNavigate();

  // const email = queryParams.get('email');
  // const password = queryParams.get('password');
  // const saleParameter = queryParams.get('sale');
  // if (email && password) {
  //     const json = { email: email, password: password };
  //     localStorage.setItem("loginInfo", JSON.stringify(json));
  // }
  // const planType = queryParams.get('planType');
  // useEffect(() => {
  //     const user = JSON.parse(localStorage.getItem("loginInfo"));
  //     // setQueryParams("");
  //     if (user.email && user.password) {
  //         // fetchAPI.post(`${config.urlPlatform}/api/v2/auth/regBuyer`,{
  //         //     withCredentials: true
  //         // }, {email: email});

  //         fetchAPI.post(`${config.urlPlatform}/api/v2/auth/login`, {
  //             withCredentials: true
  //         }, {
  //             email: user.email,
  //             password: user.password
  //         }).then((response) => {
  //             if (response?.data?.success === false) {
  //                 setError(response.data.message);
  //             }
  //             else {
  //                 console.log("response >>>>", response);

  //                 localStorage.setItem("userData", JSON.stringify(response?.data?.userData));
  //                 localStorage.setItem("expiration", JSON.stringify(response?.data?.expiration));

  //                 // navigate('/platform');

  //                 // payment step start here for pro
  //                 if (!response?.data?.userData?.isFreeTrial && response?.data?.userData?.plan !== 'free') {
  //                     fetchAPI.post(`${config.urlPlatform}/api/v2/payment/subscription`, {
  //                         withCredentials: true
  //                     }, {
  //                         email: response?.data?.userData?.email,
  //                         subscriptionId: response?.data?.userData?.stripeId,
  //                         sale: !!saleParameter
  //                     }).then(paymentResponse => {
  //                         // setLoadingOne(false);
  //                         if (paymentResponse.status !== 200) {
  //                             throw new Error("error");
  //                         }
  //                         if (paymentResponse.data.success === false) {
  //                             // setErrorMessage(paymentResponse.data.message);
  //                         } else {
  //                             window.location.replace(paymentResponse.data.url);
  //                             // setSuccessMessage("Successfully upgraded subscription!")
  //                             localStorage.setItem("subscription", "pro");

  //                             localStorage.setItem("userData", JSON.stringify(response?.data?.userData));
  //                             localStorage.setItem("expiration", JSON.stringify(response?.data?.expiration));

  //                             // navigate('/platform');
  //                         }
  //                     }).catch(error => {
  //                         console.log("Error: payment/subscription", error.message);
  //                         // setLoadingOne(false);
  //                         // setErrorMessage("There was an issue subscribing. Please contact us!");
  //                     })
  //                 } else {
  //                     navigate('/platform');
  //                 }

  //                 // payment step endt here for pro

  //             }
  //         }).catch(error => {
  //             console.log(error);
  //             setError(error.message);
  //         })
  //     }

  // }, [email, password, navigate, setQueryParams])

  const [queryParams, setQueryParams] = useSearchParams();
  const email = queryParams.get("email");

  console.log(email, ":::::email");

  setTimeout(() => {
    window.location.href =
      "https://chromewebstore.google.com/detail/social-post-mate/ffebfjkgjgbpmnoogjjdgfkmiobngdnf";
  }, 10000);

  return (
    <LoginContainer>
      <Wrapper>
        <img src={publicLogo} alt="Wellcome to Commenter.ai" />
        <Title>
          Congratulations, Commenter! <br /> You have successfully registered
        </Title>
        <Content>
          {/* <p style={{ color: "green" }}>Successfull registration</p>
                    {
                            
                    } */}
          <p>
            Now, in the next few seconds you'll be redirected to our partner{" "}
            <br /> extension - Social post mate
            <ExtensionIcon src={SocialPostMate} alt="icon" />
          </p>
          <p>
            Install & connect it with our platform and you'll be able to <br />{" "}
            generate comments directly on LinkedIn!
          </p>
        </Content>
        <FirstPromoter email={email} />
        <ErrorMessage>
          If you are not redirected in the next 10 seconds, please,&nbsp;
          <ExtensionLink href="https://chromewebstore.google.com/detail/social-post-mate/ffebfjkgjgbpmnoogjjdgfkmiobngdnf">
            click here...
          </ExtensionLink>
        </ErrorMessage>
      </Wrapper>
    </LoginContainer>
  );
};

const LoginContainer = styled.main`
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  animation: popUp 1s;
  width: 100%;
  min-height: 100vh;
`;

const Wrapper = styled.section`
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;

  @media only screen and (min-width: ${size.minWidthTablet}) {
    margin-top: 10rem;
  }
`;

const ErrorMessage = styled.div`
  font-family: Space Grotesk;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: center;
`;

const ExtensionLink = styled.a`
  text-decoration: underline;
`;

const ExtensionIcon = styled.img`
  width: 24px;
  height: auto;
  vertical-align: middle;
`;

const Title = styled.h1`
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 41px;
  line-height: 155.52%;
  margin-bottom: 0;
`;

const Content = styled.div`
  margin-top: 42px;
  font-family: Space Grotesk;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 42px;
`;

export default SuccessRegisterPage;
