import styled from "styled-components";
import { content } from "../utils/helpers";
import NewPasswordForm from "../features/NewPasswordForm";
// import Link from "../components/link";
import { size } from "../utils/helpers";
import { NavLink } from "react-router-dom";
import { urlPaths } from "../utils/config";

const NewPasswordPage = () => {
    return(
        <NewPasswordContainer>
            <Title>{ content.PublicPages.NewPassword.Title }</Title>
            <Content>{ content.PublicPages.NewPassword.content }</Content>
            <NewPasswordForm id="new-password" />
            <SignUpContainer><FooterColor>{ content.PublicPages.Login.SignUp } <NavLink to={urlPaths.register}>Sign Up</NavLink></FooterColor></SignUpContainer>
        </NewPasswordContainer>
    );
}

const NewPasswordContainer = styled.div`
    display: flex;
    align-items: center;
    flex-flow: column;
    > div  {
        width: 100%;
    }
`;

const Title = styled.h1`
    text-align: center;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 41px;
    line-height: 155.52%;
    margin-bottom: 0;
`;

const SignUpContainer = styled.div`
    position: absolute;
    bottom: 0;
    animation: image-fade-up-main 7s;
    @media only screen and (max-width:${size.minWidthTablet}){
        position:relative;
    }
`;

const FooterColor = styled.p`
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: var(--font-grey-dark);
`;

const Content = styled.p`
    font-size: 1rem;
    line-height: 26px;
    text-align: center;
    color: var(--font-grey);
`;

export default NewPasswordPage;