import styled from "styled-components";
import { size } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { validateSession } from "../../hooks/useValidateSession";

import Leaderboard from "../../layout/achievements/Leaderboard";
import Rewards from "../../layout/achievements/Rewards";
import Badges from "../../layout/achievements/Badges";
import Features from "../../layout/achievements/Features";
import Referals from "../../layout/achievements/Referals";

import topLeft from "../../assets/clip-top-left.png";
import bottomRight from "../../assets/clip-bottom.png";
import doubleArrow from "../../assets/double-arrow.svg";

const AchievementsPage = () => {
  const navigate = useNavigate();
  const [boardSection, setBoardSection] = useState("leaderboard");

  useEffect(() => {
    validateSession()
      .then((result) => {
        if (result.success === false || result === false) {
          //navigate("/unauthorized");
        }
      })
      .catch((error) => {
        //navigate("/unauthorized");
      });
  }, [navigate]);

  return (
    <SectionContainer>
      <FigureLeft>
        <FigureImage src={topLeft} />
      </FigureLeft>
      <FigureRight>
        <FigureImage src={bottomRight} />
      </FigureRight>
      <LeaderboardContainer>
        <LeaderboardSidebar>
          <LeaderboardSidebarItem
            className={boardSection === "leaderboard" ? "active" : ""}
            onClick={(e) => setBoardSection("leaderboard")}
          >
            <span>LEADERBOARDS</span>
            {boardSection === "leaderboard" ? <img src={doubleArrow} /> : ""}
          </LeaderboardSidebarItem>
          <LeaderboardSidebarItem
            className={boardSection === "rewards" ? "active" : ""}
            onClick={(e) => setBoardSection("rewards")}
          >
            <span>REWARDS</span>
            {boardSection === "rewards" ? <img src={doubleArrow} /> : ""}
          </LeaderboardSidebarItem>
          <LeaderboardSidebarItem
            className={boardSection === "badges" ? "active" : ""}
            onClick={(e) => setBoardSection("badges")}
          >
            <span>BADGES</span>
            {boardSection === "badges" ? <img src={doubleArrow} /> : ""}
          </LeaderboardSidebarItem>
          <LeaderboardSidebarItem
            className={boardSection === "features" ? "active" : ""}
            onClick={(e) => setBoardSection("features")}
          >
            <span>FEATURES</span>
            {boardSection === "features" ? <img src={doubleArrow} /> : ""}
          </LeaderboardSidebarItem>
          <LeaderboardSidebarItem
            className={boardSection === "referals" ? "active" : ""}
            onClick={(e) => setBoardSection("referals")}
          >
            <span>REFERALS</span>
            {boardSection === "referals" ? <img src={doubleArrow} /> : ""}
          </LeaderboardSidebarItem>
        </LeaderboardSidebar>
        <LeaderboardContent>
          {boardSection === "leaderboard" ? <Leaderboard /> : ""}
          {boardSection === "rewards" ? <Rewards /> : ""}
          {boardSection === "badges" ? <Badges /> : ""}
          {boardSection === "features" ? <Features /> : ""}
          {boardSection === "referals" ? <Referals /> : ""}
        </LeaderboardContent>
      </LeaderboardContainer>
    </SectionContainer>
  );
};

const SectionContainer = styled.section`
  position: relative;
  padding: 2%;
  background-color: #0050b2;
  font-family: Space Grotesk;
  > * {
    box-sizing: border-box;
  }
`;

const FigureLeft = styled.figure`
  position: absolute;
  top: 0;
  left: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  z-index: 1;
  > img {
    max-width: 400px;
  }
  @media only screen and (max-width: ${size.maxWidthTablet}) {
    > img {
      max-width: 300px;
    }
  }
  @media only screen and (max-width: ${size.minWidthTablet}) {
    > img {
      max-width: 150px;
    }
  }
`;

const FigureRight = styled.figure`
  position: absolute;
  bottom: -4px;
  right: 0;
  z-index: 1;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  > img {
    max-width: 400px;
  }
  @media only screen and (max-width: ${size.maxWidthTablet}) {
    > img {
      max-width: 300px;
    }
  }

  @media only screen and (max-width: ${size.minWidthTablet}) {
    > img {
      max-width: 150px;
    }
  }
`;
const FigureImage = styled.img``;

const LeaderboardContainer = styled.div`
  position: relative;
  left: 2%;
  top: 3%;
  z-index: 2;
  background-color: #2d2d2d;
  width: 96%;
  box-shadow: 6px 11px 14px 0px #00000026;
  border-radius: 15px;
  display: flex;
  padding: 30px;
  gap: 30px;
`;
// sidebar
const LeaderboardSidebar = styled.div`
  width: 20%;
`;
const LeaderboardSidebarItem = styled.div`
  color: #ffffff;
  border: 1px solid #ffffff;
  margin-bottom: 10px;
  padding: 16px;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 1.2em;
  transform: skew(10deg);
  & > * {
    display: block;
    transform: skew(-10deg);
  }
  &.active {
    background: linear-gradient(0deg, #0050b2, #0050b2),
      linear-gradient(0deg, #ffffff, #ffffff);
  }
`;

// content
const LeaderboardContent = styled.div`
  width: 80%;
`;

export default AchievementsPage;
