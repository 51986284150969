import styled from "styled-components";
import { useState } from "react";
import { size } from "../utils/helpers";

export const FAQBoxHomeNew = ({ question, answer }) => {
    const [isOpened, toggleState] = useState(false);
    const toggled = () => {
        if (isOpened) {
            toggleState(false);
        } else {
            toggleState(true)
        }
    }
    return (
        <FAQContainer className={isOpened ? "opened" : "closed"}>
            <QuestionBox>
                <Number  className="faqHomePageIcon" onClick={toggled}>
                    <InnerCircle>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <circle cx="20" cy="20" r="14" fill="white" />
                            <circle cx="20" cy="20" r="17" stroke="white" strokeOpacity="0.5" strokeWidth="6" />
                            <path d="M12.7197 19.1599L17.3864 23.9199L26.7197 14.3999" stroke="#0050B2" strokeWidth="3" strokeLinecap="round" />
                        </svg>
                    </InnerCircle>
                </Number>
                <Question> {question}</Question>
            </QuestionBox>
            
            <AnswerBox>
                <Answer>{answer}</Answer>
            </AnswerBox>

        </FAQContainer>
        

    );
}

const FAQContainer = styled.div`
    position: relative;
    margin: 1rem 2rem;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.17);
    backdrop-filter: blur(5px);
    border-radius: 200px;
    z-index: 5;

   
    &.closed {
        max-height: 50px;
        overflow: hidden;
        z-index: 5;
        @media only screen and (max-width: ${size.minWidthTablet}) {
            max-height: 39px;
        }
        
    }
    &.opened {
        border-radius: 10%;
        transition: max-height 0.2s ease-out;
        z-index: 5;
        > div {
            height: initial;
            > h4 {
                margin:1rem 0;
            }
        }
        @media only screen and (max-width: 991px) {
            border-radius: 20px;
        }
    }
    @media only screen and (min-width: 1023px) {
        margin: 1rem 0;
    }

    @media only screen and (max-width: ${size.minWidthTablet}) {
        border-radius: 67.2214px;
        margin: 1rem 0;
       
    }
`;

const QuestionBox = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    gap:25px;
    // justify-content: space-around;

    @media only screen and (max-width:480px){
        gap:15px; 
        align-items: start;
    }
`;

const Question = styled.h4`
color:  #FFF;
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin:0px;

@media only screen and (max-width:1140px){
    font-size:18px;
}

    @media only screen and (max-width: ${size.minWidthTablet}) {
        width: 85%;
        font-family: Space Grotesk;
        font-size: 16px;
        font-weight: 500;
        margin-left: 10px;
        margin-top:-12px;
    }
   
`;

const AnswerBox = styled.div`
    transition: .8s;
`;

const Answer = styled.p`
color:  #FFF;
font-family: Roboto;

    font-size: 18px;
    padding-top: 10px;
    @media only screen and (max-width: ${size.minWidthTablet}) {
        font-size: 16px;
        padding-top: 10px;
    }
`;
const Number = styled.div`
    border-radius: 50%;
    ${'' /* background: rgba(255, 255, 255, 0.38); */}
    backdrop-filter: blur(1.5px);
    position: relative;
    width: 28px;
    height: 28px;
    
    &:hover {
        cursor: pointer;
    }
    @media only screen and (max-width: ${size.minWidthTablet}) {
        width: 30px;
        height: 30px;
        margin-top:5px;
    }
`;

const InnerCircle = styled.div`
    background: rgba(255, 255, 255, 1);
    position: absolute;
    top:0px;
    right:0px;
    left:5px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: var(--main-bg);
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    @media only screen and (max-width: ${size.minWidthTablet}) {
        width: 24px;
        height: 24px;
        top:3px;
        right:3px;
    }
    >svg{
        flex-shrink:0;
    }
`;