import { useState, lazy, Suspense, useMemo } from "react";
import styled from "styled-components";
import { getContent } from "../../hooks/useGetContent";
import backLine from "../../assets/backLine.png";
import coming from "../../assets/hero-image-last.gif";
import WantTimeComingsoon from "../../features/WantTimeComingsoon";


const ComingsoonBrand = lazy(() => import('../../layout/sales/ComingsoonBrand'));
const SectionFourCommingsoon = lazy(() => import('../../layout/sales/MainPageSectionFourCommingsoon'));


const CommingSoon2 = ({ image }) => {
    const [content, setContent] = useState({});
    const [faq, setFAQ] = useState({});

    useMemo(() => {
        getContent().then(response => {
            if (response['homepage']) {
                setContent(response['homepage'])
            }
            if (response['homeFAQ']) {
                setFAQ(response['homeFAQ'])
            }
        })
    }, []);
    if (Object.keys(content).length === 0 || Object.keys(faq).length === 0) {
        return <></>
    }

    return (
        <Container>
            <SectionFourCommingsoon />
            <Suspense>
                <ImageLine src={backLine} />
            </Suspense>
            <Suspense>
                <SaveTimecontent as='h2'>
                    Want to have more time? <br></br>
                    And craft valuable comments every time?
                </SaveTimecontent>
                <WantTimeComingsoon />
            </Suspense>
            <Suspense>
                <VideoContainer2>
                    <Image src={coming} alt="img" />
                </VideoContainer2>
            </Suspense>
            <Suspense>
                <ComingsoonBrand /> 
            </Suspense>
        </Container>
    )
}
const VideoContainer2 = styled.div`
    position: relative;
    text-align:center;
    width: 100%;
    padding:0 15px;
    box-sizing: border-box;
    margin:40px 0 0px;
    @media only screen and (max-width:767px){
        margin:40px 0 20px;
    }
    video {
        position:absolute;
        border-radius: 10px;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index: 2;
    }
    iframe.show {
        z-index: 10;
    }
  
`;

const Image = styled.img`
    width: 100%;
    border-radius: 10px;
    max-width: 674px;
    margin-left:auto;
    margin-right: auto;
    &.hide {
        z-index: -1;
    }
   
`;
const Container = styled.main`
`;
const ImageLine = styled.img`
    display:block;
    margin:auto;
    width:100%;
    margin-top:-1px;
`;
const SaveTimecontent = styled.h2`
   color: #4B4B4B;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    max-width: 1000px;
    margin: auto;
    margin-top: 40px;
    @media only screen and (max-width:767px){
            font-size: 28px;
            margin-bottom:0px !important;
            line-height: normal;
       
    }
`;
export default CommingSoon2;