import styled from "styled-components";
import Link from "../components/link";

const NotFoundPage = () => {
    return(
        <NotFound>
            <Title>Not Found</Title>
            <Content>Sorry we couldn't find your page!</Content>
            <Link href="/">Go to start page</Link>
        </NotFound>
    );
}

const NotFound = styled.div`
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    animation: fade-up-quick 2s;
    min-height: 85vh;
`;

const Title = styled.h1`
    text-align: center;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 41px;
    line-height: 155.52%;
    margin-bottom: 0;
`;

const Content = styled.p`
    font-size: 1rem;
    line-height: 26px;
    text-align: center;
    color: var(--font-grey);
`;

export default NotFoundPage;