
import styled from "styled-components";
import { size } from "../utils/helpers";
// import ComingsoonButton from "../components/ComingsoonButton";
import Input from "../components/input";
import { useState } from "react";
import { Loader } from "../components/loader";
import { config, urlPaths } from "../utils/config";
import { fetchAPI } from "../utils/fetch";
import { Buffer } from "buffer";


const SaveTimeComingsoon = ({ buttonText }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isError, setErrorStatus] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    console.log("buttonText >>", buttonText)
    const handleClick = async (event) => {
        console.log("asdsa")
        setLoading(true);
        event.preventDefault();
      
        // if (!captchaValue) {
        //     setLoading(false);
        //     setErrorStatus(true);
        //     setErrorMessage('Please verify the reCAPTCHA!');
        //     return;
        // }
        try {
            const response = await fetchAPI.post(`${config.urlPlatform}/api/v2/auth/register`, {}, {
                email: email,
                password: Buffer.from(password, 'ascii').toString('base64'),
                plan: "free"
            });
            if (response && response.data.success === false) {
                setLoading(false);
                setErrorStatus(true);
                setErrorMessage(response.data.message);
            }
            window.location.replace(`${urlPaths.successRegister}?email=${email}&password=${Buffer.from(password, 'ascii').toString('base64')}&planType=free`);
        } catch (error) {
            console.log(error)
            setLoading(false);
            setErrorStatus(true);
            setErrorMessage(error?.response?.data?.message);
        }

    }
    
    return (
        <FormContainer onSubmit={handleClick}>
            {isError && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <h3>Comment your email and password below:</h3>
            <Input placeholder='Marco@gmail.com' label='Email address' value={email}
                changeEvent={(event) => setEmail(event.target.value)} hasIcon={{ active: true, icon: "@" }} />
            <Input placeholder='********' label='Password' value={password} inputType="password"
                changeEvent={(event) => setPassword(event.target.value)} hasIcon={{ active: true, icon: "lock" }} />
            {/* <RedButtonclick>
                <ComingsoonButton href="#" required text={buttonText} rounded={false} />
            </RedButtonclick> */}
            <SubmitButton type="submit"  disabled={isLoading} className={isLoading ? "loading" : ""} >
                {isLoading ? 
                    <Loader /> 
                    : 
                    buttonText
                }
            </SubmitButton>
        </FormContainer>
    )
}

const FormContainer = styled.form`
    width: 100%;
    max-width:570px;
    margin:auto;
    padding: 1rem 1rem 0;
    box-sizing: content-box;
    @media only screen and (max-width:767px){
        padding: 0rem 1rem 0;
        
    }
    > h3{
        color: #4B4B4B;
        font-family: Space Grotesk;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 145.6%;
        margin:0px;
        margin-bottom: 20px;
        text-align:center;
        @media only screen and (max-width:767px){
            font-size: 22px;
            line-height: normal;
            margin-bottom: 20px;
        
    }
    }
    > input{
        border-radius: 6px;
        border: 1px solid #4b4b4b9c;
        background: rgba(217,217,217,0.10);
        width: 100%;
        height: 54px;
        padding: 0 15px;
        margin-bottom: 20px;
        box-sizing: border-box;
   
    &::placeholder {
        color: var(--grey, #4B4B4B);
       font-family: Space Grotesk;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 145.6%; /* 26.208px */
        text-transform: capitalize;
    }
}

    @media only screen and (max-width: ${size.minWidthTablet}) {
        width: revert;
        margin-top: 15px;
    }
    
`
const RedButtonclick =styled.div`
    margin-top: 14px;
    display: flex;
    justify-content: center;

   
`
const ErrorMessage = styled.div`
    color:red;
    font-weight: 600;
    margin-bottom: 2rem;
    animation: fade-up-quick .5s;
`;
const SubmitButton = styled.button`
   width: 100%;
    line-height: 1.5;
    color: var(--white);
    background: var(--red);
    border:0px;
    border-radius: 10px;
    // outline: 6px solid rgba(255, 36, 0, 0.3);
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    color: #FFF;
    font-family: Space Grotesk;
    font-size: 20px;
    font-weight: 500;
    line-height: 155.523%; /* 40.436px */
    text-transform: uppercase;
    position:relative;
    margin-top:10px;
    @media only screen and (max-width:767px){
        font-size: 18px;
    }

    &::before {
      content: "";
      position: absolute;
      top: -6px; // border: 2px + offset: 1px
      right: -6px; // border: 2px + offset: 1px
      bottom: -6px; // border: 2px + offset: 1px
      left: -6px; // border: 2px + offset: 1px
      border: 6px solid rgba(255, 36, 0, 0.3);
      border-radius: 15px;
    }
    &:hover {
        color: #FF2400;
        background-color: var(--white);
        transition: 0.4s;
        cursor: pointer;
    }
    &.loading {
        background-color: #fedd00;
        border: 1px solid #fedd00;
        transition: .4s;
    }
   
    
`;
export default SaveTimeComingsoon;