import styled from "styled-components";
import { useState } from "react";
import { size } from "../utils/helpers";

export const FAQBox = ({question, answer}) => {
    const [isOpened, toggleState] = useState(false);
    const toggled = () => {
        if (isOpened) {
            toggleState(false);
        } else {
            toggleState(true)
        }
    }
    const hyperlink = (answer) => {
        return <span>
            {answer}
            <a href="https://chrome.google.com/webstore/detail/social-post-mate/ffebfjkgjgbpmnoogjjdgfkmiobngdnf" target="_blank" rel="noreferrer"> &gt;&gt; CLICK HERE &lt;&lt;</a>
            </span>
    }
    return (
        <FAQContainer className={isOpened ? "opened" : "closed"}>
            <QuestionBox>
                <Question>{ question }</Question>
                <Number onClick={toggled}>
                    <InnerCircle>
                        +
                    </InnerCircle>
                </Number>
            </QuestionBox>
            <AnswerBox>
                <Answer>{ answer && answer.includes("You can learn more about it by going here") ? hyperlink(answer) : answer }</Answer>
            </AnswerBox>
        </FAQContainer>
    );
}

const FAQContainer = styled.div`
    position: relative;
    margin: 1rem 2rem;
    padding: 1rem;
    background: rgba(255, 255, 255, 1);
    backdrop-filter: blur(5px);
    border-radius: 100px;
    z-index: 5;
    &.closed {
        max-height: 50px;
        overflow: hidden;
        z-index: 5;
        @media only screen and (max-width: ${size.minWidthTablet}) {
            max-height: 30px;
        }
        
    }
    &.opened {
        border-radius: 10%;
        transition: max-height 0.2s ease-out;
        z-index: 5;
        > div {
            height: initial;
            > h4 {
                margin:1rem 0;
            }
        }
        @media only screen and (max-width: 991px) {
            border-radius: 20px;
        }
    }
    @media only screen and (min-width: ${size.maxWidthTablet}) {
        margin: 1rem 0;
       
    }

    @media only screen and (max-width: ${size.minWidthTablet}) {
        border-radius: 67.2214px;
        margin: 1rem 0;
       
    }
`;

const QuestionBox = styled.div`
    display: flex;
    height: 100%;
    align-items:center;
    color: #58595B;
    justify-content: space-around;
@media only screen and (max-width:767px){
    align-items:start;
}
`;

const Question = styled.h4`
    font-size: 18px;
    margin-left: 1rem;
    width:70%;
    margin-block-start: 0;
    margin-block-end: 0;
    color: #5A5B5D;
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: 700;
    line-height: 155.523%; /* 27.994px */

    @media only screen and (max-width: ${size.minWidthTablet}) {
        width: 85%;
        color: #5A5B5D;
        font-family: Space Grotesk;
        font-size: 13.531px;
        font-weight: 500;
        margin-left: 10px;
    }
`;

const AnswerBox = styled.div`
    transition: .8s;
    color: #58595B;
`;

const Answer = styled.p`
    font-size: 18px;
    padding-top: 10px;
    @media only screen and (max-width: ${size.minWidthTablet}) {
        font-size: 11.53px;
        padding-top: 10px;
    }
`;

const Number = styled.div`
    border-radius: 50%;
    background: rgba(0, 80, 178, 0.28);
    backdrop-filter: blur(1.5px);
    position: relative;
    width: 70px;
    height: 70px;

    &:hover {
        cursor: pointer;
    }
    @media only screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
    }
    @media only screen and (max-width: 767px) {
        width: 30px;
        height: 30px;
    }
`;

const InnerCircle = styled.div`
    position: absolute;
    top:5px;
    right:5px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--main-bg);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 51px;
    @media only screen and (max-width: 991px) {
        width: 40px;
        height: 40px;
    }
    @media only screen and (max-width: 767px) {
        width: 24px;
        height: 24px;
        top:3px;
        right:3px;
    }
`;