import styled from "styled-components"

export const Loader = styled.span`
    display: block;
    margin-left:auto;
    margin-right:auto;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    &::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 5px solid #FFF;
        animation: prixClipFix 2s linear infinite ;
    }
`;

export const BloackLoader = styled.span`
    display: block;
    margin-left:auto;
    margin-right:auto;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    &::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 5px solid #000;
        animation: prixClipFix 2s linear infinite ;
    }
`;
