import React, { useEffect } from "react";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import styled from "styled-components";
import { size } from "../utils/helpers";

const GoogleAuth = ({ text, onSuccess, onError, theme = "filled_blue" }) => {
    const clientId = "955773312650-4n5sr5abe3jsmdf9jm7ihhcebc89vmlf.apps.googleusercontent.com";

    return (
        <GoogleContainer>
            {theme === "filled_blue" &&
                <BlockDivider>
                    <Divider /> Or <Divider />
                </BlockDivider>
            }
            <div>
                <GoogleOAuthProvider
                    clientId={clientId}
                >
                    <GoogleLogin
                        theme={theme}
                        text={text}
                        onSuccess={onSuccess}
                        onError={onError}
                        width={400}
                        logo_alignment="center"
                    />
                </GoogleOAuthProvider>
            </div>
        </GoogleContainer>
    )
}

const GoogleContainer = styled.div`
    & > div:last-child{
        display: flex;
        justify-content: center;
    }
    width: 100%;
`;

const BlockDivider = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    @media only screen and (min-width: ${size.minWidthTablet}) {
        margin-top: 0;
        margin-bottom: 8px;
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 2px;
    background: #ddd;
`;

export default GoogleAuth;