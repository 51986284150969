import { NavLink } from "react-router-dom";
import styled from "styled-components"
import { size } from "../utils/helpers";
import { Link as ScrollLink } from "react-scroll";

const RedLinkButton = ({ href, text, rounded = true, isLink = false, plan }) => {

  let planData;

  const handleButtonClick = (planType) => {
    if (planType === 'starter') {
      planData = { type: 'starter' };
    } else if (planType === 'professional') {
      planData = { type: 'professional' };
    } else if (planType === 'proMonth') {
      planData = { type: 'proMonth' };
    } else {
      console.error('Invalid planType');
      return;
    }
    localStorage.setItem('planData', JSON.stringify(planData));
  };

  return (!isLink ?
    <Button onClick={() => handleButtonClick(plan)}>
      <NavLink to={href} className={`${rounded ? "rounded" : ""}`} planData={planData}>
        {text}
      </NavLink>
    </Button> :
    <Button>
      <ScrollLink to={href} smooth={true} className={`${rounded ? "rounded" : ""}`}>
        {text}
      </ScrollLink>
    </Button>
  );
}

const Button = styled.div`
  width: 100%;
  margin-top:12px;
  max-width: 330px;
  display: flex;
  transition: .4s;
  z-index: 1;
  position: relative;
  > a {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--white);
    background: var(--red);
    border-radius: 50px;
    // outline: 6px solid rgba(255, 36, 0, 0.3);
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    &::before {
      content: "";
      position: absolute;
      top: -6px; // border: 2px + offset: 1px
      right: -6px; // border: 2px + offset: 1px
      bottom: -6px; // border: 2px + offset: 1px
      left: -6px; // border: 2px + offset: 1px
      border: 6px solid rgba(255, 36, 0, 0.3);
      border-radius: 50px;
    }
    &.rounded {
      border-radius: 50px;
      &::before {
        border-radius: 50px;
      }
    }
    &:hover {
      color: var(--red);
      background: var(--white);
    }
  }
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    > a {
      font-size: 18px;
      // outline: 9px solid rgba(255, 36, 0, 0.3);
      &::before {
        content: "";
        position: absolute;
        top: -9px; // border: 2px + offset: 1px
        right: -9px; // border: 2px + offset: 1px
        bottom: -9px; // border: 2px + offset: 1px
        left: -9px; // border: 2px + offset: 1px
        border: 9px solid rgba(255, 36, 0, 0.3);
        border-radius: 50px;
      }
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
`;

export default RedLinkButton;
