import styled from "styled-components";

const Rewards = () => {
    return (
        <CenterText>
            <h1>Coming Soon</h1>
        </CenterText>
    )
}

const CenterText = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 120px;
    margin-bottom: 120px;
    color: white;
`
export default Rewards;