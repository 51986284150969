import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { config } from "../utils/config";
import bannr from "../assets/discount.png";
import marketPlace1 from "../assets/marketText.png";
import contentBack from "../assets/search.png";
import { Marketcard } from "../components/Marketcard";
import axios from "axios";

const Marketplace = () => {

  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [userData, setUserData] = useState(null);


  let loggedInUserData = localStorage.getItem("userData");
  loggedInUserData = JSON.parse(loggedInUserData);

  const fetchCoupons = async () => {
    try {
      const response = await axios.get(`${config.urlPlatform}/api/v2/content/marketplace-coupons?title=${searchTerm}`);
      setCoupons(response.data);
    } catch (error) {
      setErrorMessage("There was an issue fetching offers.");
      console.error('Error fetching coupons:', error);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${config.urlPlatform}/api/v2/auth/user?email=${loggedInUserData.email}`);
      setUserData(response.data);
    } catch (error) {
      setErrorMessage("There was an issue.");
      console.error('Error fetching user:', error);
    }
  };

  useEffect(() => {
    fetchCoupons();
    fetchUser();
    setLoading(false);
  }, []);

  const handleSearchCoupon = async () => {
    setLoading(true);
    await fetchCoupons(searchTerm);
    setLoading(false);
  };

  return (
    <>
      {errorMessage ? <Error>{errorMessage}</Error> : null}
      {successMessage ? <Success>{successMessage}</Success> : null}
      <SectionMarket>
        <ContainerMarket>
          <WrapperDiv>
            <Leftpannal>
              <MarketHeading>
                <HeadingOne>
                  Marketplace
                </HeadingOne>
                <FigureImageText src={marketPlace1} />
              </MarketHeading>
              <Paratext>
                Grab your discounts as a valued Commenter AI paid user and save more money in your business!
              </Paratext>
              <SearchBar>
                <input
                  placeholder="Search Discount"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button onClick={handleSearchCoupon}>
                  Go
                </Button>
              </SearchBar>
            </Leftpannal>
            <Rightpannal>
              <FigureImage src={bannr} />
            </Rightpannal>
          </WrapperDiv>
        </ContainerMarket>

      </SectionMarket>
      <SectionBox>
        {coupons.map((coupon, index) => (
          <Marketcard
            key={index}
            title={coupon.title}
            description={coupon.description}
            cardText={coupon.offerDescription}
            imageSrc={coupon.image}
            couponcode={coupon.couponcode}
            couponId={coupon.id}
            userData={userData}
          />
        ))}
      </SectionBox>
    </>

  );
};

const Error = styled.div`
  color: red;
`;

const Success = styled.div`
  color: green;
`;

const SectionMarket = styled.section`
  position: relative;
  background:#0050B2;
  box-sizing:border-box;
  padding:70px 15px 50px;

  @media only screen and (max-width:1024px){
    padding:40px 15px 40px;
  }
`;
const SectionBox = styled.div`
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    padding:90px 0 60px;
    @media only screen and (max-width:1400px){
      gap: 15px;
    }
    @media only screen and (max-width:1024px){
      padding:40px 15px 40px;
    }
  
 `;
const ContainerMarket = styled.div`
 max-width:1300px;
 margin:auto;
`;
const WrapperDiv = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width:1024px){
      flex-direction:column-reverse;
    }
   `;
const Leftpannal = styled.div`
   @media only screen and (max-width:1024px){
      width:100%;
    }
   `;
const Rightpannal = styled.div`
     @media only screen and (max-width:1024px){
      width:100%;
      text-align: center;
    }
   `;
const MarketHeading = styled.div`
    position: relative;
    height: 120px;
    width: 100%;
    max-width: 400px;
    padding: 25px 20px;
    box-sizing:border-box;
    @media only screen and (max-width: 480px){
      height: auto;
    }

   `;

const HeadingOne = styled.div`
    font-family: Poppins;
    font-size: 50px;
    font-weight: 800;
    line-height: 50px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #ffff;
    @media only screen and (max-width:480px){
      font-size: 24px;
       line-height: normal;
    }
  `
const Paratext = styled.div`
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.02em;
      text-align: left;
      color:#fff;
      max-width: 600px;
      margin-top:20px;
      @media only screen and (max-width:767px){
        font-size: 16px;
        line-height: 26px;
        margin-top:0px;
      }
`

const FigureImage = styled.img`
    width:100%;
    @media only screen and (max-width:1024px){
     max-width:400px;
     text-align: center;
    
    }
    `;

const FigureImageText = styled.img`
   position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
   
    @media only screen and (max-width:480px){
      height:100%;
    object-fit: contain;
    }
    `;
const Input = styled.div`
       
    `;
const Button = styled.div`
        width: 121px;
    height: 51px;
    top: 421px;
    left: 670px;
    gap: 0px;
    border-radius: 35px;
    border: 4px;
    background: rgb(255, 255, 255);
    flex-shrink: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #0050B2;
    cursor:pointer;
   
    @media only screen and (max-width: 767px){
        height:100%;
        width: 100px;
        font-size: 16px;
    }
    @media only screen and (max-width:480px){
      width: 65px;
      font-size: 16px;

    }
    `;
const SearchBar = styled.div`
    max-width: 601px;
    width:100%;
    height: 71px;
    border-radius: 35px;
    background: rgb(42, 110, 191);
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    box-sizing: border-box;
    margin-top:30px;
    @media only screen and (max-width: 767px){
        height: 60px;
        padding: 10px;
    }
        >input{
          border: 0px;
          width: 100%;
          border-radius: 50px;
          padding: 0 30px 0 50px;
          background-image: url(${contentBack});
          border: 0px;
          background-color: rgba(255, 0, 0, 0);
          background-repeat: no-repeat;
          background-position-y: center;
          background-position-x: 15px;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #fff;
          @media only screen and (max-width:480px){
            padding: 0px 7px 0px 35px;
            font-size: 16px;
            background-position: 7px 50%;
          }
        }
        >input::placeholder {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #fff;
          @media only screen and (max-width:767px){
            font-size: 16px;
          }
        }
        >input:focus-visible {
        outline: 0 !important;
        border:0px;
        }

    `;





export default Marketplace;
