import { useState, useEffect } from "react";
import styled from "styled-components";
import salesClouds from "../../assets/sales-clouds.webp";
import salesMascott from "../../assets/sales-mascott.webp";
import { urlPaths } from "../../utils/config";
import { size } from "../../utils/helpers";
import plane from "../../assets/plane.webp";
import RedLinkButton from "../../components/RedLinkButton";

const HomepageCloudSection = ({
    title,
    children, 
    button,
    links = [],
    staticImages = [],
    influencer,
}) => {
    const [creditCards, setCreditCards] = useState([]);
    useEffect(() => {
        if (staticImages && staticImages.length > 0) {
            const cardAssets = staticImages.filter(item => ['VISA', 'mastercard', 'American express'].includes(item.fields.title));
            setCreditCards(cardAssets.map(item => `https:${item.fields.file.url}`));
        }
    }, [staticImages]);

    const checkMark = () => {
        return (
            <svg width="25" height="25" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="19" cy="19" r="17.5" fill="#0050B2" stroke="#ECECEC" strokeWidth="3"/>
                <path d="M11 20.467L16.2403 25.667L28.3333 13.667" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            </svg>
        )
    }
    return (
        <>
        <SectionWrapper>
            <Plane src={plane} />
            <SectionTitle>
                { title }
            </SectionTitle>
            <ContentDiv>
                <MascottBG src={staticImages?.[1]?.fields?.file?.url ? 'https:' +  staticImages?.[1]?.fields?.file?.url : salesMascott}  alt="Mascott" />
                { children }
            </ContentDiv>
            <ButtonsWrapper style={{
                marginTop:"2rem"
            }}>
                <RedLinkButton href={urlPaths.register} text={button} />
                <Links>
                    {links?.map((link, key) => <TheLink key={key}>{checkMark()} {link} </TheLink>)}
                </Links>

            <CreditCardList>
                {creditCards.length > 0 && creditCards.map((item, index) => (
                    <CreditCardImage key={`credit-card-${index}`} src={item} alt="" />
                ))}
            </CreditCardList>
            </ButtonsWrapper>
            <BottomBG src={ staticImages?.[0]?.fields?.file?.url ? 'https:' +  staticImages?.[0]?.fields?.file?.url : salesClouds} alt="Sales clouds" />
        </SectionWrapper>
        <InfluencerContainer>
            <InfluencerImage src={"https:" + influencer?.content[0].data.target.fields.file.url ?? undefined} />
        </InfluencerContainer>
        </>
    )
}
const SectionWrapper = styled.section`
    position: relative;
    min-height: 100vh;
`;

const ButtonsWrapper =  styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
`

const ContentDiv = styled.div`
    position: relative;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        width: calc(100% - 2rem);
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        max-width: 900px;
    }
`

const SectionTitle = styled.h2`
    text-align: center;
    margin-top: 7.5rem;
    font-size: 53px;
    font-weight: 700;
    line-height: 87px;
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        font-size: 40px;
    }
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        margin-top: 2.5rem;
        margin-bottom: 3rem;
        font-size: 27px;
        line-height: 33px;
    }
`;

const MascottBG = styled.img`
    position: absolute;
    top: 25%;
    left: 0%;
    z-index: -1;
    max-width: 350px;
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        left: -10%;
        max-width: 300px;
    }

    @media only screen and (max-width: ${size.maxWidthTablet}) {
        position: relative;
        max-width: 120px;
        width: 100%;
        left: calc(50% - 53.5px);
        margin-bottom: 20px;
    }
`;

const BottomBG = styled.img`
    width: 100%;
    position: absolute;
    bottom: 0;
    left:0;
    z-index: -1;
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        position: relative;
        margin-top: -65px;
    }
`

const Plane = styled.img`
    position:absolute;
    top: -7.5rem;
    left: calc(50% - 500px);
    z-index: -1;
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        display:none;
    }
`

const Links = styled.div`
    display: flex;
    grid-gap:15px;
    margin-top: 2rem;
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        margin-top: 1rem;
    }
`;

const TheLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    > svg {
        width: 20px;
        height: auto;
        margin-right: .25rem;
    }
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        font-size: 12px;
        line-height: 19px;
    }
`;

const CreditCardList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 30px;
`;

const CreditCardImage = styled.img`
    max-height: 30px;
`;

const InfluencerContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
    padding: 10px;
`;

const InfluencerImage = styled.img`
  margin-top: 2rem;
  max-width: 870px;
  width:100%;
  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 800px;
  }
  @media only screen and (max-width: ${size.minWidthTablet}) {
    margin-top: 0;
  }
`;

export default HomepageCloudSection;