import styled from "styled-components";
import { useState} from "react";
import { Loader } from "../../components/loader";
import { size, convertStringToParagraphs } from "../../utils/helpers";
import { urlPaths } from "../../utils/config";
import { FAQBox } from "../../components/faqBoxWhite";
import backgroundImage from "../../assets/homepage-faq-bg.webp";
import RedLinkButton from "../../components/RedLinkButton";

const PricingFAQ = ({
    content = [],
    faq = [],
    staticImages = []
    
}) => {
    const [isLoading, setLoading] = useState(false);
    const title = content?.content?.[0]?.content?.[0]?.value ?? "";

    const onclickhandler = () => {
        window.scrollTo(0, 100)
    }
    return (
        <Wrapper>
            <ContentWrapper>
                <SectionTitle>{ title }</SectionTitle>
                <FAQSectionWrapper className="faq_pricing_sec">
                <FAQBox key={0} question={faq?.faq1Question} answer={convertStringToParagraphs(faq?.faq1Answer)} />
                    <FAQBox key={1} question={faq?.faq2Question} answer={convertStringToParagraphs(faq?.faq2Answer)} />
                    <FAQBox key={2} question={faq?.faq3Question} answer={convertStringToParagraphs(faq?.faq3Answer)} />
                    <FAQBox key={3} question={faq?.faq4Question} answer={convertStringToParagraphs(faq?.faq4Answer)} />
                    <FAQBox key={4} question={faq?.faq5Question} answer={convertStringToParagraphs(faq?.faq5Answer)} />
                    <FAQBox key={5} question={faq?.faq6Question} answer={convertStringToParagraphs(faq?.faq6Answer)} />
                    <FAQBox key={6} question={faq?.faq7Question} answer={convertStringToParagraphs(faq?.faq7Answer)} />
                    <FAQBox key={7} question={faq?.faq8Question} answer={convertStringToParagraphs(faq?.faq8Answer)} />
                    {
                        faq?.faq9Question ? <FAQBox key={8} question={faq?.faq9Question} answer={convertStringToParagraphs(faq?.faq9Answer)} />: ''
                    }
                    {
                        faq?.faq10Question ? <FAQBox key={9} question={faq?.faq10Question} answer={convertStringToParagraphs(faq?.faq10Answer)} />: ''
                    }
                    {
                        faq?.faq11Question ? <FAQBox question={faq?.faq11Question} answer={convertStringToParagraphs(faq?.faq11Answer)} />: ''
                    }
                   
                
                </FAQSectionWrapper>
               <Fapbutton> 
               <SubmitButton disabled={isLoading} onClick={onclickhandler} className={isLoading ? "loading" : ""}>{isLoading ? <Loader /> : "Improve your commenting Today"}</SubmitButton>
               </Fapbutton>
            </ContentWrapper>
            {/* <BackgroundImage src={staticImages?.[1]?.fields?.file?.url ? 'https:' + staticImages?.[1]?.fields?.file?.url : backgroundImage} alt="Background image" /> */}
        </Wrapper>

    )
}

const ButtonsWrapper =  styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
    @media only screen and (max-width: ${size.minWidthTablet}) {
        margin-top: 0;
        margin-bottom: 22px;
    }
`

const Wrapper = styled.section`
    padding: 2rem 15px 5rem;
    background-color: #0050B2;
    position: relative;
    color: var(--white);
    @media only screen and (max-width: ${size.minWidthTablet}) {
        padding-bottom: 1rem;
    }
`;

const FAQSectionWrapper = styled.div`
    margin-top: 28px;
    @media only screen and (min-width: ${size.maxWidthTablet}) {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
    }
`;

const ContentWrapper = styled.div`
    // margin-right: 1rem;
    // margin-left: 1rem;
    padding-bottom: 18rem;
    @media only screen and (min-width: ${size.minWidthTablet}) {
        max-width: 1050px;
        margin-left:auto;
        margin-right: auto;
        width: 100%;
    }

    @media only screen and (min-width: ${size.maxWidthTablet}) {
        padding-bottom: 5rem;
    }

    @media only screen and (max-width: ${size.minWidthTablet}) {
        padding-bottom: 2rem;
    }
`;

const SectionTitle = styled.h2`
    text-align: center;
    font-family: Space Grotesk;
    font-size: 24px;
    font-weight: 700;
    line-height: 155.523%;
    @media only screen and (min-width: ${size.minWidthTablet}) {
        font-size: 40px;
        line-height: 62px;
        margin-top: 0;
    }

    @media only screen and (max-width: ${size.minWidthTablet}) {
    }
`;

const BackgroundImage = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
    max-width: 500px;
    @media only screen and (max-width: ${size.maxWidthTablet}) {
        max-width: 300px;
    }

    @media only screen and (max-width: ${size.minWidthTablet}) {
        max-width: 300px;
    }
`;
const Fapbutton = styled.button`
    margin-top: 24px;
    width: 100%;
    margin:auto;
    margin: auto;
    display: block;
    background: none;
    border: none;
    margin-top: 24px;
   
`
const SubmitButton = styled.button`
    display: "block";
    width:100%;
    max-width:556px;
    text-transform: uppercase;
    text-align: center;
    color: var(--white);
    border-radius: 8px;
    padding: 15px 0;
    background-color: #FF2400;
    border: 1px solid #FF2400;
    font-family: "Space Grotesk", sans-serif;
    font-weight: 500;
    font-size: 18px;
    transition: 0.4s;
    border-radius: 500px;
border: 6px solid rgba(255, 36, 0, 0.30);

background: var(--red, #FF2400);
    &:disabled {
        background-color: #848484;
        border: 1px solid #848484;
        &:hover {
            cursor: revert;
            background-color: #848484;
            border: 1px solid #848484;
            color: var(--white);
        }
    }
    &:hover {
        color: #FF2400;
        background-color: var(--white);
        transition: 0.4s;
        cursor: pointer;
    }
    &.loading {
        background-color: #fedd00;
        border: 1px solid #fedd00;
        transition: .4s;
    }
    @media only screen and (min-width: ${size.minWidthTablet}) {
        padding: 11px 0;
    }
`;

export default PricingFAQ;