import { MainContainer } from "../../components/mainContainer";
import { Title } from "../../components/Title";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { validateSession } from "../../hooks/useValidateSession";

const RequestAFeaturePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    validateSession()
      .then((result) => {
        if (result.success === false || result === false) {
          //navigate("/unauthorized");
        }
      })
      .catch((error) => {
        //navigate("/unauthorized");
      });
  }, [navigate]);

  return (
    <MainContainer>
      <Title>Coming soon</Title>
    </MainContainer>
  );
};

export default RequestAFeaturePage;
