import styled from "styled-components";
import { Outlet } from "react-router-dom";
import logo from "../assets/public-logo.png";
import { NavLink } from "react-router-dom";
import RobotAssistant from "../components/robotAssistant";
import { content, size } from "../utils/helpers";
import FooterLogo from "../assets/footer-logo.png";
import EmailLogo from "../assets/email.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAPI } from "../utils/fetch";
import { USER_SUBSCRIPTION, config, urlPaths } from "../utils/config";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import checkIcon from "../assets/modal-check-icon.png";
import { Loader } from "../components/loader";
import { useGetStoredData } from "../hooks/useGetStoreData";

const MainPages = ({ children }) => {
  const [showProfessionalBanner, setShowProfessionalBanner] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const userDetail = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const location = useLocation();
  const [isPlanModalOpen, setPlanModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoadingPrm, setLoadingPrm] = useState(false);
  const [isLoadingPro, setLoadingPro] = useState(false);
  const [stripeId, setStripeId] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const storedData = useGetStoredData();
  const [isLoadingThree, setLoadingThree] = useState(false);
  useEffect(() => {
    // Add an event listener to disable body scroll when the modal is open
    const handleBodyScroll = (e) => {
      if (isPlanModalOpen) {
        e.preventDefault();
      }
    };

    document.body.style.overflowY = isPlanModalOpen ? "hidden" : "auto";
    document.body.addEventListener("touchmove", handleBodyScroll, {
      passive: false,
    });

    return () => {
      // Clean up the event listener when the component unmounts
      document.body.style.overflowY = "auto";
      document.body.removeEventListener("touchmove", handleBodyScroll);
    };
  }, [isPlanModalOpen]);

  useEffect(() => {
    if (userDetail?.plan !== "proMonth" || userDetail.status === 'canceled') {
      if ((userDetail?.plan === "professional" && userDetail?.paidStatus === true)) {
        setShowProfessionalBanner(false)
      } else {
        setShowProfessionalBanner(true);
      }
    }
  }, [userDetail?.plan, userDetail?.paidStatus])

  useEffect(() => {
    fetchAPI
      .get(
        `${config.urlPlatform}/api/v2/auth/user?email=${storedData.userData?.email}`,
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if ("success" in result) {
          setErrorMessage(result.message);
        } else {
          //   if (result.data?.subscriptionData) {
          //     setTrialStart(
          //       new Date(
          //         Number(result.data?.subscriptionData?.[0]?.trial_start + "000")
          //       ).toDateString() +
          //         " " +
          //         new Date(
          //           Number(result.data.subscriptionData[0]?.trial_start + "000")
          //         ).toLocaleTimeString()
          //     );
          //     setTrialEnd(
          //       new Date(
          //         Number(result.data?.subscriptionData?.[0]?.trial_end + "000")
          //       ).toDateString() +
          //         " " +
          //         new Date(
          //           Number(result.data.subscriptionData[0]?.trial_end + "000")
          //         ).toLocaleTimeString()
          //     );
          //   }
          //   localStorage.setItem(
          //     "subscriptionId",
          //     result.data.subscriptionData?.[0]?.id
          //   );
          //   localStorage.setItem("commentsLeft", result.data.commentsLeft);
          //   localStorage.setItem("status", result.data.status);
          //   setSubscriptionData(result.data.subscriptionData);
          //   setCommentsLeft(result.data.commentsLeft);
          setEmail(result?.data?.email);
          setStripeId(result?.data?.stripeId);
          setStatus(result?.data?.status);
        }
      })
      .catch((error) => {
        setErrorMessage("We had issue with the server");
      });
  }, [storedData?.userData?.email]);

  const openPlanModal = () => setPlanModalOpen(true);
  const closePlanModal = () => setPlanModalOpen(false);
  const currentPathname = location.pathname.toLowerCase();
  const subscriptionStatus = localStorage.getItem("subscription");

  // console.log("subscriptionStatus ? ", subscriptionStatus)
  // const navigate = useNavigate();
  const storage = JSON.parse(localStorage.getItem("userData"));
  const handleClick = async () => {
    await fetchAPI.get(`${config.urlPlatform}/api/v2/auth/logout`, {
      withCredentials: true
    });
    navigate("/login");
    localStorage.clear();




  };


  const subscriptionHandler = () => {
    // setLoadingOne(true);
    fetchAPI.post(`${config.urlPlatform}/api/v2/payment/subscription`, {
      withCredentials: true
    }, {
      email: storage?.email,
      subscriptionId: storage.stripeId,
    }).then(response => {
      // setLoadingOne(false);
      if (response.status !== 200) {
        throw new Error("error");
      }
      if (response.data.success === false) {
        // setErrorMessage(response.data.message);
        console.log(response.data.message)
      } else {
        window.location.replace(response.data.url);
        // setSuccessMessage("Successfully upgraded subscription!")
        localStorage.setItem("subscription", "pro");
      }
    }).catch(error => {
      // setLoadingOne(false);
      // setErrorMessage("There was an issue subscribing. Please contact us!");
      console.log("There was an issue subscribing. Please contact us! >>>", error.message);
    })
  }

  const handleSubscribe = () => {
    setLoadingThree(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: email,
          subscriptionId: stripeId,
          plan: "starter",
        }
      )
      .then((response) => {
        setLoadingThree(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingThree(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };

  const handleSubscribePrm = () => {
    console.log({
      email: email,
      subscriptionId: stripeId,
      plan: "proMonth",
    })
    setLoadingPrm(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: email,
          subscriptionId: stripeId,
          plan: "proMonth",
        }
      )
      .then((response) => {
        setLoadingPrm(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingPrm(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };

  const handleSubscribePro = () => {
    setLoadingPro(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: email,
          subscriptionId: stripeId,
          plan: "professional",
        }
      )
      .then((response) => {
        setLoadingPro(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingPro(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };

  const allNavigationLinks = () => {
    return (

      <Navigation className={isOpen ? "show" : ""}>
        <NavLink to={"/platform"}>
          <NavigationLink className="navigation-link" onClick={() => { setOpen(!isOpen) }}>
            Welcome
          </NavigationLink>
        </NavLink>
        <NavLink to={"/get-comments"}>
          <NavigationLink className="navigation-link" onClick={() => { setOpen(!isOpen) }}>
            Commenting
          </NavigationLink>
        </NavLink>

        <NavLink to={"/custom"}>
          <NavigationLink className="navigation-link" onClick={() => { setOpen(!isOpen) }}>
            Custom
          </NavigationLink>
        </NavLink>

        <NavLink to={"/settings"}>
          <NavigationLink className="navigation-link" onClick={() => { setOpen(!isOpen) }}>
            Billing
          </NavigationLink>
        </NavLink>
        {/* <a href="/crm" target="_blank" rel="noreferrer" onClick={() => { setOpen(!isOpen) }}>
          <NavigationLink className="navigation-link">
            CRM
          </NavigationLink>
        </a> */}
        <NavLink to={"/achievements"}>
          {/* <NavigationLink className="navigation-link achievements">
                            Achievements
                            <Tooltip>Coming soon...</Tooltip>
                        </NavigationLink> */}
          <NavigationLink className="navigation-link" onClick={() => { setOpen(!isOpen) }}>
            Leaderboard
          </NavigationLink>
        </NavLink>
        <NavLink to={"/engagement"}>
          <NavigationLink className="navigation-link" onClick={() => { setOpen(!isOpen) }}>
            My List
          </NavigationLink>
        </NavLink>
        <NavLink to={"/my-comments"}>
          <NavigationLink className="navigation-link" onClick={() => { setOpen(!isOpen) }}>
            Saved Comments
          </NavigationLink>
        </NavLink>
        <NavLink to={"/marketplace"}>
          <NavigationLink className="navigation-link" onClick={() => { setOpen(!isOpen) }}>
            Specials
          </NavigationLink>
        </NavLink>
        <NavLink onClick={handleClick}>
          <NavigationLink className="navigation-link">
            Logout
          </NavigationLink>
        </NavLink>
        {!storage?.paidStatus &&
          <NavLink style={{ display: "flex" }}>
            <NavigationLinkbtn onClick={openPlanModal} className="navigation-link achievements">
              Subscribe now
            </NavigationLinkbtn>

          </NavLink>
        }
        {(storage?.plan === 'starter' || storage?.plan === 'professional') && storage?.paidStatus && <NavigationLinkbtn onClick={openPlanModal} className="navigation-link achievements">
          UPGRADE MY PLAN
        </NavigationLinkbtn>}
        {isPlanModalOpen && (
          <div className="modalOverlay" onClick={closePlanModal}>
            <div
              className="modalContent"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modalHeader">
                <span
                  className="closeButton"
                  onClick={closePlanModal}
                >
                  &times;
                </span>
              </div>
              <div className="planWrapperDiv">
                {(userDetail?.plan === "starter" && userDetail?.paidStatus !== true || userDetail.status === "canceled") && (
                  <PlanboxOne>
                    <FreeplanPart>
                      <h2>Starter plans</h2>
                    </FreeplanPart>
                    <ContentList>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>300 comments/month</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>1 emotion</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>TextTune™ - only short comments</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>GenuYou™ - turn your ideas into comments </Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Multi-language support</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Grammar check of your comment</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Standard support</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Commenter academy</Text>
                      </ContentItem>
                      <MonthPlan>
                        {" "}
                        <span></span>Just $12/month
                      </MonthPlan>
                      <BoxContent isButton>
                        <SubmitButton
                          className={
                            isLoadingThree ? "loading" : ""
                          }
                          onClick={handleSubscribe}
                        >
                          {isLoadingThree ? (
                            <Loader />
                          ) : (
                            "UPGRADE ME TO STARTER"
                          )}
                        </SubmitButton>
                      </BoxContent>
                      {/* <RedButtonclick>
                                  <RedLinkButton
                                    href={urlPaths.register}
                                    text="Upgrade Plan"
                                    rounded={false}
                                  />
                                </RedButtonclick> */}
                      {/* <NeeDed>
                                    <CheckIcon
                                      style={{ width: "13px", height: "13px" }}
                                      src={checkIcon}
                                    />
                                    No credit card required
                                  </NeeDed> */}
                    </ContentList>
                  </PlanboxOne>
                )}

                <PlanboxTwo>
                  <ProPlan>Influencer PLAN
                    <span>(customize your prompt)</span>
                  </ProPlan>
                  {/* <Tag>Most Popular</Tag> */}

                  <ContentList>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>3000 comments/month </Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>5 emotions (+Natural™ emotion)</Text>
                    </ContentItem>
                    {/* <ContentItem>
                <CheckIcon src={checkIcon} />
                <Text>10 commenting styles</Text>
              </ContentItem> */}
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Full TextTune™ - short & long comments</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>GenuYou™ - turn your ideas into comments</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Multi-language support</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Grammar check of your comment</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>VIP support</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Commenter academy</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>SmartReply+™ - reply to comments</Text>
                    </ContentItem>
                    {/* <ContentItem>
                <CheckIcon src={checkIcon} />
                <Text>Access to our exclusive community</Text>
              </ContentItem> */}
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Video course: "Strategic commenting"</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>
                        <strong>+Customize your prompt & voice</strong>
                      </Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+13 commenting styles</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+Saved comments - unlimited</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+Engagement list - unlimited</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+Special discounts for events, courses</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+Commenting on Facebook and Twitter</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+eBook - "All-in-one LI personal branding"</strong></Text>
                    </ContentItem>
                    <MonthPlan>
                      {" "}
                      <span>$81</span> $27/month
                    </MonthPlan>
                    <PerCup>
                      (less than a cup of coffee per day)
                    </PerCup>
                    <BoxContent isButton>
                      <SubmitButton
                        className={isLoadingPrm ? "loading" : ""}
                        onClick={handleSubscribePrm}
                      >
                        {isLoadingPrm ? (
                          <Loader />
                        ) : (
                          "UPGRADE ME TO PREMIUM"
                        )}
                      </SubmitButton>
                    </BoxContent>
                    {/* <RedButtonclick>
                                  <RedLinkButton
                                    href={urlPaths.proplan}
                                    text="UPGRADE PREMIUM PLAN"
                                    rounded={false}
                                  />
                                </RedButtonclick> */}
                    <CancleAnytime>
                      {/* <TextCancel>
                                    <CheckIcon
                                      style={{ width: "13px", height: "13px" }}
                                      src={checkIcon}
                                    />
                                    <Text>No credit card required</Text>
                                  </TextCancel> */}
                    </CancleAnytime>
                  </ContentList>
                </PlanboxTwo>

                {showProfessionalBanner && (
                  <PlanboxOne>
                    <ProPlanBlackTwo>
                      Creator PLAN
                    </ProPlanBlackTwo>
                    <ContentList>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>900 comments/month </Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>3 emotions</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text> Full TextTune™ - short and long comments</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>GenuYou™ - turn your ideas into comments</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Multi-language support.</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Grammar check of your comment</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Priority support</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Commenter academy</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+SmartReply+™- basic comment replies</strong></Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+5 commenting styles</strong></Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+Saved comments - up to 40.</strong></Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+Engagement list - up to 40.</strong></Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+Video course: “Strategic commenting”</strong></Text>
                      </ContentItem>
                      <MonthPlan>
                        {" "}
                        <span>$36</span> $17/month
                      </MonthPlan>

                      {/* <MonthPlan> <span>$324</span> $243/year</MonthPlan> */}
                      <NewRedbtn>
                        <BoxContent isButton>
                          <SubmitButton
                            className={
                              isLoadingPro ? "loading" : ""
                            }
                            onClick={handleSubscribePro}
                          >
                            {isLoadingPro ? (
                              <Loader />
                            ) : (
                              "UPGRADE ME TO PROFESSIONAL"
                            )}
                          </SubmitButton>
                        </BoxContent>
                        {/* <RedButtonclick>
                                      <RedLinkButton
                                        href="https://forms.gle/LkJZVf9eNju6s7C27"
                                        text="UPGRADE PROFESSIONAL PLAN"
                                        rounded={false}
                                      />
                                    </RedButtonclick> */}
                        {/* <CancleAnytime>
                                      <TextCancel>
                                        <CheckIcon
                                          style={{
                                            width: "13px",
                                            height: "13px",
                                          }}
                                          src={checkIcon}
                                        />
                                        <Text>Limited spots available</Text>
                                      </TextCancel>
                                    </CancleAnytime> */}
                      </NewRedbtn>
                    </ContentList>
                  </PlanboxOne>
                )}
              </div>
            </div>
          </div>
        )}
        <style jsx>{`
                      .modalOverlay {
                          position: fixed;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          background: rgba(0, 0, 0, 0.8);
                          display: flex;
                          align-items: start;
                          justify-content: center;
                          z-index: 99;
                          {/* padding-top: 40px; */}
                      }

                              .modalContent {
                                  background: #fff0;
                                      padding: 20px;
                                      border-radius: 8px;
                                      width: 100%;
                                      /* margin: 40px auto; */
                                      height: calc(100vh - 35px);
                                      overflow-y: auto;
                              }

                              .modalHeader {
                              display: flex;
                              justify-content: flex-end;
                              }

                              .closeButton {
                              cursor: pointer;
                              font-size: 30px;
                              color: #fff;
                              }

                              .openButton {
                              margin-top: 20px;
                              cursor: pointer;
                              }
                              .planWrapperDiv{
                                  display:flex;
                                  justify-content: center;
                                  gap: 10px;
                              }
                              ::-webkit-scrollbar {
                               width: 0px;
                                }

                              @media only screen and (max-width:1023px){
                                  .planWrapperDiv {
                                          flex-direction: column;
                                      }
                                      .planWrapperDiv >div {
                                          width: 100%;
                                          margin: auto;
                                          max-width: 100%;
                                          box-sizing:border-box;
                                      }
                              }
                              }

                              @media only screen and (max-width:767px){
                                  .modalContent {
                                      height: 600px;
                                    }
                              }
                          `}</style>
        {/* <RobotAssistant hasHeadline={true}>
                    <SmallHeadlineSpan className="small-headline-span">{content.PublicPages.Welcome.Robot.Title1}<br /></SmallHeadlineSpan>
                    {content.PublicPages.Welcome.Robot.Text}
                </RobotAssistant> */}
      </Navigation>
    )
  }

  return (
    <Container className="platform-pages">
      {location.pathname !== '/crm' &&

        <div className="mobile">
          <MainLogo src={logo} className="main-logo" onClick={() => { navigate(urlPaths.platform) }} />
          {allNavigationLinks()}
          <div id="menuToggle">
            <input type="checkbox" checked={isOpen} onChange={() => { setOpen(!isOpen) }} />
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>}
      {location.pathname !== '/crm' &&
        <Header className="header">
          <MainLogo src={logo} className="main-logo" />
          {allNavigationLinks()}
        </Header>
      }
      {children}
      <Outlet />
      {location.pathname !== '/crm' &&
        <Footer className="footer_head">
          <MainLogo className="footerLogo" src={FooterLogo} onClick={() => { navigate(urlPaths.platform) }} />
          <FooterNav className="footerLink" >
            <NavLink to={"mailto:support@commenter.ai"}><MailImg className="mail-img" src={EmailLogo} />support@commenter.ai</NavLink>
            <a href="https://yjdk70k2wcj.typeform.com/to/JTcL3k3D" target="_blank" rel="noreferrer">Request a feature</a>
            <a href="https://yjdk70k2wcj.typeform.com/to/TwEuA1lU" target="_blank" rel="noreferrer">Report a Bug</a>
            <NavLink to={"/contact-us"}>Contact Us</NavLink>
            <a href="/blog/" >Blog</a>
          </FooterNav>
        </Footer>
      }
    </Container>
  )
}

const Tooltip = styled.span`
    opacity:0;
    position: absolute;
    top:30px;
    left:20px;
    background-color: #fff;
    border-radius: 30px;
    padding:1rem 2rem;
    transition: .4s;
`;

const Container = styled.main`
    min-height: 100vh;
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    height: 100px;
    background-color: var(--white);
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.15);
    padding: 0 5% 15px;
    animation: fade-down .8s;
    @media only screen and (max-width:${size.headerWidth}) {
        height:50px;
    }
`;

const MainLogo = styled.img`
    margin: 1rem 0;
    &:hover {
        cursor: pointer;
    }
    @media only screen and (max-width:${size.headerWidth}) {
        width:102px;
        height:auto;
        margin-top:auto;
        margin-bottom:auto;
    }
`;

const Navigation = styled.nav`
    display: flex;
    margin: 1rem 0;
    align-items: center;
    > a {
        text-decoration: none;
        transition: .4s;
        font-family: 'Space Grotesk', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 25px;
        
        &:nth-last-child(2) {
            margin-right:3rem;
        }
    }
    > a.active {
        >span {
            color: var(--main-bg);
        }
    }
    @media only screen and (max-width:${size.headerWidth}) {
        display: none;
    }
`;

const NavigationLink = styled.span`
    margin-left: 1.5rem;
    color: var(--black);
    transition: .4s;
    &.achievements {
        position: relative;
        color: #000000!important;
        &:hover {
            > span {
                opacity: 1;
                transition: .4s;
                z-index:100;
            }
        }
    }
    &:hover {
        color: var(--main-bg);
        transition: .4s;
    }
`;

const NavigationLinkbtn = styled.span`
   width: 150px;
height: 37px;
flex-shrink: 0;
background:#ff2400;
display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 42px;
    color:  #FFF !important;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 0;
    text-transform: uppercase;
   
   
    @media only screen and (max-width:1299px){
        display:none;
    }
`;

const SmallHeadlineSpan = styled.span`
    color: var(--blue-text);
`;

const Footer = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    background-color: var(--dark-grey);
    padding: 0 5%;
    animation: fade-down .8s;
    @media only screen and (max-width: ${size.minWidthTablet}) {
        height: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
        flex-flow:column-reverse;
    }
`;

const FooterNav = styled.nav`

    > a {
        font-family: 'Space Grotesk', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 25px;
        color: var(--white);
        text-decoration: none;
        margin-right:2rem;
    }
    @media only screen and (max-width: ${size.minWidthTablet}) {
        display: flex;
        flex-flow: column;
        align-items: center;
        > a {
            margin-right:0;
            margin-bottom:1rem;
        }
    }
    
`;

const MailImg = styled.img`
    height: 1rem;
    margin-bottom: -5px;
    margin-right: 5px;
`;

const PlanboxOne = styled.div`
  max-width: 389px;
  width: 100%;
  height: max-content;
  border-radius: 45px;
  border: 8px solid #ebebeb;
  background: var(--w, #fff);
  box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.16);
  @media only screen and (max-width: 991px) {
    max-width: 89%;
    margin: auto;
  }
  @media only screen and (max-width: 1322px) {
    width: 326px;
    flex-shrink: 0;
    ${"" /* box-sizing: border-box; */}
  }
`;
const FreeplanPart = styled.div`
  border-radius: 32px 32px 0px 0px;
  background: radial-gradient(
    142.67% 120.19% at 91.93% 3.53%,
    #d0e5ff 0%,
    #bfd3ec 0.01%,
    #dedede 48.92%,
    #dcecff 91.67%
  );
  & > h2 {
    color: #4b4b4b;
    font-family: Space Grotesk;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    /* padding: 41px 105px 55px 103px; */
    margin: 0px;
    text-align: center;
    min-height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CheckIcon = styled.img`
  width: 14px;
  height: 14px;
`;

const ContentItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  @media only screen and (max-width: 480px) {
    padding: 15px;
  }
`;

const Text = styled.div`
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #12100b;
`;

const MonthPlan = styled.div`
  color: #000;
  font-family: Space Grotesk;
  font-size: 19.5px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-top: 20px;
  text-align: center;
  > span {
    color: #000;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    text-decoration-line: line-through;
  }
`;

const SubmitButton = styled.a`
  background: #ff2400;
  border-radius: 500px;
  padding: 16px 60px;
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  outline: 6px solid rgba(255, 36, 0, 0.33);
  transition: 0.4s;
  text-transform: capitalize;
  min-width: 100px;
  display:inline-block;
  
  &.block {
    display: block;
    color: white;
  }
  &.cancel {
    background: transparent;
    color: #fff;
    outline: 6px solid rgba(255, 255, 255, 0.33);
    &.block {
      background: #0050b2;
      outline: 6px solid rgba(0, 36, 255, 0.33);
      &:hover {
        background-color: transparent;
      }
    }
  }
  &.loading {
    background-color: #fedd00;
    outline: 6px solid rgba(254, 221, 0, 0.42);
    transition: 0.4s;
    padding: 16px 60px;
  }
  &:hover {
    transition: 0.4s;
    background: var(--white);
    color: #ff2400;
    cursor: pointer;
  }

  span {
    display: inline-block;
  }

  @media only screen and (max-width: ${size.maxWidthTablet}) {
    padding: 12px 20px;
    text-align: center;
    outline: 3px solid rgba(255, 255, 255, 0.42);
    font-size: 16px;
    line-height: 25px;
  }
`;

const BoxContent = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 26px;
  text-align: ${(props) => (props.isButton ? "center" : "left")};
  margin-top: ${(props) => (props.isButton ? "15px" : "0")};
  &.block {
    margin-top: 0;
  }
  &.plan {
    font-size: 1.2rem;
  }
`;

const NeeDed = styled.div`
  color: #333;
  text-align: center;
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 134.1%;
  letter-spacing: -0.8px;
  text-transform: capitalize;
  margin: 15px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;
const PlanboxTwo = styled.div`
  border-radius: 45px;
  border: 8px solid #ebebeb;
  box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.16);
  max-width: 389px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #fff;
  @media only screen and (max-width: 991px) {
    max-width: 89%;
    margin: auto;
  }
  @media only screen and (max-width: 1322px) {
    width: 306px;
    box-sizing: border-box;
  }
`;
const ProPlan = styled.div`
  border-radius: 40px 40px 0px 0px;

  background: radial-gradient(
    142.67% 120.19% at 91.93% 3.53%,
    #0050b2 0%,
    #2c281f 0.01%,
    #1f1b13 48.92%,
    #12100b 100%
  );
  height: 119px;
  color: var(--w, #fff);
  font-family: Space Grotesk;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  ${'' /* padding-right: 20px; */}
`;

const Tag = styled.div`
  background: #ff2400;
  padding: 6px;
  -webkit-transform: rotate(37deg);
  -ms-transform: rotate(37deg);
  -webkit-transform: rotate(37deg);
  -ms-transform: rotate(37deg);
  transform: rotate(37deg);
  position: absolute;
  top: 26px;
  width: 45%;
  right: -46px;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  text-transform: uppercase;
  font-family: Space Grotesk;
  @media only screen and (max-width: 1323px) {
    top: 16px;
    width: 38%;
    right: -28px;
    font-size: 8px;
  }
  @media only screen and (max-width: 1200px) {
    position: absolute;
    top: 15px;
    width: 47%;
    right: -35px;
    text-align: center;
    font-size: 7px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 13px;
    position: absolute;
    top: 30px;
    width: 200px;
    right: -46px;
    text-align: center;
  }

  @media only screen and (max-width: 480px) {
    font-size: 9px;
    top: 19px;
    width: 53%;
    text-align: center;
  }
`;
const PerCup = styled.div`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const CancleAnytime = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 18px;
`;

const TextCancel = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const ProPlanBlackTwo = styled.div`
  border-radius: 40px 40px 0px 0px;
  background: radial-gradient(
    96.02% 97.16% at 23.13% 29.46%,
    #0050b2 0%,
    #0a66c2 70.17%,
    #0050b2 100%
  );
  height: 119px;
  color: var(--w, #fff);
  font-family: Space Grotesk;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NewRedbtn = styled.div`
  ${"" /* margin-top: 65px; */}

  @media only screen and (max-width: 767px) {
    margin-top: 0px;
  }
`;
export default MainPages;
