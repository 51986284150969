import styled from "styled-components";
import { size, content as asideContent } from "../utils/helpers";
import publicIdentity from "../assets/public-identity.webp";
import publicClouds from "../assets/public-clouds.webp";
import publicImage from "../assets/fly_robot.png";
import publicLogo from "../assets/public-logo.png";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import StartGrowingList from "../features/StartGrowingContent";
import { lazy, useMemo, useState } from "react";
import { getContent } from "../hooks/useGetContent";
import FAQsection from "../layout/sales/PricingFAQ";
import CommenterBanner from "../assets/commenter_banner.png";
import checkIcon from "../assets/modal-check-icon.png";
import RegisterPage from "../pages/RegisterOne";

const Professional = ({ children }) => {
  const location = useLocation();
  const isRegister = location.pathname === "/register";
  const [content, setContent] = useState({});
  const [faq, setFAQ] = useState({});

  useMemo(() => {
    getContent().then((response) => {
      setContent(response["pricing"]);
    });

    getContent().then((response) => {
      setFAQ(response["pricingFAQ"]);
    });
  }, []);

  const planData = {
    type: "professional",
  };

  return (
    <>
      <Container className="public-pages">
       
       <ContentSection className="content-section">
          <ContentImage src={publicLogo} className="content-image" />
          {children}
          <RegisterPage planData={planData} />
        </ContentSection>
        <Aside className="aside">
          {/* {
                        isRegister ? '' : <AsideClouds src={publicClouds} alt="Commenter.ai clouds" />
                    }
                    <AsideContent className="aside-content">
                        {
                            isRegister ?
                                <>
                                    <AsideHeader className="aside-header">{asideContent.PublicPages.Aside.Title}</AsideHeader>
                                    <StartGrowingList content={content} />
                                </> :
                                <AsideIdentityImage src={publicIdentity} />
                        }
                    </AsideContent> */}
          <PlanWrpper>
            <PlanboxTwo>
              <ProPlanText>PROFESSIONAL PLAN</ProPlanText>
              {/* <Tag>Most Popular</Tag> */}
              <ContentList>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>900 comments/month</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>3 emotions (including Natural™ emotion).</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>5 commenting styles</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text> Full TextTune™ - short and long comments</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>GenuYou™ - comment personalization</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Multi-language support.</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>SmartReply+™- basic comment replies</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Grammar check of your comment</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Better comment suggestion</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Saved comments - up to 40.</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Engagement list - up to 40.</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Commenter academy</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Video course: “Strategic commenting”</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Priority support</Text>
                </ContentItem>
                <MonthPlan>
                  {" "}
                  <span>$36</span> $17/month
                </MonthPlan>
              </ContentList>
            </PlanboxTwo>
          </PlanWrpper>
        </Aside>
      </Container>
      <FAQsection
        content={content.faq}
        faq={faq}
        staticImages={content?.staticImages}
      />
    </>
  );
};

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    flex-flow: row;
  }
`;

const Aside = styled.aside`
  position: relative;
  flex: 1;
  background-color: var(--main-bg);
  display: flex;
  align-items: center;
  flex-flow: column;
  color: var(--white);
  // animation: slide-right-public 1.25s;
  width: 100%;
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    width: 50%;
  }
`;
const AsideContent = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;
`;
const AsideImage = styled.img`
  margin-top: 20px;
  // animation: image-fade-up 1.25s;
  width: 130px;
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    display: none;
    width: auto;
  }
`;

const AsideClouds = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 95%;
`;

const AsideIdentityImage = styled.img`
  width: 100%;

  @media only screen and (max-width: ${size.maxWidthTablet}) {
    margin-top: 7rem;
    margin-bottom: 3rem;
  }
`;

const AsideHeader = styled.h2`
  width: 100%;
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
  margin-top: 43px;
  margin-bottom: 0;
  // animation: image-fade-up-main 1.75s;
  @media only screen and (max-width: ${size.maxWidthTablet}) {
    margin-top: 16.5px;
    font-size: 20px;
    font-weight: 700;
    max-width: 360px;
  }
`;

const ContentSection = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    > img + div {
      width: 100%;
    }
    width: 50%;
  }
`;
const ContentImage = styled.img`
  // animation: image-fade-up-main 3.75s;
  margin-top: 26px;
  // display: none;
  padding-top: 1rem;
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    display: block;
  }
`;

const Planbox = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 0px 15px;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
    padding: 0px;
  }
`;
const PlanboxOne = styled.div`
  max-width: 389px;
  width: 100%;
  height: max-content;
  border-radius: 45px;
  border: 8px solid #ebebeb;
  background: var(--w, #fff);
  box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.16);
  @media only screen and (max-width: 991px) {
    max-width: 89%;
    margin: auto;
  }
`;
const FreeplanPart = styled.div`
  border-radius: 32px 32px 0px 0px;
  background: radial-gradient(
    142.67% 120.19% at 91.93% 3.53%,
    #d0e5ff 0%,
    #bfd3ec 0.01%,
    #dedede 48.92%,
    #dcecff 91.67%
  );
  & > h2 {
    color: #4b4b4b;
    font-family: Space Grotesk;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    /* padding: 41px 105px 55px 103px; */
    margin: 0px;
    text-align: center;
    min-height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const RedButtonclick = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: center;
`;
const NeeDed = styled.div`
  color: #333;
  text-align: center;
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 134.1%;
  letter-spacing: -0.8px;
  text-transform: capitalize;
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const CheckIcon = styled.img`
  width: 9px;
  height: 9px;
`;
const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px 20px;
`;
const ContentItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
const Text = styled.div`
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #12100b;
`;
const PlanWrpper = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
  border-bottom: 1px solid white;
  width: 100%;
  padding: 60px 0px;
`;
const PlanboxTwo = styled.div`
  border-radius: 45px;
  border: 8px solid #ebebeb;
  box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.16);
  max-width: 389px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #fff;
  @media only screen and (max-width: 991px) {
    max-width: 89%;
    margin: auto;
  }
`;
const ProPlanText = styled.div`
  border-radius: 40px 40px 0px 0px;
  background: radial-gradient(
    96.02% 97.16% at 23.13% 29.46%,
    #0050b2 0%,
    #0a66c2 70.17%,
    #0050b2 100%
  );
  height: 119px;
  color: var(--w, #fff);
  font-family: Space Grotesk;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
`;
const Tag = styled.div`
  background: #ff2400;
  padding: 6px;
  -webkit-transform: rotate(37deg);
  -ms-transform: rotate(37deg);
  -webkit-transform: rotate(37deg);
  -ms-transform: rotate(37deg);
  transform: rotate(37deg);
  position: absolute;
  top: 26px;
  width: 45%;
  right: -46px;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  text-transform: uppercase;
  font-family: Space Grotesk;
  @media only screen and (max-width: 1200px) {
    position: absolute;
    top: 16px;
    width: 49%;
    right: -46px;
    text-align: center;
    font-size: 9px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 13px;
    position: absolute;
    top: 30px;
    width: 200px;
    right: -46px;
    text-align: center;
  }

  @media only screen and (max-width: 480px) {
    font-size: 9px;
    top: 19px;
    width: 53%;
    text-align: center;
  }
`;

const TextBlue = styled.div`
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #0050b2;
`;
const MonthPlan = styled.div`
  color: #000;
  font-family: Space Grotesk;
  font-size: 19.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 20px;
  text-align: center;
  > span {
    color: #000;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    text-decoration-line: line-through;
  }
`;
const PerCup = styled.div`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const CancleAnytime = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 18px;
`;
const TextCancel = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export default Professional;
