import styled from "styled-components";
import { content } from "../utils/helpers";
import RequestNewPasswordForm from "../features/RequestNewPasswordForm";
import { size } from "../utils/helpers";
import { urlPaths } from "../utils/config";
import { NavLink } from "react-router-dom";

const RequestNewPasswordPage = () => {

    const symbol = () => {
        return <>{" "}&larr;</>
    }
    return(
        <NewPasswordContainer>
            <NavLink to={urlPaths.login}>{symbol()} Back</NavLink>
            <Title>{ content.PublicPages.RequestNewPassword.Title }</Title>
            <RequestNewPasswordForm id="request-new-password" />
            <SignUpContainer><FooterColor>{ content.PublicPages.Login.SignUp } <NavLink to={urlPaths.register}>Sign Up</NavLink></FooterColor></SignUpContainer>
        </NewPasswordContainer>
    );
}

const NewPasswordContainer = styled.div`
    display: flex;
    align-items: center;
    flex-flow: column;
    > div  {
        width: 100%;
    }
    > a {
        color: var(--blue-text);
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        transition: .4s;
        position: absolute;
        top:20px;
        left: 52%;
        text-decoration: none;
        &:hover {
            color: #333;
            text-decoration: none;
            transition: .4s;
            cursor: pointer;
        }
        @media only screen and (max-width: ${size.minWidthTablet}) {
            left: 1rem;
            top: 1rem;
        }
    }
`;

const Title = styled.h1`
    text-align: center;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 41px;
    line-height: 155.52%;
    margin-bottom: 0;
`;

const SignUpContainer = styled.div`
    position: absolute;
    bottom: 0;
    animation: image-fade-up-main 7s;
    @media only screen and (max-width:${size.minWidthTablet}){
        position:relative;
    }
`;

const FooterColor = styled.p`
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: var(--font-grey-dark);
`;


export default RequestNewPasswordPage;