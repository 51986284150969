import styled from "styled-components";
import { useState, useEffect} from 'react';
import { content } from "../utils/helpers";
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import { size } from "../utils/helpers";
import publicLogo from "../assets/public-logo.png";
import { Loader } from "../components/loader";
import { fetchAPI } from "../utils/fetch";
import { config } from "../utils/config";
import FirstPromoter from "../hooks/useFirstPromoter";

const CheckoutRedirectPage = () => {
    const [queryParams, setQueryParams] = useSearchParams();
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const email = queryParams.get('email');

    useEffect(() => {

            fetchAPI.post(`${config.urlPlatform}/api/v2/payment/subscription`, {}, {
                email: email
            }).then(result => {

                if(result.data.success === false) {
                    setError(true)
                    return;
                }
                window.location.replace(result.data.url);

            }).catch((err) => {
                console.log(err);
                setError(true);
            })

    }, [email, navigate, setQueryParams])

    return(
        <LoginContainer>
            <Wrapper>
                <img src={publicLogo} alt="Wellcome to Commenter.ai" />
                <Title>{ content.PublicPages.Login.Title }</Title>
                <Content>
                    { 
                    !error ? 
                    <p>Thanks for clicking the checkout link, please wait while we redirect you to our checkout form</p> : 
                    <p>Oops, we couldn't redirect you to our checkout. Please contact us with the problem and <NavLink to={"/login"}>Login</NavLink> so you can subscribe manually from the Settings page.</p>
                    }
                </Content>
                <FirstPromoter email={email} />
                <LoaderPart style={{marginTop: '2rem'}}>
                    { error ? <></> : <Loader /> }
                </LoaderPart>
            </Wrapper>
             </LoginContainer>
    );
}

const LoginContainer = styled.main`
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content:space-around;
    align-items: center;
    animation: popUp 1s;
    width:100%;
    min-height: 100vh;
`;

const Wrapper = styled.section`
    margin-top:auto;
    margin-bottom: auto;
    text-align: center;

    @media only screen and (min-width: ${size.minWidthTablet}) {
        margin-top: 10rem;
    }
`;

const LoaderPart = styled.div`
    position: relative;
    > span {
        width: 40px;
        height: 40px;
        &:before {
            border: 5px solid green;
        }
    }
`

const Title = styled.h1`
    text-align: center;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 41px;
    line-height: 155.52%;
    margin-bottom: 0;
`;

const Content = styled.div`
    font-size: 1rem;
    line-height: 26px;
    text-align: center;
    color: var(--font-grey);
`;

export default CheckoutRedirectPage;