import React  from "react";
import styled from "styled-components";

const InputField = ({ value, label, name, placeholder, type, onChange, onKeyUp, onBlur }) => (
  <div className="form-group">
    {label && <label htmlFor="input-field">{label}</label>}
    <Input
      type={type}
      value={value}
      name={name}
      className="form-control"
      placeholder={placeholder}
      onChange={onChange}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
    />
  </div>
);

const Input = styled.input`
    width: 70px;
    border-radius: 5px;
    border: 1px solid var(--ffffff, #FFF);
    opacity: 0.5;
    height: 30px;
    color: #FFF;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: transparent;
    margin-left: 10px;
`;

export default InputField;