import styled from "styled-components";
import { lazy, Suspense } from "react";

import robotPlugin from "../../assets/get-comments-overlay.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateSession } from "../../hooks/useValidateSession";
import { fetchAPI } from "../../utils/fetch";
import { config } from "../../utils/config";
import { size } from "../../utils/helpers";

const GetCommentsSecondSection = lazy(() =>
  import("../../layout/platform/GetCommentsSecond")
);
const CommentGeneration = lazy(() =>
  import("../../layout/platform/CommentGeneration")
);

const GetCommentsPage = () => {
  const navigate = useNavigate();
  const storage = localStorage.getItem("userData");
  const storageJson = JSON.parse(storage);
  const [commentsAmount, setComments] = useState(storageJson.commentsLeft);
  const [overlay, setOverlay] = useState(true);

  useEffect(() => {
    validateSession()
      .then((result) => {
        if (result.success === false || result === false) {
          //navigate("/unauthorized");
        }
      })
      .catch((error) => {
        //navigate("/unauthorized");
      });

    fetchAPI
      .get(
        `${config.urlPlatform}/api/v2/auth/user?email=${storageJson.email}`,
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if ("success" in result) {
          setComments(storageJson.commentsLeft);
        } else {
          delete result.data.subscriptionData;
          localStorage.setItem("userData", JSON.stringify(result.data));

          setComments(result.data.commentsLeft);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate, storageJson]);

  return (
    <Container>
      {(() => {
        if (overlay) {
          return (
            <Overlay
              onClick={() => {
                setOverlay(false);
              }}
            >
              <OverlayBox>
                <OvarlayImg src={robotPlugin} />
                <OverlayTextBox>
                  <OverlayText>
                    Hello Commenter,
                    <br />
                    <br />
                    Did you know that there is an easier and faster way to
                    generate comments? <br />
                    You can do that directly on LinkedIn by using Social Post
                    Mate. <br />
                    <br />
                    Add it to your Chrome, connect it with our platform, and
                    start getting comments.
                    <br />
                    <br />
                    Download it from{" "}
                    <a
                      href="https://chrome.google.com/webstore/detail/social-post-mate/ffebfjkgjgbpmnoogjjdgfkmiobngdnf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      &gt;&gt; HERE &lt;&lt;
                    </a>
                    .<br />
                    <br />
                    Then check the second video on our "Welcome" page to see how
                    to use it.
                    <br />
                    <br />
                    P.S. Keep commenting, the opportunities are waiting for you.
                  </OverlayText>
                </OverlayTextBox>
              </OverlayBox>
            </Overlay>
          );
        }
        return null;
      })()}
      <Suspense
        fallback={
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Loading...
          </div>
        }
      >
        <CommentGeneration amount={commentsAmount} />
      </Suspense>
      <Suspense
        fallback={
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Loading...
          </div>
        }
      >
        <GetCommentsSecondSection />
      </Suspense>
    </Container>
  );
};

const Container = styled.div``;

const OverlayTextBox = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  width: 50%;
  left: 43%;
  overflow: auto;
  height: 80%;
`;

const OverlayText = styled.p`
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
  a {
    color: #fff;
  }
  @media only screen and (max-width: ${size.minWidthTablet}) {
    font-size: 0.65rem;
  }
`;

const OverlayBox = styled.div`
  z-index: 9999;
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 40%;
  transition: 0.4s;
  animation: image-scale 1.5s;
  @media only screen and (max-width: ${size.maxXlWidthTablet}) {
    max-width: 100%;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  transition: 0.4s;
  animation: fade-opacity 1s;
`;

const OvarlayImg = styled.img`
  transition: 0.4s;
  animation: image-scale 1.5s;
  width: 100%;
  @media only screen and (max-width: ${size.minWidthTablet}) {
    width: 100%;
  }
`;

export default GetCommentsPage;
